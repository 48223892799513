import storeConstants from 'redux/constants/actionTypes'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

// Function to calculate timestamp for the last day of the previous month
const calculateLastDayOfPreviousMonth = () => {
  const currentDateStart = dayjs().startOf('month')
  const lastDayOfPreviousMonth = currentDateStart.subtract(1, 'day')
  return lastDayOfPreviousMonth.utc().unix() * 1000
}

// Function to calculate timestamp for the last day of the previous December
const calculateLastDayOfPreviousDecember = () => {
  const lastDayOfPreviousDecember = dayjs().subtract(1, 'year').endOf('year')
  return lastDayOfPreviousDecember.utc().unix() * 1000
}

// Function to calculate fiscal timestamp
const calculateFiscalTimestamp = () => dayjs().unix() * 1000

// Function to calculate timestamp for December 1st of next year
const calculateDecember1stNextYear = () => {
  const nextYear = dayjs().add(1, 'year')
  return nextYear.set('month', 11).set('date', 1).unix() * 1000
}

// Function to calculate timestamp for the end of the current year
const calculateEndOfYearTimestamp = () => {
  const currentYear = new Date().getFullYear()
  const endDate = new Date(currentYear, 11, 31)
  return endDate.getTime()
}

const initialState = {
  analysisListResult: [],
  firmSlug: [],
  firmBackground: {
    name: '',
    country: 'United States',
    industry: '',
    foreCastStartDate: calculateLastDayOfPreviousMonth(),
    foreCastEndDate: calculateEndOfYearTimestamp(),
    fiscalYear: calculateFiscalTimestamp(),
    start_of_operations_year: calculateLastDayOfPreviousMonth(),
    flowOrstock: true,
    decemberOfLastYear: calculateLastDayOfPreviousDecember(),
    projection_frequency: 'Q',
    currency_sym: 'USD ($)',
    decimal: 'None',
  },
  financial_statements: [],
  financial_statements_stock: [],
  fx_position: [],
  maturity_short_term: [],
  business_plan: [],
  initial_custom_scenario: [],
  analysisResult: {
    enable_custom_scenario: false,
  },
  saved_analysis: false,
  customScenarioData: [],
  tabsRouter: false,
  removeOrGetLast: false,
  toggle:false
}

const analysisDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case storeConstants.ANALYSIS_LIST:
      return {
        ...state,
        analysisListResult: action.payload,
      }
    case storeConstants.FIRM_SLUG:
      return {
        ...state,
        firmSlug: action.payload,
      }
    case storeConstants.GET_ANALYSIS_DATA_ALL:
      return {
        ...state,
        financial_statements: action.payload.financial_statements,
        financial_statements_stock: action.payload.financial_statements_stock,

        fx_position: action.payload.fx_position,
        maturity_short_term: action.payload.maturity_short_term,
        business_plan: action.payload.business_plan,
        initial_custom_scenario: action.payload.custom_scenario,
        analysisResult: {
          enable_custom_scenario: false,
        },
      }
    case storeConstants.EXCEL_PUSH:
      return {
        ...state,
        financial_statements: action.payload.financial_statements,
        financial_statements_stock: action.payload.financial_statements_stock,
        fx_position: action.payload.fx_position,
        maturity_short_term: action.payload.maturity_short_term,
        business_plan: action.payload.business_plan,
        // initial_custom_scenario: action.payload.custom_scenario,
        // analysisResult: {
        //   enable_custom_scenario: false,
        // },
      }
    case storeConstants.EXCEL_CUSTOM_PUSH:
      return {
        ...state,
        analysisResult: { ...state.analysisResult, ...action.payload },
      }
    case storeConstants.CUSTOM_SCENARIO_LIST:
      return {
        ...state,
        customScenarioData: action.payload,
      }
    case storeConstants.GET_ANALYSIS_REAL_DATA:
      const newScenarios = []
      action.payload.scenarios.map((item) => {
        let date = new Date(item.year)
        newScenarios.push({
          year: new Date(
            Date.UTC(date.getFullYear(), date.getMonth() + 1, 0)
          ).getTime(),
          values: item.values,
        })
      })
      action.payload.scenarios = newScenarios
      return {
        ...state,
        analysisResult: { ...state.analysisResult, ...action.payload },
      }
    case storeConstants.ENABLE_CUSTOM_SCENARIO:
      return {
        ...state,
        analysisResult: {
          ...state.analysisResult,
          enable_custom_scenario: true,
        },
      }
    case storeConstants.DISABLE_CUSTOM_SCENARIO:
      return {
        ...state,
        analysisResult: {
          ...state.analysisResult,
          enable_custom_scenario: false,
        },
      }
    case storeConstants.DISABLE_BUTTON:
      return {
        ...state,
        financial_statements: [
          ...state.financial_statements.map((data, i) =>
            i === 0
              ? {
                  ...data,
                  ...(data[0] = { disabled: action.payload }),
                }
              : { ...data }
          ),
        ],
      }
    case storeConstants.DISABLE_BUTTON_FX:
      return {
        ...state,
        fx_position: [
          ...state.fx_position.map((data, i) =>
            i === 0
              ? {
                  ...data,
                  ...(data[0] = { disabled: action.payload }),
                }
              : { ...data }
          ),
        ],
      }
    case storeConstants.DISABLE_BUTTON_MATURITY:
      return {
        ...state,
        maturity_short_term: [
          ...state.maturity_short_term.map((data, i) =>
            i === 0
              ? {
                  ...data,
                  ...(data[0] = { disabled: action.payload }),
                }
              : { ...data }
          ),
        ],
      }
    case storeConstants.FIRM_BACKGROUND_SELECT:
      return {
        ...state,
        firmBackground: { ...state.firmBackground, ...action.payload },
      }
    case storeConstants.GET_LAST_CUSTOM_SCENARIO:
      return {
        ...state,
        analysisResult: { ...state.analysisResult, scenarios: action.payload },
      }
    case storeConstants.SAVE_ANALYSIS:
      return {
        ...state,
        saved_analysis: action.payload,
      }
    case storeConstants.TABS_ROUTER:
      return {
        ...state,
        tabsRouter: action.payload,
      }
    case storeConstants.REMOVE_OR_GET_LAST:
      return {
        ...state,
        removeOrGetLast: action.payload,
      }
    case storeConstants.TOGGLE:
      return {
        ...state, toggle: !state.toggle
      }
    default:
      return state
  }
}

export default analysisDataReducer
