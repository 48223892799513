import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './rootReducer';

// Persist Config
const persistConfig = {
  key: 'persisted',
  storage,
  debug: true,
  whitelist: ['auth','myfirm', 'joyRide'],
};

// Persisted Reducer oluştur
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Redux DevTools desteğini güvenli şekilde ekle
const composeEnhancers =
  (typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

// Store oluştur
const configureStore = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk))
);

// Persistor oluştur
const persistor = persistStore(configureStore);

export { configureStore, persistor };
