import { useEffect, useRef, useState } from 'react'
import AnalysisButtons from 'components/AnalysisButtons'
import BusinessPlan from 'pages/BusinessPlan'
import CustomScenario from 'pages/CustomScenario'
import FinancialStatements from 'pages/FinancialStatements'
import Firmbackground from 'pages/Firmbackground'
import FxPosition from 'pages/FxPosition'
import MaturityShortTerm from 'pages/MaturityShortTerm'
import Report from 'pages/Report'
import './../assets/styles/fixedTableHeader.scss'
import { getFirmList } from 'redux/ApiCalls'
import { useDispatch, useSelector } from 'react-redux'
import storeConstants from 'redux/constants/actionTypes'
import Sidebar from 'components/Sidebar/Sidebar'
import HeaderAnalysisChart from './HeaderAnalysisChart'

const AnalysisPage = () => {
  const containerRef = useRef(null);
  
  const { token } = useSelector((state) => state.auth)
  const [tab, setTab] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [excelRead, setExcelRead] = useState(null)
  const [isLastCustomScenario, setIsLastCustomScenario] = useState(false)
  const [runInTransition, setRunInTransition] = useState(true)
  const { toggle } = useSelector((state) => state.analysisDataReducer)

  const { tabsRouter } = useSelector((state) => state.analysisDataReducer)
  const dispatch = useDispatch()

  useEffect(() => {
    if (tab === 1 && token)
      getFirmList(token)
        .then((res) => {
          dispatch({
            type: storeConstants.GET_FIRM_LIST,
            payload: res.data.results,
          })
        })
        .catch((e) => console.log(e))
  }, [tab])

  useEffect(() => {
    if (tabsRouter) {
      setTab(0)
    }
  }, [tabsRouter])


  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollLeft = 0;
    }
  }, []);

  const prevTab = useRef(tab);

  useEffect(() => {
    if (prevTab.current === 0 && tab === 1) {
      setRunInTransition(true)
    }
    prevTab.current = tab;
  }, [tab]);


  return (
    <>
      <Sidebar
        changePage={() => setTab(1)}
        tab={tab}
        setTab={setTab}
        isLoading={isLoading}
        token={token}
      />
      <div className="relative md:ml-64 sm:!bottom-[30px] md:!bottom-0"
        style={{
          background: 'linear-gradient(180deg, #CEE5FD, #FFF)',
          top: window.innerWidth < 768 ? '70px' : '0px'
        }}>

        <div className='max-w-[1200px] mx-auto min-h-screen flex flex-col'>
          {
            tab === 0 && (
              <Firmbackground changePage={() => setTab(1)} />
            )
          }
          {tab !== 0 && (
            <>
              <div className='w-full'>
                <HeaderAnalysisChart isLoading={isLoading} />
              </div>
              <div className='flex-1 overflow-x-auto w-full'>
                {tab === 1 ? (
                  <FinancialStatements
                    excelRead={excelRead}
                    isLoading={isLoading}
                    runInTransition={runInTransition}
                    setRunInTransition={setRunInTransition}
                    setIsLoading={setIsLoading}
                    setExcelRead={setExcelRead}
                  />
                ) : tab === 2 ? (
                  <FxPosition
                    excelRead={excelRead}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    setExcelRead={setExcelRead}
                  />
                ) : tab === 3 ? (
                  <MaturityShortTerm
                    excelRead={excelRead}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    setExcelRead={setExcelRead}
                  />
                ) : tab === 4 ? (
                  <BusinessPlan
                    excelRead={excelRead}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    setExcelRead={setExcelRead}
                  />
                ) : tab === 5 ? (
                  <CustomScenario
                    isLastCustomScenario={isLastCustomScenario}
                    setIsLastCustomScenario={setIsLastCustomScenario}
                    excelRead={excelRead}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    setExcelRead={setExcelRead}
                  />
                ) : tab === 6 ? (
                  <Report isLoading={isLoading} />
                ) : null}
              </div>
              <div className='sticky bottom-0 left-0 bg-white z-10 shadow-md w-full'>
                <AnalysisButtons activeTab={tab} setActiveTab={setTab} />
              </div>
            </>
          )}
        </div>

      </div>
    </>

  )
}

export default AnalysisPage
