import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

const useHandleInputChange = ( action, data, calculateAnalysis) => {
  const [lastKeyPress, setLastKeyPress] = useState(null)
  const dispatch = useDispatch()

  const handleInputChange = (value, key, index) => {
    const newData = [...data]
    const findValue = newData[index].values.find((item) => item.key === key)
    findValue.value = value
    dispatch(action(newData))
    setLastKeyPress(newData)
  }

  useEffect(() => {
    if (lastKeyPress) {
      const timeoutId = setTimeout(() => calculateAnalysis(), 1500)
      return () => clearTimeout(timeoutId)
    }
  }, [lastKeyPress])

  return { handleInputChange }
}

export default useHandleInputChange
