export const country = [
  { label: 'AFGHANISTAN', value: 'Afghanistan' },
  { label: 'ALAND_ISLANDS', value: 'ALAND_ISLANDS' },
  { label: 'ALBANIA', value: 'Albania' },
  { label: 'ALGERIA', value: 'Algeria' },
  { label: 'AMERICAN_SAMOA', value: 'American Samoa' },
  { label: 'ANDORRA', value: 'Andorra' },
  { label: 'ANGOLA', value: 'Angola' },
  { label: 'ANGUILLA', value: 'Anguilla' },
  { label: 'ANTARCTICA', value: 'Antarctica' },
  { label: 'ANTIGUA_AND_BARBUDA', value: 'Antigua and Barbuda' },
  { label: 'ARGENTINA', value: 'Argentina' },
  { label: 'ARMENIA', value: 'Armenia' },
  { label: 'ARUBA', value: 'Aruba' },
  { label: 'AUSTRALIA', value: 'Australia' },
  { label: 'AUSTRIA', value: 'Austria' },
  { label: 'AZERBAIJAN', value: 'Azerbaijan' },
  { label: 'BAHAMAS', value: 'Bahamas' },
  { label: 'BAHRAIN', value: 'Bahrain' },
  { label: 'BANGLADESH', value: 'Bangladesh' },
  { label: 'BARBADOS', value: 'Barbados' },
  { label: 'BELARUS', value: 'Belarus' },
  { label: 'BELGIUM', value: 'Belgium' },
  { label: 'BELIZE', value: 'Belize' },
  { label: 'BENIN', value: 'Benin' },
  { label: 'BERMUDA', value: 'Bermuda' },
  { label: 'BHUTAN', value: 'Bhutan' },
  { label: 'BOLIVIA', value: 'Bolivia' },
  { label: 'BONAIRE_SINT_EUSTATIUS_AND_SABA', value: 'Bonaire, Sint Eustatius and Saba' },
  { label: 'BOSNIA_AND_HERZEGOVINA', value: 'Bosnia and Herzegovina' },
  { label: 'BOTSWANA', value: 'Botswana' },
  { label: 'BOUVET_ISLAND', value: 'Bouvet Island' },
  { label: 'BRAZIL', value: 'Brazil' },
  { label: 'BRITISH_INDIAN_OCEAN_TERRITORY', value: 'British Indian Ocean Territory' },
  { label: 'BRUNEI_DARUSSALAM', value: 'Brunei Darussalam' },
  { label: 'BULGARIA', value: 'Bulgaria' },
  { label: 'BURKINA_FASO', value: 'Burkina Faso' },
  { label: 'BURUNDI', value: 'Burundi' },
  { label: 'KAMBODIA', value: 'Kambodia' },
  { label: 'CAMEROON', value: 'Cameroon' },
  { label: 'CANADA', value: 'Canada' },
  { label: 'CAPE_VERDE', value: 'Cape Verde' },
  { label: 'CAYMAN_ISLANDS', value: 'Cayman Islands' },
  { label: 'CENTRAL_AFRICAN_REPUBLIC', value: 'Central African Republic' },
  { label: 'CHAD', value: 'Chad' },
  { label: 'CHILE', value: 'Chile' },
  { label: 'CHINA', value: 'China' },
  { label: 'CHRISTMAS_ISLAND', value: 'Christmas Island' },
  { label: 'COCOS_(KEELING)_ISLANDS', value: 'Cocos (Keeling) Islands' },
  { label: 'COLOMBIA', value: 'Colombia' },
  { label: 'COMOROS', value: 'Comoros' },
  { label: 'CONGO', value: 'Congo' },
  { label: 'CONGO_THE_DEMOCRATIC_REPUBLIC_OF_', value: 'Congo, the Democratic Republic of the' },
  { label: 'COOK_ISLANDS', value: 'Cook Islands' },
  { label: 'COSTA_RICA', value: 'Costa Rica' },
  { label: 'COTE_D_IVOIRE', value: 'Cote d\'Ivoire' },
  { label: 'CROATIA', value: 'Croatia' },
  { label: 'CUBA', value: 'Cuba' },
  { label: 'CURAÇAO', value: 'Curaçao' },
  { label: 'CYPRUS', value: 'Cyprus' },
  { label: 'CZECHIA', value: 'Czechia' },
  { label: 'DENMARK', value: 'Denmark' },
  { label: 'DJIBOUTI', value: 'Djibouti' },
  { label: 'DOMINICA', value: 'Dominica' },
  { label: 'DOMINICAN_REPUBLIC', value: 'Dominican Republic' },
  { label: 'ECUADOR', value: 'Ecuador' },
  { label: 'EGYPT', value: 'Egypt' },
  { label: 'EL_SALVADOR', value: 'El Salvador' },
  { label: 'EQUATORIAL_GUINEA', value: 'Equatorial Guinea' },
  { label: 'ERITREA', value: 'Eritrea' },
  { label: 'ESTONIA', value: 'Estonia' },
  { label: 'ETHIOPIA', value: 'Ethiopia' },
  { label: 'FALKLAND_ISLANDS_(MALVINAS)', value: 'Falkland Islands (Malvinas)' },
  { label: 'FAROE_ISLANDS', value: 'Faroe Islands' },
  { label: 'FIJI', value: 'Fiji' },
  { label: 'FINLAND', value: 'Finland' },
  { label: 'FRANCE', value: 'France' },
  { label: 'FRENCH_GUIANA', value: 'French Guiana' },
  { label: 'FRENCH_POLYNESIA', value: 'French Polynesia' },
  { label: 'FRENCH_SOUTHERN_TERRITORIES', value: 'French Southern Territories' },
  { label: 'GABON', value: 'Gabon' },
  { label: 'GAMBIA', value: 'Gambia' },
  { label: 'GEORGIA', value: 'Georgia' },
  { label: 'GERMANY', value: 'Germany' },
  { label: 'GHANA', value: 'Ghana' },
  { label: 'GIBRALTAR', value: 'Gibraltar' },
  { label: 'GREECE', value: 'Greece' },
  { label: 'GREENLAND', value: 'Greenland' },
  { label: 'GRENADA', value: 'Grenada' },
  { label: 'GUADELOUPE', value: 'Guadeloupe' },
  { label: 'GUAM', value: 'Guam' },
  { label: 'GUATEMALA', value: 'Guatemala' },
  { label: 'GUERNSEY', value: 'Guernsey' },
  { label: 'GUINEA', value: 'Guinea' },
  { label: 'GUINEA_BISSAU', value: 'Guinea Bissau' },
  { label: 'GUYANA', value: 'Guyana' },
  { label: 'HAITI', value: 'Haïti' },
  { label: 'HEARD_AND_MC_DONALD_ISLANDS', value: 'Heard and Mc Donald Islands' },
  { label: 'HOLY_SEE_(VATICAN_CITY_STATE)', value: 'Holy See (Vatican City State)' },
  { label: 'HONDURAS', value: 'Honduras' },
  { label: 'HONG_KONG', value: 'Hong Kong' },
  { label: 'HUNGARY', value: 'Hungary' },
  { label: 'ICELAND', value: 'Iceland' },
  { label: 'INDIA', value: 'India' },
  { label: 'INDONESIA', value: 'Indonesia' },
  { label: 'IRAN_ISLAMIC_REPUBLIC_OF', value: 'Iran Islamic Republic of' },
  { label: 'IRAQ', value: 'Iraq' },
  { label: 'IRELAND', value: 'Ireland' },
  { label: 'ISLE_OF_MAN', value: 'Isle of man' },
  { label: 'ISRAEL', value: 'Israel' },
  { label: 'ITALY', value: 'Italy' },
  { label: 'JAMAICA', value: 'Jamika' },
  { label: 'JAPAN', value: 'Japan' },
  { label: 'JERSEY', value: 'Jersey' },
  { label: 'JORDAN', value: 'Jordan' },
  { label: 'KAZAKSTAN', value: 'Kazakstan' },
  { label: 'KENYA', value: 'Kenya' },
  { label: 'KIRIBATI', value: 'Kiribati' },
  { label: 'KOREA_DEMOCRATIC_PEOPLE_S_REPUBLIC_OF', value: 'Koree Democratic People\'s Republic of' },
  { label: 'KOREA_REPUBLIC_OF', value: 'Korea Republic of' },
  { label: 'KOSOVO', value: 'Košovo' },
  { label: 'KUWAIT', value: 'Kuwait' },
  { label: 'KYRGYZSTAN', value: 'Kyrgyzstan' },
  { label: 'LAO_PEOPLE_S_DEMOCRATIC_REPUBLIC', value: 'Lao People s Democratic Republic' },
  { label: 'LATVIA', value: 'Latvia' },
  { label: 'LEBANON', value: 'Lebanon' },
  { label: 'LESOTHO', value: 'Lesotho' },
  { label: 'LIBERIA', value: 'Liberia' },
  { label: 'LIBYAN_ARAB_JAMAHIRIYA', value: 'Libyan Arab Jamahiriya' },
  { label: 'LIECHTENSTEIN', value: 'Liectenstain' },
  { label: 'LITHUANIA', value: 'Lithuania' },
  { label: 'LUXEMBOURG', value: 'Lüxembourg' },
  { label: 'MACAO', value: 'Macao' },
  { label: 'MACEDONIA_THE_FORMER_YUGOSLAV_REPUBLIC_OF', value: 'Macedonia the Former Yugoslav Republic of' },
  { label: 'MADAGASCAR', value: 'Madagascar' },
  { label: 'MALAWI', value: 'Malawi' },
  { label: 'MALAYSIA', value: 'Malaysia' },
  { label: 'MALDIVES', value: 'Maldives' },
  { label: 'MALI', value: 'Mali' },
  { label: 'MALTA', value: 'Malta' },
  { label: 'MARSHALL_ISLANDS', value: 'Marshall Islands' },
  { label: 'MARTINIQUE', value: 'Martinique' },
  { label: 'MAURITANIA', value: 'Mauretanien' },
  { label: 'MAURITIUS', value: 'Mauretius' },
  { label: 'MAYOTTE', value: 'Mayotte' },
  { label: 'MEXICO', value: 'Mexico' },
  { label: 'MICRONESIA_FEDERATED_STATES_OF', value: 'Micronesia, Federated States of' },
  { label: 'MOLDOVA_REPUBLIC_OF', value: 'Moldova Republic of' },
  { label: 'MONACO', value: 'Monaco' },
  { label: 'MONGOLIA', value: 'Mongolia' },
  { label: 'MONTENEGRO', value: 'Montenegro' },
  { label: 'MONTSERRAT', value: 'Montserrat' },
  { label: 'MOROCCO', value: 'Morocco' },
  { label: 'MOZAMBIQUE', value: 'Mozambique' },
  { label: 'MYANMAR', value: 'Myanmar' },
  { label: 'NAMIBIA', value: 'Namibia' },
  { label: 'NAURU', value: 'Nauru' },
  { label: 'NEPAL', value: 'Nepal' },
  { label: 'NETHERLANDS', value: 'Netherlands' },
  { label: 'NETHERLANDS_ANTILLES', value: 'Netherlands Antilles' },
  { label: 'NEW_CALEDONIA', value: 'New Caledonia' },
  { label: 'NEW_ZEALAND', value: 'New Zealand' },
  { label: 'NICARAGUA', value: 'Nicaragua' },
  { label: 'NIGER', value: 'Niger' },
  { label: 'NIGERIA', value: 'Nigeria' },
  { label: 'NIUE', value: 'Niue' },
  { label: 'NORFOLK_ISLAND', value: 'Norfolk Island' },
  { label: 'NORTHERN_MARIANA_ISLANDS', value: 'Northern Mariana Islands' },
  { label: 'NORWAY', value: 'Norway' },
  { label: 'OMAN', value: 'Oman' },
  { label: 'PAKISTAN', value: 'Pakistan' },
  { label: 'PALAU', value: 'Palau' },
  { label: 'PALESTINIAN_TERRITORY_OCCUPIED', value: 'Palestinian Territory Occupied' },
  { label: 'PANAMA', value: 'Panama' },
  { label: 'PAPUA_NEW_GUINEA', value: 'Papua New Guinea' },
  { label: 'PARAGUAY', value: 'Paraguay' },
  { label: 'PERU', value: 'Peru' },
  { label: 'PHILIPPINES', value: 'Philippines' },
  { label: 'PITCAIRN', value: 'Pitcairn' },
  { label: 'POLAND', value: 'Poland' },
  { label: 'PORTUGAL', value: 'Portugal' },
  { label: 'PUERTO_RICO', value: 'Puerto Rico' },
  { label: 'QATAR', value: 'Qatar' },
  { label: 'REPUBLIC_OF_SERBIA', value: 'Republic of Serbia' },
  { label: 'REUNION', value: 'Reunion' },
  { label: 'ROMANIA', value: 'Romania' },
  { label: 'RUSSIA_FEDERATION', value: 'Russia Federation' },
  { label: 'RWANDA', value: 'Rwanda' },
  { label: 'SAINT_BARTHÉLEMY', value: 'Saint Barthélemy' },
  { label: 'SAINT_HELENA', value: 'Saint Helena' },
  { label: 'SAINT_KITTS_&_NEVIS', value: 'Saint Kitts & Nevis' },
  { label: 'SAINT_LUCIA', value: 'Saint Lucia' },
  { label: 'SAINT_MARTIN', value: 'Saint Martin' },
  { label: 'SAINT_PIERRE_AND_MIQUELON', value: 'Saint Pierre and Miquelon' },
  { label: 'SAMOA', value: 'Samoa' },
  { label: 'SAN_MARINO', value: 'San Marino' },
  { label: 'SAO_TOME_AND_PRINCIPE', value: 'Sao Tome and Principe' },
  { label: 'SAUDI_ARABIA', value: 'Saudi Arabia' },
  { label: 'SENEGAL', value: 'Senegal' },
  { label: 'SERBIA_AND_MONTENEGRO', value: 'Serbia and Montenegro' },
  { label: 'SEYCHELLES', value: 'Seychelles' },
  { label: 'SIERRA_LEONE', value: 'Sierra Leone' },
  { label: 'SINGAPORE', value: 'Singapore' },
  { label: 'SINT_MAARTEN', value: 'Sınt Maarten' },
  { label: 'SLOVAKIA', value: 'Slovakia' },
  { label: 'SLOVENIA', value: 'Slovenia' },
  { label: 'SOLOMON_ISLANDS', value: 'Solomon Islands' },
  { label: 'SOMALIA', value: 'Somalia' },
  { label: 'SOUTH_AFRICA', value: 'South Africa' },
  { label: 'SOUTH_GEORGIA_&_THE_SOUTH_SANDWICH_ISLANDS', value: 'South Georgia & The South Sandwich Islands' },
  { label: 'SOUTH_SUDAN', value: 'South Sudan' },
  { label: 'SPAIN', value: 'Spain' },
  { label: 'SRI_LANKA', value: 'Sri Lanka' },
  { label: 'SUDAN', value: 'Sudan' },
  { label: 'SURINAME', value: 'Suriame' },
  { label: 'SVALBARD_AND_JAN_MAYEN', value: 'Svalbard and Jan Mayen' },
  { label: 'SWAZILAND', value: 'Swaziland' },
  { label: 'SWEDEN', value: 'Sweden' },
  { label: 'SWITZERLAND', value: 'Switzerland' },
  { label: 'SYRIAN_ARAB_REPUBLIC', value: 'Syrian Arab Republic' },
  { label: 'TAIWAN_PROVINCE_OF_CHINA', value: 'Taiwan Province of China' },
  { label: 'TAJIKISTAN', value: 'Tajikistan' },
  { label: 'TANZANIA_UNITED_REPUBLIC_OF', value: 'Tanzania United Republic of' },
  { label: 'THAILAND', value: 'Thailand' },
  { label: 'TIMOR_LESTE', value: 'Timor Leste' },
  { label: 'TOGO', value: 'Togo' },
  { label: 'TOKELAU', value: 'Tokelau' },
  { label: 'TONGA', value: 'Tonga' },
  { label: 'TRINIDAD_AND_TOBAGO', value: 'Trinidad and Tobago' },
  { label: 'TUNISIA', value: 'Tunisia' },
  { label: 'Türkiye', value: 'Turkiye' },
  { label: 'TURKISH_REP_N_CYPRUS', value: 'Turkish Republic of Cyprus' },
  { label: 'TURKMENISTAN', value: 'Turkmenistan' },
  { label: 'TURKS_AND_CAICOS_ISLANDS', value: 'Turks and Caicos Islands' },
  { label: 'TUVALU', value: 'Tuvalu' },
  { label: 'UGANDA', value: 'Uganda' },
  { label: 'UKRAINE', value: 'Ukraine' },
  { label: 'UNITED_ARAB_EMIRATES', value: 'United Arab Emirates' },
  { label: 'UNITED_KINGDOM', value: 'United Kingdom' },
  { label: 'UNITED_STATES', value: 'United States' },
  { label: 'UNITED_STATES_MINOR_OUTLYING_ISLANDS', value: 'United States Minor Outlying Islands' },
  { label: 'URUGUAY', value: 'Uraguay' },
  { label: 'UZBEKISTAN', value: 'Uzbeksitan' },
  { label: 'VANUATU', value: 'Vanuatu' },
  { label: 'VENEZUELA', value: 'Venuzuela' },
  { label: 'VIETNAM', value: 'Vietnam' },
  { label: 'VIRGIN_ISLANDS_BRITISH', value: 'Virgin Islands British' },
  { label: 'VIRGIN_ISLANDS_U.S.', value: 'Virgin Islands U.S.' },
  { label: 'WALLIS_AND_FUTUNA', value: 'Wallis and Futuna' },
  { label: 'WESTERN_SAHARA', value: 'Western Sahara' },
  { label: 'YEMEN', value: 'Yemen' },
  { label: 'ZAMBIA', value: 'Zambia' },
  { label: 'ZIMBABWE', value: 'Zimbabwe' }
]
export const industry = [
  {
    label: 'AGRICULTURE_AND_FORESTRY',
    value: 'Agriculture and Forestry'
  },
  {
    label: 'OIL_GAS_AND_MINING',
    value: 'Oil, Gas and Mining'
  },
  {
    label: 'UTILITLES',
    value: 'Utilities'
  },
  {
    label: 'CONSTRUCTION_AND_REAL_ESTATE',
    value: 'Construction and Real Estate'
  },
  {
    label: 'TRANSPORTATION_AND_WAREHOUSING',
    value: 'Transportation and Warehousing'
  },
  {
    label: 'FOOD_&_BEVERAGES',
    value: 'Food & Beverages'
  },
  {
    label: 'CHEMICALS',
    value: 'Chemicals'
  },
  {
    label: 'NONMETALLIC_MINERAL_PRODUCT_MANUFACTURING',
    value: 'Nonmetallic Mineral Product Manufacturing'
  },
  {
    label: 'PRIMARY_METALS',
    value: 'Primary Metals'
  },
  {
    label: 'PULP_PAPER',
    value: 'Pulp & Paper'
  },
  {
    label: 'TEXTILES_APPAREL_&_LEATHER',
    value: 'Textiles, Apparel & Leather'
  },
  {
    label: 'PLASTICS_&_RUBBER',
    value: 'Plastics & Rubber'
  },
  {
    label: 'INDUSTRIAL_&_CONSUMER_PRODUCTS',
    value: 'Industrial & Consumer Products'
  },
  {
    label: 'INFORMATION',
    value: 'Information'
  },
  {
    label: 'FINANCE_&_INSURANCE',
    value: 'Finance & Insurance'
  },
  {
    label: 'COLLECTIVE_INVESTMENT_VEHICLES',
    value: 'Collective Investment Vehicles'
  },
  {
    label: 'WHOLESALE_AND_RETAIL_TRADE',
    value: 'Wholesale and Retail Trade'
  },
  {
    label: 'PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_SERVICES',
    value: 'Professional, Scientific and Technical Services'
  },
  {
    label: 'HEALTH_CARE',
    value: 'Health Care'
  },
  {
    label: 'EDUCATION_SERVICES',
    value: 'Education Services'
  },
  {
    label: 'ACCOMMODATION_&_TOURISM_SERVICES',
    value: 'Accommodation & Tourism Services'
  },
  {
    label: 'ELECTRIC_POWER',
    value: 'Electric Power'
  },
  {
    label: 'OTHER_(FOR_NON_INVESTMENT_PROJECTS)',
    value: 'Other (For Non-Investment Projects)'
  }
]

export const auditOfFinancialStatements = [
  {
    label: 'AUDITED_BY_BIG_FOUR_AUDIT_FIRMS_(DELOITTE_PWC_KPMG_EY)',
    value: 'Audited by big four audit firms (Deloitte, PWC, KPMG, EY)'
  },
  {
    label: 'AUDITED_BY_OTHER_AUDIT_FIRMS',
    value: 'Audited by other audit firms'
  },
  {
    label: 'AUDITED_BY_A_REGULATORY_AUTHORITY_(INCLUDING_THE_TAX_AUTHORITY)',
    value: 'Audited by a regulatory authority (including the tax authority)'
  }
]
export const decimal = [
  {
    label: 'None',
    value: 'None'
  },
  {
    label: 'Thousands',
    value: 'Thousands'
  },
  {
    label: 'Millions',
    value: 'Millions'
  },
  {
    label: 'Billions',
    value: 'Billions'
  }
]

export const currencySym = [
  {
    label: 'ALL (Lek)',
    value: '$'
  },
  {
    label: 'AFN (؋)',
    value: '؋'
  },
  {
    label: 'ARS ($)',
    value: '$'
  },
  {
    label: 'AWG (ƒ)',
    value: 'ƒ'
  },
  {
    label: 'AUD ($)',
    value: '$'
  },
  {
    label: 'AZN (₼)',
    value: '₼'
  },
  {
    label: 'BSD ($)',
    value: '$'
  },
  {
    label: 'BBD ($)',
    value: '$'
  },
  {
    label: 'BYN (Br)',
    value: 'Br   '
  },
  {
    label: 'BZD (BZ$)',
    value: 'BZ$   '
  },
  {
    label: 'BMD ($)',
    value: '$'
  },
  {
    label: 'BOB ($b)',
    value: '$b   '
  },
  {
    label: 'BAM (KM)',
    value: 'KM   '
  },
  {
    label: 'BWP (P)',
    value: 'P'
  },
  {
    label: 'BGN (лв)',
    value: 'лв  '
  },
  {
    label: 'BRL (R$)',
    value: 'R$   '
  },
  {
    label: 'BND ($)',
    value: '$'
  },
  {
    label: 'KHR (៛)',
    value: '៛'
  },
  {
    label: 'CAD ($)',
    value: '$'
  },
  {
    label: 'KYD ($)',
    value: '$'
  },
  {
    label: 'CLP ($)',
    value: '$'
  },
  {
    label: 'CNY (¥)',
    value: '¥'
  },
  {
    label: 'COP ($)',
    value: '$'
  },
  {
    label: 'CRC (₡)',
    value: '₡'
  },
  {
    label: 'HRK (kn)',
    value: 'kn)   '
  },
  {
    label: 'CUP (₱)',
    value: '₱'
  },
  {
    label: 'CZK (Kč)',
    value: 'Kč   '
  },
  {
    label: 'DKK (kr)',
    value: 'kr   '
  },
  {
    label: 'DOP (RD$)',
    value: 'RD$   '
  },
  {
    label: 'XCD ($)',
    value: '$'
  },
  {
    label: 'EGP (£)',
    value: '£'
  },
  {
    label: 'SVC ($)',
    value: '$'
  },
  {
    label: 'EUR (£)',
    value: '£'
  },
  {
    label: 'FKP (£)',
    value: '£'
  },
  {
    label: 'FJD ($)',
    value: '$'
  },
  {
    label: 'GHS (¢)',
    value: '¢'
  },
  {
    label: 'GIP (£)',
    value: '£'
  },
  {
    label: 'GTQ (Q)',
    value: 'Q'
  },
  {
    label: 'GGP (£)',
    value: '£'
  },
  {
    label: 'GYD ($)',
    value: '$'
  },
  {
    label: 'HNL (L)',
    value: 'L'
  },
  {
    label: 'HKD ($)',
    value: '$'
  },
  {
    label: 'HUF (Ft)',
    value: 'Ft   '
  },
  {
    label: 'ISK (kr)',
    value: 'kr   '
  },
  {
    label: 'INR ()',
    value: ''
  },
  {
    label: 'IDR (Rp)',
    value: 'Rp   '
  },
  {
    label: 'IRR (﷼)',
    value: '﷼'
  },
  {
    label: 'IMP (£)',
    value: '£'
  },
  {
    label: 'ILS (₪)',
    value: '₪'
  },
  {
    label: 'JMD (J$)',
    value: 'J$   '
  },
  {
    label: 'JPY (¥)',
    value: '¥'
  },
  {
    label: 'JEP (£)',
    value: '£'
  },
  {
    label: 'KZT (лв)',
    value: 'лв    '
  },
  {
    label: 'KPW (₩)',
    value: '₩'
  },
  {
    label: 'KRW (₩)',
    value: '₩'
  },
  {
    label: 'KGS (лв)',
    value: 'лв   '
  },
  {
    label: 'LAK (₭)',
    value: '₭'
  },
  {
    label: 'LBP (£)',
    value: '£'
  },
  {
    label: 'LRD ($)',
    value: '$'
  },
  {
    label: 'MKD (ден)',
    value: 'ден   '
  },
  {
    label: 'MYR (RM)',
    value: 'RM   '
  },
  {
    label: 'MUR (₨)',
    value: '₨'
  },
  {
    label: 'MXN ($)',
    value: '$'
  },
  {
    label: 'MNT (₮)',
    value: '₮'
  },
  {
    label: 'MZN (MT)',
    value: 'MT   '
  },
  {
    label: 'NAD ($)',
    value: '$'
  },
  {
    label: 'NPR (₨)',
    value: '₨'
  },
  {
    label: 'ANG (ƒ)',
    value: 'ƒ'
  },
  {
    label: 'NZD ($)',
    value: '$'
  },
  {
    label: 'NIO (C$)',
    value: 'C$   '
  },
  {
    label: 'NGN (₦)',
    value: '₦'
  },
  {
    label: 'NOK (kr)',
    value: 'kr    '
  },
  {
    label: 'OMR (﷼)',
    value: '﷼'
  },
  {
    label: 'PKR (₨)',
    value: '₨'
  },
  {
    label: 'PAB (B/.)',
    value: 'B/.   '
  },
  {
    label: 'PYG (Gs)',
    value: 'Gs    '
  },
  {
    label: 'PEN (S/.)',
    value: 'S/.   '
  },
  {
    label: 'PHP (₱)',
    value: '₱'
  },
  {
    label: 'PLN (zł)',
    value: 'zł   '
  },
  {
    label: 'QAR (﷼)',
    value: '﷼'
  },
  {
    label: 'RON (lei)',
    value: 'lei   '
  },
  {
    label: 'RUB (₽)',
    value: '₽'
  },
  {
    label: 'SHP (£)',
    value: '£'
  },
  {
    label: 'SAR (﷼)',
    value: '﷼'
  },
  {
    label: 'RSD (Дин.)',
    value: 'Дин     .'
  },
  {
    label: 'SCR (₨)',
    value: '₨'
  },
  {
    label: 'SGD ($)',
    value: '$'
  },
  {
    label: 'SBD ($)',
    value: '$'
  },
  {
    label: 'SOS (S)',
    value: 'S'
  },
  {
    label: 'ZAR (R)',
    value: 'R'
  },
  {
    label: 'LKR (₨)',
    value: '₨'
  },
  {
    label: 'SEK (kr)',
    value: 'kr   '
  },
  {
    label: 'CHF (CHF)',
    value: 'CHF   '
  },
  {
    label: 'SRD ($)',
    value: '$'
  },
  {
    label: 'SYP (£)',
    value: '£'
  },
  {
    label: 'TWD (NT$)',
    value: 'NT$   '
  },
  {
    label: 'THB (฿)',
    value: '฿'
  },
  {
    label: 'TTD (TT$)',
    value: 'TT$   '
  },
  {
    label: 'TRY (TL)',
    value: 'TL'
  },
  {
    label: 'TVD ($)',
    value: '$'
  },
  {
    label: 'UAH (₴)',
    value: '₴'
  },
  {
    label: 'GBP (£)',
    value: '£'
  },
  {
    label: 'USD ($)',
    value: '$'
  },
  {
    label: 'UYU ($U)',
    value: '$U '
  },
  {
    label: 'UZS (лв)',
    value: 'лв'
  },
  {
    label: 'VEF (Bs)',
    value: 'Bs'
  },
  {
    label: 'VND (₫)',
    value: '₫'
  },
  {
    label: 'YER (﷼)',
    value: '﷼'
  },
  {
    label: 'ZWD (Z$)',
    value: 'Z$ '
  }
]

export const baseYear = [
  {
    label: 2019,
    value: 2019
  },
  {
    label: 2018,
    value: 2018
  },
  {
    label: 2017,
    value: 2017
  },
  {
    label: 2016,
    value: 2016
  },
  {
    label: 2015,
    value: 2015
  },
  {
    label: 2014,
    value: 2014
  },
  {
    label: 2013,
    value: 2013
  },
  {
    label: 2012,
    value: 2012
  },
  {
    label: 2011,
    value: 2011
  },
  {
    label: 2010,
    value: 2010
  },
  {
    label: 2009,
    value: 2009
  },
  {
    label: 2008,
    value: 2008
  },
  {
    label: 2007,
    value: 2007
  },
  {
    label: 2006,
    value: 2006
  },
  {
    label: 2005,
    value: 2005
  },
  {
    label: 2004,
    value: 2004
  },
  {
    label: 2003,
    value: 2003
  },
  {
    label: 2002,
    value: 2002
  },
  {
    label: 2001,
    value: 2001
  },
  {
    label: 2000,
    value: 2000
  },
  {
    label: 1999,
    value: 1999
  },
  {
    label: 1998,
    value: 1998
  },
  {
    label: 1997,
    value: 1997
  },
  {
    label: 1996,
    value: 1996
  },
  {
    label: 1995,
    value: 1995
  },
  {
    label: 1994,
    value: 1994
  },
  {
    label: 1993,
    value: 1993
  },
  {
    label: 1992,
    value: 1992
  },
  {
    label: 1991,
    value: 1991
  },
  {
    label: 1990,
    value: 1990
  },
  {
    label: 1989,
    value: 1989
  },
  {
    label: 1988,
    value: 1988
  },
  {
    label: 1987,
    value: 1987
  },
  {
    label: 1986,
    value: 1986
  },
  {
    label: 1985,
    value: 1985
  },
  {
    label: 1984,
    value: 1984
  },
  {
    label: 1983,
    value: 1983
  },
  {
    label: 1982,
    value: 1982
  },
  {
    label: 1981,
    value: 1981
  },
  {
    label: 1980,
    value: 1980
  },
  {
    label: 1979,
    value: 1979
  },
  {
    label: 1978,
    value: 1978
  },
  {
    label: 1977,
    value: 1977
  },
  {
    label: 1976,
    value: 1976
  },
  {
    label: 1975,
    value: 1975
  },
  {
    label: 1974,
    value: 1974
  },
  {
    label: 1973,
    value: 1973
  },
  {
    label: 1972,
    value: 1972
  },
  {
    label: 1971,
    value: 1971
  },
  {
    label: 1970,
    value: 1970
  },
  {
    label: 1969,
    value: 1969
  },
  {
    label: 1968,
    value: 1968
  },
  {
    label: 1967,
    value: 1967
  },
  {
    label: 1966,
    value: 1966
  },
  {
    label: 1965,
    value: 1965
  },
  {
    label: 1964,
    value: 1964
  },
  {
    label: 1963,
    value: 1963
  },
  {
    label: 1962,
    value: 1962
  },
  {
    label: 1961,
    value: 1961
  },
  {
    label: 1960,
    value: 1960
  },
  {
    label: 1959,
    value: 1959
  },
  {
    label: 1958,
    value: 1958
  },
  {
    label: 1957,
    value: 1957
  },
  {
    label: 1956,
    value: 1956
  },
  {
    label: 1955,
    value: 1955
  },
  {
    label: 1954,
    value: 1954
  },
  {
    label: 1953,
    value: 1953
  },
  {
    label: 1952,
    value: 1952
  },
  {
    label: 1951,
    value: 1951
  }
]


export const month = [
  {
    label: 'January',
    value: 1
  },
  {
    label: 'February',
    value: 2
  },
  {
    label: 'March',
    value: 3
  },
  {
    label: 'April',
    value: 4
  },
  {
    label: 'May',
    value: 5
  },
  {
    label: 'June',
    value: 6
  },
  {
    label: 'July',
    value: 7
  },
  {
    label: 'August',
    value: 8
  },
  {
    label: 'September',
    value: 9
  },
  {
    label: 'October',
    value: 10
  },
  {
    label: 'November',
    value: 11
  },
  {
    label: 'December',
    value: 12
  }
]

export const frequency = [
  {
    label: 'Months',
    value: 'M'
  },
  {
    label: 'Quarters',
    value: 'Q'
  },
  {
    label: 'Semi Annual',
    value: '6M'
  },
  {
    label: 'Annual',
    value: 'A'
  }
]
