import Sidebar from 'components/Sidebar/Sidebar'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFirmList, getMyFirms } from 'redux/ApiCalls'

import storeConstants from 'redux/constants/actionTypes'

const MyFirm = () => {
  const dispatch = useDispatch()

  const { token } = useSelector((state) => state.auth)
  const { financial_statements } = useSelector(
    (state) => state.analysisDataReducer
  )
  const showItem = true

  const getMyFirm = async () => {
    await getMyFirms(token)
      .then((res) => {
        dispatch({
          type: storeConstants.GET_MY_SHOW_FIRM,
          payload: res.data,
        })
      })
      .catch(console.log)
  }

  useEffect(() => {
    getMyFirm()
  }, [])

  useEffect(() => {
    if (financial_statements.length) {
      dispatch({
        type: storeConstants.SAVE_ANALYSIS,
        payload: true,
      })
    }
  }, [financial_statements])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getFirmList(token)
        .then((res) => {
          dispatch({
            type: storeConstants.GET_FIRM_LIST,
            payload: res.data.results,
          })
        })
        .catch((e) => console.log(e))
    }, 2000)
    return () => clearTimeout(timeoutId)
  }, [])

  return <div className="container mt-3">
    <Sidebar showItem={showItem} />
  </div>
}

export default MyFirm
