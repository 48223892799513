import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as analysisDataActions from 'redux/actions/analysisDataActions'
import Table from '../components/Table/Table' 
import useCalculateAnalysis from 'hooks/useCalculateAnalysis'
import useHandleInputChange from 'hooks/useHandleInputChange'

const FxPosition = ({ isLoading, setIsLoading }) => {
  const { calculateAnalysis } = useCalculateAnalysis(setIsLoading)
  const [lastKeyPress, setLastKeyPress] = useState(null)
  const {
    firmBackground,
    fx_position,
  } = useSelector((state) => state.analysisDataReducer)

  const { handleInputChange } = useHandleInputChange(analysisDataActions.fxPosition, fx_position, calculateAnalysis)

  useEffect(() => {
    if (lastKeyPress) {
      const timeoutId = setTimeout(() => calculateAnalysis(), 1500)
      return () => clearTimeout(timeoutId)
    }
  }, [lastKeyPress])

  useEffect(() => {
    setIsLoading(false)
  }, [])

  return (
    <Table
      data={fx_position}
      isLoading={isLoading}
      handleInputChange={handleInputChange}
      columnTitle="Fx Position"
      currencySym={firmBackground.currency_sym}
      decimal={firmBackground.decimal}
    />
  )
}

export default FxPosition
