/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  FaFingerprint,
  FaUserPlus,
  FaSignOutAlt,
  FaTimes,
  FaBars
} from "react-icons/fa";
import { PiEqualizer } from "react-icons/pi";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { FaMagnifyingGlassChart } from "react-icons/fa6";
import TabsRouter from "components/TabsRouter";
import storeConstants from "redux/constants/actionTypes";
import { firmDelete, getFirmList } from "redux/ApiCalls";
import { toast } from "react-toastify";
import { FaHandshake } from "react-icons/fa";
import CountryFlag from "components/CountryFlag";
import { MdOutlineAccountBalance } from "react-icons/md";
import { logOut } from "redux/actions/auth";
import { FaBuilding } from "react-icons/fa";


export default function Sidebar({ tab, setTab, token, showItem }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { tabsRouter } = useSelector((state) => state.analysisDataReducer);
  const auth = useSelector((state) => state.auth);
  const { firms } = useSelector((state) => state.getFirmList)

  const [collapseShow, setCollapseShow] = useState(false);
  const [isOpenRequest, setIsOpenRequest] = useState(true);
  const [isOpenAnalysis, setIsOpenAnalysis] = useState(true);
  const [isOpenAccount, setIsOpenAccount] = useState(true);
  const [isOpenMyFirm, setIsOpenMyFirm] = useState(true);
  const [selectedFirm, setSelectedFirm] = useState(null);

  useEffect(() => {
    if (tab === 1 && token) {
      getFirmList(token)
        .then((res) => {
          dispatch({
            type: storeConstants.GET_FIRM_LIST,
            payload: res.data.results,
          });
        })
        .catch((e) => console.log(e));
    }
  }, [tab, dispatch, token]);

  useEffect(() => {
    if (tabsRouter) setTab(0);
  }, [tabsRouter, setTab]);


  const toggleAccordionRequest = () => {
    setIsOpenRequest(!isOpenRequest);
  };

  const toggleAccordionAnalysis = () => {
    setIsOpenAnalysis(!isOpenAnalysis);
  };

  const toggleAccordionMyFirm = () => {
    setIsOpenMyFirm(!isOpenMyFirm);
  };

  const toggleAccordionAccount = () => {
    setIsOpenAccount(!isOpenAccount);
  };


  return (
    <>
      <nav className={`w-full bg-white shadow-xl md:w-64 fixed md:top-0 md:bottom-0 md:left-0 z-10 flex flex-col border border-white items-stretch md:overflow-y-hidden md:flex-nowrap py-3 px-4 ${showItem ? 'mt-14' : ''
        }`}>
        <div className=" w-full-screen flex justify-between items-center">
          {
            !showItem &&
            <a href="/homepage" className="-m-1.5 p-1.5 flex-1 ">
              <h1 className="flex items-center text-1xl font-bold text-blue-700 ">
                FinancialPrediction
                <span className="bg-blue-100 text-blue-800 text-base font-semibold me-2 px-2 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ms-1">
                  Al
                </span>
              </h1>
            </a>
          }
          <div className="flex justify-between items-center md:hidden ">
            <CountryFlag />
            <button
              onClick={() => setCollapseShow(!collapseShow)}
              className="text-black opacity-50 text-xl  rounded focus:outline-none align-end pl-6"
            >
              {collapseShow ? <FaTimes color={""} /> : <FaBars color={""} />}
            </button>
          </div>
          <div className="h-[1px] mt-2  bg-gray-200">
          </div>
        </div>
        <div
          className={`transition-all duration-300 ease-in-out ${collapseShow ? "block" : "hidden"} md:flex md:flex-col md:items-stretch text-dark`}>
              {auth.token && <ul className="flex flex-col list-none mt-2">
            <li className="hs-accordion">
              <button
                type="button"
                className="hs-accordion-toggle w-full text-start flex items-center gap-x-3 py-2 px-2.5 text-sm text-gray-700 rounded-lg bg-blue-50  "
                aria-expanded={isOpenMyFirm ? 'true' : 'false'}
                onClick={toggleAccordionMyFirm}
              >
                <FaBuilding />
                {intl.formatMessage({
                  id: 'NAV.BUTTON.MY_FIRM',
                })}
              </button>
              <div
                id="account-accordion-sub-1-collapse-1"
                className={`hs-accordion-content w-full overflow-hidden transition-[height] duration-300 ${isOpenMyFirm ? 'block' : 'hidden'}`}
                role="region"
                aria-labelledby="account-accordion"
              >
                <ul className="pt-1 ps-7 space-y-1">
                  {firms.map((firm) => (
                    <li key={firm}>
                      <div className="flex justify-between items-center gap-2 h-6">
                        <Link to={`/analysis/${firm.slug}`} className="h-full flex-1">
                          <button
                            className={`border border-gray-400 text-black font-medium text-xs px-3 py-1 rounded-md transition w-28 h-full truncate flex items-center justify-center hover:border-blue-700 ${selectedFirm === firm.slug ? 'bg-blue-700' : 'hover:bg-blue-100'
                              }`}
                            onClick={() => {
                              setSelectedFirm(firm.slug);
                              dispatch({
                                type: storeConstants.TABS_ROUTER,
                                payload: true,
                              });
                            }}
                          >
                            {firm.name.length > 5 ? `${firm.name.slice(0, 5)}...` : firm.name}
                          </button>
                        </Link>
                        <button
                          className="border border-red-500 text-red-500 hover:bg-red-500 hover:text-white font-medium text-xs  py-1 rounded-md transition w-full h-full flex items-center justify-center"
                          onClick={() => {
                            firmDelete(auth.token, firm.slug).then(() => {
                              toast.success(`${firm.name} deleted`);
                              getFirmList(auth.token)
                                .then((res) => {
                                  dispatch({
                                    type: storeConstants.GET_FIRM_LIST,
                                    payload: res.data.results,
                                  });
                                  changeLocation('/analysis');
                                })
                                .catch((e) => console.log(e));
                            });
                          }}
                        >
                          <i className="fa fa-trash text-sm"></i>
                        </button>
                      </div>
                    </li>
                  ))}
                  {
                    auth.token && <li>
                      <Link to="/analysis" onClick={() => changeLocation('/analysis')} className="h-6 mt-2 flex">
                        <button
                          type="submit"
                          className="bg-blue-600 hover:bg-blue-700 text-white font-medium text-xs px-3 py-1 rounded-md transition w-full h-full flex items-center justify-center"
                        >
                          {intl.formatMessage({ id: 'FIRMACCORDION.BUTTON_CREATE' })}
                          <i className="fa fa-plus ml-2" aria-hidden="true"></i>
                        </button>
                      </Link>
                    </li>
                  }
                </ul>
              </div>
            </li>
          </ul>
          }
          {
            !showItem &&
            <ul className="flex flex-col list-none mt-2">
              <li className="hs-accordion">
                <button
                  type="button"
                  className="hs-accordion-toggle w-full text-start flex items-center gap-x-3 py-2 px-2.5 text-sm text-gray-700 rounded-lg bg-blue-50  "
                  aria-expanded={isOpenAnalysis ? 'true' : 'false'}
                  onClick={toggleAccordionAnalysis}
                >
                  <FaMagnifyingGlassChart />
                  Financial Analysis
                </button>

                <div
                  id="account-accordion-sub-1-collapse-1"
                  className={`hs-accordion-content w-full overflow-hidden transition-[height] duration-300 ${isOpenAnalysis ? 'block' : 'hidden'}`}
                  role="region"
                  aria-labelledby="account-accordion"
                >
                  <ul className="pt-1 ps-7 space-y-1">
                    <li>
                      <TabsRouter
                        changePage={() => setTab(1)}
                        activeTab={tab}
                        setActiveTab={setTab}
                      />
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          }
        
          <>
            <ul className="flex flex-col list-none mt-2">
              <li className="hs-accordion">
                <button
                  type="button"
                  className="hs-accordion-toggle w-full text-start flex items-center gap-x-3 py-2 px-2.5 text-sm text-gray-700 rounded-lg bg-blue-50  "
                  aria-expanded={isOpenRequest ? 'true' : 'false'}
                  onClick={toggleAccordionRequest}
                >
                  <FaMoneyCheckDollar />
                  Financial Requests
                </button>
                <div
                  id="account-accordion-sub-1-collapse-1"
                  className={`hs-accordion-content w-full overflow-hidden transition-[height] duration-300 ${isOpenRequest ? 'block' : 'hidden'}`}
                  role="region"
                  aria-labelledby="account-accordion"
                >
                  <ul className="pt-1 ps-7 space-y-1">
                    <li>
                      <Link
                        to="/loan"
                        className="flex items-center gap-x-3 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-blue-50 focus:outline-none focus:bg-gray-100 "
                      >
                        <FaHandshake />
                        <span> {intl.formatMessage({ id: 'SIDEBAR_ROUTES.LOANS_REQUESTS' })}</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/equity"
                        className="flex items-center gap-x-3 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-blue-50 focus:outline-none focus:bg-gray-100 "
                      >
                        <PiEqualizer />
                        <span> {intl.formatMessage({ id: 'SIDEBAR_ROUTES.EQUITY_REQUESTS' })}</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            <ul className="flex flex-col list-none mt-2">
              <li className="hs-accordion cursor-pointer">
                <button
                  type="button"
                  className="hs-accordion-toggle w-full text-start flex items-center gap-x-3 py-2 px-2.5 text-sm text-gray-700 rounded-lg bg-blue-50  "
                  aria-expanded={isOpenAccount ? 'true' : 'false'}
                  onClick={toggleAccordionAccount}
                >
                  <svg
                    className="size-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <circle cx="18" cy="15" r="3" />
                    <circle cx="9" cy="7" r="4" />
                    <path d="M10 15H6a4 4 0 0 0-4 4v2" />
                    <path d="m21.7 16.4-.9-.3" />
                    <path d="m15.2 13.9-.9-.3" />
                    <path d="m16.6 18.7.3-.9" />
                    <path d="m19.1 12.2.3-.9" />
                    <path d="m19.6 18.7-.4-1" />
                    <path d="m16.8 12.3-.4-1" />
                    <path d="m14.3 16.6 1-.4" />
                    <path d="m20.7 13.8 1-.4" />
                  </svg>
                  Account
                </button>
                <div
                  id="account-accordion-sub-1-collapse-1"
                  className={`hs-accordion-content w-full overflow-hidden transition-[height] duration-300 ${isOpenAccount ? 'block' : 'hidden'}`}
                  role="region"
                  aria-labelledby="account-accordion"
                >
                  <ul className="pt-1 ps-7 space-y-1">

                    {
                      <>
                        {auth.token ? (
                          <>
                            <Link
                              to="/myfirm"
                              className="flex items-center gap-x-3 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-blue-50 focus:outline-none focus:bg-gray-100 "
                            >
                              <MdOutlineAccountBalance className="mr-2" />
                              <span>
                                {intl.formatMessage({ id: 'NAV.BUTTON.MY_FIRM' })}
                              </span>
                            </Link>
                            <li>
                              <Link
                                onClick={() => {
                                  dispatch(logOut());
                                  toast.info("Successfully logged out");
                                }}
                                to="/homepage"
                                className="flex items-center gap-x-3 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-blue-50 focus:outline-none focus:bg-gray-100 "
                              >
                                <FaSignOutAlt className="mr-2" />
                                <span>{intl.formatMessage({ id: "NAV.BUTTON.LOGOUT" })}</span>
                              </Link>
                            </li>
                          </>
                        ) : (
                          <>
                            <li>
                              <Link
                                to="/login"
                                className="flex items-center gap-x-3 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-blue-50 focus:outline-none focus:bg-gray-100 "
                              >
                                <FaFingerprint className="mr-2" />
                                <span>{intl.formatMessage({ id: "NAV.BUTTON.LOGIN" })}</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/register"
                                className="flex items-center gap-x-3 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-blue-50 focus:outline-none focus:bg-gray-100 "
                              >
                                <FaUserPlus className="mr-2" />
                                <span>{intl.formatMessage({ id: "NAV.BUTTON.SIGN_UP" })}</span>
                              </Link>
                            </li>
                          </>
                        )}
                      </>
                    }
                  </ul>
                </div>
              </li>
            </ul>
          </>
        </div>
      </nav>
    </>
  );
}
