import { useDispatch, useSelector } from 'react-redux'
import { analysis } from 'redux/ApiCalls'
import * as analysisDataActions from 'redux/actions/analysisDataActions'

const useCalculateAnalysis = (setIsLoading) => {
  const dispatch = useDispatch()
  const { token } = useSelector((state) => state.auth)
  const {
    firmBackground,
    financial_statements,
    fx_position,
    maturity_short_term,
    initial_custom_scenario,
    business_plan,
    analysisResult,
  } = useSelector((state) => state.analysisDataReducer)

  const trueOrFalse = firmBackground.industry !== 'Finance & Insurance'

  const processData = (data, key, postData) => {
    data.map((item, index) => {
      item.values.map((value) => {
        let newValue =
          typeof value.value === 'string'
            ? Number(value.value.replace(/,/g, ''))
            : value.value
  
        if (!postData.analysis_data[key]) {
          postData.analysis_data[key] = {}
        }
  
        if (!postData.analysis_data[key][value.key]) {
          postData.analysis_data[key][value.key] = []
        }
  
        if (index === 0) {
          postData.analysis_data[key][value.key] = [newValue]
        } else {
          postData.analysis_data[key][value.key].push(newValue)
        }
      })
    })
  }
  

  const calculateAnalysis = () => {
    setIsLoading(true)

    const postData = {
      firm_background: firmBackground,
      analysis_data: {
        financial: {},
        fx: {},
        maturity: {},
        business_plan: {},
        scenario: {},
      },
      is_analysis_real: trueOrFalse,
      enable_custom_scenario: analysisResult?.enable_custom_scenario || false,
    }

    processData(financial_statements, 'financial', postData)
    processData(fx_position, 'fx', postData)
    processData(maturity_short_term, 'maturity', postData)
    processData(business_plan, 'business_plan', postData)

    if (analysisResult?.scenarios) {
      processData(analysisResult.scenarios, 'scenario', postData)
    } else {
      processData(initial_custom_scenario, 'scenario', postData)
    }

    analysis(token, postData)
      .then(({ data }) => {
        dispatch(analysisDataActions.fetchRealAnalysis(data))
        setIsLoading(false)
      })
      .catch(() => setIsLoading(false))
  }

  return { calculateAnalysis }
}

export default useCalculateAnalysis
