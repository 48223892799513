import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as analysisDataActions from 'redux/actions/analysisDataActions'
import Table from '../components/Table/Table' 
import useCalculateAnalysis from 'hooks/useCalculateAnalysis'
import useHandleInputChange from 'hooks/useHandleInputChange'

const BusinessPlan = ({ isLoading, setIsLoading }) => {
  const [lastKeyPress, setLastKeyPress] = useState(null)
  const { calculateAnalysis } = useCalculateAnalysis(setIsLoading)

  const {
    firmBackground,
    business_plan,
  } = useSelector((state) => state.analysisDataReducer)

  const { handleInputChange } = useHandleInputChange(analysisDataActions.businessPlan, business_plan, calculateAnalysis)


  useEffect(() => {
    const timeoutId = setTimeout(() => calculateAnalysis(), 1500)
    return () => clearTimeout(timeoutId)
  }, [lastKeyPress])

  useEffect(() => {
    setIsLoading(false)
  }, [])



  return (
    <Table
    data={business_plan}
    isLoading={isLoading}
    handleInputChange={handleInputChange}
    columnTitle="Business Plan"
    currencySym={firmBackground.currency_sym}
    decimal={firmBackground.decimal}
  />
  )
}

export default BusinessPlan
