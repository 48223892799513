
import { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2"; 
import { useSelector } from "react-redux"; 
import { useIntl } from 'react-intl'
import styles from 'assets/styles/pages/report.module.scss'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import MyLoader from "./MyLoader";
import { TableStyle } from "./TableStyles/TableStyles";
import CurrencyInfo from "./CurrencyInfo";
import ProgressTableAndExcelExport from "./ProgressTableAndExcelExport";
import ReactTooltip from 'react-tooltip'
import { analysis } from "redux/ApiCalls";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const RealAnalysisReport = ({isLoading}) => {
  const [selectedMetrics, setSelectedMetrics] = useState([
    "Growth Rate Of Revenues",
    "Gross Margin",
    "EBITDA Margin",
    "Profit Margin",
    "ICR",
    "DSCR",
    "ROA",
    "ROE",
    "Leverage Ratio",
  ]);

  const availableMetrics = [
    "Growth Rate Of Revenues",
    "Gross Margin",
    "EBITDA Margin",
    "Profit Margin",
    "ICR",
    "DSCR",
    "ROA",
    "ROE",
    "Leverage Ratio",
  ];

  const { analysisResult } = useSelector((state) => state.analysisDataReducer);
  const intl = useIntl()
  const { custom_financial_statements, financial_statements, enable_custom_scenario } = analysisResult;
  const labels = financial_statements.map((data) => data.year); 
  const [lastKeyPress, setLastKeyPress] = useState(null)
  const [chartIndicatorsDatas, setChartIndicatorsDatas] = useState({});
  const { token } = useSelector((state) => state.auth)
  const { rating_valuation } = useSelector(
    (state) => state.analysisDataReducer.analysisResult
  )
  const {
    fx_position,
    maturity_short_term,
    business_plan,
    initial_custom_scenario,
    firmBackground,
  } = useSelector((state) => state.analysisDataReducer)
  let { flowOrstock } = firmBackground
  useEffect(() => {
    indicatorsTables([
      "Growth Rate Of Revenues",
      "Gross Margin",
      "EBITDA Margin",
      "Profit Margin",
      "ICR",
      "DSCR",
      "ROA",
      "ROE",
      "Leverage Ratio",
    ]);
  }, []);

  const indicatorsTables = (keyArrays) => {
    let newChartData = {};

    keyArrays.forEach((key) => {
      const dataSet = [];

      const baselineDataSet = {
        backgroundColor: "rgba(75, 192, 192, 0.2)", 
        label: "Baseline Scenario",
        data: [],
      };
      const customDataSet = {
        backgroundColor: "rgba(153, 102, 255, 0.2)", 
        label: "Custom Scenario",
        data: [],
      };

      // Fetch baseline data from financial statements
      analysisResult.indicators.forEach((datas) => {
        const indicatorsData = datas.values.find((item) => item.label === key);
        if (indicatorsData) baselineDataSet.data.push(indicatorsData.value);
      });
      dataSet.push(baselineDataSet);

      if (enable_custom_scenario) {
        analysisResult.custom_indicators.forEach((datas) => {
          const indicatorsData = datas.values.find((item) => item.label === key);
          if (indicatorsData) customDataSet.data.push(indicatorsData.value);
        });
        dataSet.push(customDataSet);
      }

      newChartData[key] = { labels, datasets: dataSet };
    });

    setChartIndicatorsDatas(newChartData);
  };

  const handleCheckboxChange = (metric) => {
    setSelectedMetrics((prevSelected) =>
      prevSelected.includes(metric)
        ? prevSelected.filter((item) => item !== metric)
        : [...prevSelected, metric]
    );
  };

  const handleSelectAll = () => {
    setSelectedMetrics(availableMetrics);
  };

  const handleDeselectAll = () => {
    setSelectedMetrics([]);
  };

  const createChartOptions = (titleText) => {
    return {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: titleText,
        },
      },
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };
  };

  

  useEffect(() => {
    const timeoutId = setTimeout(() => calculateAnalysis(), 1500)
    return () => clearTimeout(timeoutId)
  }, [financial_statements, lastKeyPress])

  const trueOrFalse = (() => {
    if (firmBackground.industry === 'Finance & Insurance') {
      return false
    }
    return true
  })()

  const calculateAnalysis = () => {
    const postData = {
      firm_background: firmBackground,

      analysis_data: {
        financial: {},
        fx: {},
        maturity: {},
        business_plan: {},
        scenario: {},
      },
      is_analysis_real: trueOrFalse,
      enable_custom_scenario: analysisResult.enable_custom_scenario,
    }

    financial_statements.map((item, index) => {
      if (index === 0) {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.financial[value.key] = [newValue]
        })
      } else {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.financial[value.key].push(newValue)
        })
      }
    })

    fx_position.map((item, index) => {
      if (index === 0) {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.fx[value.key] = [newValue]
        })
      } else {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.fx[value.key].push(newValue)
        })
      }
    })

    maturity_short_term.map((item, index) => {
      if (index === 0) {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.maturity[value.key] = [newValue]
        })
      } else {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.maturity[value.key].push(newValue)
        })
      }
    })

    business_plan.map((item, index) => {
      if (index === 0) {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.business_plan[value.key] = [newValue]
        })
      } else {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.business_plan[value.key].push(newValue)
        })
      }
    })

    if (analysisResult?.scenarios) {
      analysisResult.scenarios.map((item, index) => {
        if (index === 0) {
          item.values.map((value) => {
            let newValue =
              typeof value.value === 'string'
                ? Number(value.value.replace(/,/g, ''))
                : value.value
            postData.analysis_data.scenario[value.key] = [newValue]
          })
        } else {
          item.values.map((value) => {
            let newValue =
              typeof value.value === 'string'
                ? Number(value.value.replace(/,/g, ''))
                : value.value
            postData.analysis_data.scenario[value.key].push(newValue)
          })
        }
      })
    } else {
      initial_custom_scenario.map((item, index) => {
        if (index === 0) {
          item.values.map((value) => {
            let newValue =
              typeof value.value === 'string'
                ? Number(value.value.replace(/,/g, ''))
                : value.value
            postData.analysis_data.scenario[value.key] = [newValue]
          })
        } else {
          item.values.map((value) => {
            let newValue =
              typeof value.value === 'string'
                ? Number(value.value.replace(/,/g, ''))
                : value.value
            postData.analysis_data.scenario[value.key].push(newValue)
          })
        }
      })
    }

    analysis(token, postData)
      .then(({ data }) => {
        dispatch(analysisDataActions.fetchRealAnalysis(data))
      })
      .catch((err) => err)
  }


  const handleInputChange = (value, key, index) => {
    const newFinancialStatements = [...financial_statements]
    const findValue = newFinancialStatements[index].values.find(
      (item) => item.key === key
    )

    findValue.value = value

    dispatch(analysisDataActions.financialStatements(newFinancialStatements))

    setLastKeyPress(newFinancialStatements)
  }
  const formatDate = (year, month) => {
    const date = new Date(Number(year), Number(month) - 1, 1);
    return `${date.toLocaleString('en-US', { month: 'short' })} ${date.toLocaleString('en-US', { year: 'numeric' })}`;
  };

  const renderHeaders = (data, isProjection) => {
    return data?.map((yearData, columnIndex) => {
      const [year, month] = yearData.year.split('-');
      return (
        <th
          key={columnIndex}
          className={`${TableStyle.ThStyle} ${isProjection ? 'bg-primary !text-white' : 'bg-primary !text-white'
            } px-2`}
        >
          {isProjection ? formatDate(year, month) : yearData.year}
        </th>
      );
    });
  };

  console.log("custom_financial_statements[0]?.values",custom_financial_statements[0]?.values )


  const table = (
    <div className="overflow-x-auto  p-2 bg-white rounded-lg shadow-lg">
      {
        rating_valuation ?
          <table className="!border border-[#d2d5d9]  table-auto  ">
            <thead className="sticky top-0 text-end  z-9">
              <tr >
                <th className={`${TableStyle.ThStyleHeaderText}  text-center text-bold bg-white `}>
                  <span className="flex">
                    {intl.formatMessage({ id: 'FINANCIAL_CHART.CURRENCY' })}{firmBackground.currency_sym}
                    <span className="flex ">
                      {intl.formatMessage({ id: 'FINANCIAL_CHART.DECIMAL' })}{firmBackground.decimal}
                    </span>
                  </span>
                </th>
                <th colSpan={2} className={`${TableStyle.ThStyleHeaderText} text-dark text-center bg-white text-xs sm:text-sm`}>
               
                </th>

                {isLoading ? (
                  <>

                    <th colSpan={analysisResult?.financial_statements?.length || 1} className={`${TableStyle.ThStyleHeader}  text-black text-center !bg-gray-200 text-xs sm:text-sm`}>
                      Projection
                    </th>
                  </>
                ) : (
                  <th colSpan={analysisResult?.financial_statements?.length || 1} className={`${TableStyle.ThStyleHeader}  text-black text-center !bg-gray-200 text-xs sm:text-sm`}>
                    Projection
                  </th>
                )}
              </tr>
            </thead>
            <thead className="sticky top-[20px] text-end  z-9">
              <tr>
                <th className={TableStyle.ThStyleHeaderText}>
                  <CurrencyInfo showFirstButton={true} showSecondButton={true} />
                </th>
                <th className={`${TableStyle.ThStyleHeaderText} text-dark text-center`} colSpan={1}>
                  <ProgressTableAndExcelExport showFirstButton={true} />
                </th>
                <th className={`${TableStyle.ThStyleHeader} text-black text-end`} colSpan={1}>
                  <ProgressTableAndExcelExport showSecondButton={true} />
                </th>
                <th colSpan={analysisResult?.financial_statements?.length || 1} className={`${TableStyle.ThStyleHeader} text-black !bg-gray-200  text-center text-xs sm:text-sm`}>
                </th>


              </tr>

            </thead>
            <thead className="sticky top-[44px] text-center  rounded-md bg-gray-100 z-5">
              <tr>
                <th className={`${TableStyle.TdLabelStyle} !bg-gray-300 !font-semibold`}>
                  {intl.formatMessage({ id: 'FINANCIAL_STATEMENT.BALANCE_SHEET_TABLE.TITLE' })}
                </th>
                {renderHeaders(flowOrstock ? analysisResult?.financial_statements : analysisResult?.financial_statements_stock, true)}
              </tr>
            </thead>
            
            <tbody>
              {financial_statements[0]?.values
                .slice(0, firmBackground.industry === 'Finance & Insurance' ? 14 : 12)
                .map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    className={'bg-gray-200'}
                  >
                    <td
                      className={`${TableStyle.TdLabelStyle} text-gray-800 font-normal !border-l border-b border-[#d2d5d9] truncate sm:max-w-none`}
                      title={intl.formatMessage({ id: `tooltip_${row.key}` })}

                      data-tip={
                        row.label === 'Cash and Banks'
                          ? intl.formatMessage({
                            id: 'tooltip_cash_and_banks',
                          })
                          : row.label === 'Cash And Banks'
                            ? intl.formatMessage({
                              id: 'tooltip_cash_and_banks',
                            })
                            : row.label === 'Debt Securities'
                              ? intl.formatMessage({
                                id: 'tooltip_equity_securities',
                              })
                              : row.label === 'Equity Securities'
                                ? intl.formatMessage({
                                  id: 'tooltip_equity_securities',
                                })
                                : row.label === 'Debt Securities'
                                  ? intl.formatMessage({
                                    id: 'tooltip_debt_securities',
                                  })
                                  : row.label === 'Gross Loans'
                                    ? intl.formatMessage({
                                      id: 'tooltip_gross_loans',
                                    })
                                    : row.label === 'Non-performing Loans'
                                      ? intl.formatMessage({
                                        id: 'tooltip_non_performing_loans',
                                      })
                                      : row.label === 'General Reserves'
                                        ? intl.formatMessage({
                                          id: 'tooltip_general_reserves',
                                        })
                                        : row.label === 'Specific Reserves'
                                          ? intl.formatMessage({
                                            id: 'tooltip_specific_reserves',
                                          })
                                          : row.label === 'Total Assets'
                                            ? intl.formatMessage({
                                              id: 'tooltip_total_assets',
                                            })
                                            : row.label === 'Deposits'
                                              ? intl.formatMessage({
                                                id: 'tooltip_deposits',
                                              })
                                              : row.label === 'Borrowings'
                                                ? intl.formatMessage({
                                                  id: 'tooltip_borrowings',
                                                })
                                                : row.label === 'Equity'
                                                  ? intl.formatMessage({
                                                    id: 'tooltip_equity',
                                                  })
                                                  : row.label === 'Total Liabilities And Equity'
                                                    ? intl.formatMessage({
                                                      id: 'tooltip_total_liabilities_and_equity',
                                                    })
                                                    : row.label === 'Guarantees'
                                                      ? intl.formatMessage({
                                                        id: 'tooltip_guarantees',
                                                      })
                                                      : row.label === 'Net FX Position'
                                                        ? intl.formatMessage({
                                                          id: 'tooltip_net_fx_position',
                                                        })
                                                        : row.label === 'Interest Incomes'
                                                          ? intl.formatMessage({
                                                            id: 'tooltip_interest_incomes',
                                                          })
                                                          : row.label === 'Interest Expenses'
                                                            ? intl.formatMessage({
                                                              id: 'tooltip_interest_expenses',
                                                            })
                                                            : row.label === 'Equity Gains and Losses'
                                                              ? intl.formatMessage({
                                                                id: 'tooltip_equity_gains_losses',
                                                              })
                                                              : row.label === 'Provisions'
                                                                ? intl.formatMessage({
                                                                  id: 'tooltip_provisions',
                                                                })
                                                                : row.label === 'Income Tax'
                                                                  ? intl.formatMessage({
                                                                    id: 'tooltip_income_tax',
                                                                  })
                                                                  : row.label === 'Net Income'
                                                                    ? intl.formatMessage({
                                                                      id: 'tooltip_net_income',
                                                                    })
                                                                    : row.label === 'Regulatory Capital'
                                                                      ? intl.formatMessage({
                                                                        id: 'tooltip_capital',
                                                                      })
                                                                      : row.label === 'Risk Weighted Assets'
                                                                        ? intl.formatMessage({
                                                                          id: 'tooltip_rwas',
                                                                        })
                                                                        : row.label === 'Gross Loans'
                                                                          ? intl.formatMessage({
                                                                            id: 'tooltip_loans',
                                                                          })
                                                                          : row.label === 'Inventories'
                                                                            ? intl.formatMessage({
                                                                              id: 'tooltip_inventories',
                                                                            })
                                                                            : row.label === 'Account Receivables'
                                                                              ? intl.formatMessage({
                                                                                id: 'tooltip_account_receivables',
                                                                              })
                                                                              : row.label === 'Tangible And Intangible Assets'
                                                                                ? intl.formatMessage({
                                                                                  id: 'tooltip_tangible_and_intangible_assets',
                                                                                })
                                                                                : row.label === 'Account Payables'
                                                                                  ? intl.formatMessage({
                                                                                    id: 'tooltip_account_payables',
                                                                                  })
                                                                                  : row.label === 'Debt'
                                                                                    ? intl.formatMessage({
                                                                                      id: 'Debt',
                                                                                    })
                                                                                    : row.label === 'Balancing'
                                                                                      ? intl.formatMessage({
                                                                                        id: 'tooltip_balancing',
                                                                                      })
                                                                                      : ''
                      }
                    >
                      <ReactTooltip />

                      {intl.formatMessage({ id: row.key })}
                    </td>
                    {analysisResult?.financial_statements &&
                      analysisResult?.financial_statements.map((yearData, columnIndex) => (
                        <td key={columnIndex} className={`${TableStyle.TdStyle} text-end`}>
                          {isLoading ? (
                            <MyLoader />

                          ) : (
                            <input
                              className={` border-none text-end text-xs focus:outline-none focus:ring-2  !bg-gray-200 m-0 py-1  text-gray-900 px-2 `}
                              type="text"
                              value={yearData.values[rowIndex]?.value.toLocaleString('en-US')}
                              disabled
                            />
                          )}
                        </td>
                      ))}


                  </tr>
                ))}
            </tbody>
            <thead className="text-center  bg-primary rounded-md">
              <tr>
                <th className={`${TableStyle.TdLabelStyle} !bg-gray-300 !font-semibold `}>
                  {intl.formatMessage({ id: 'FINANCIAL_STATEMENT.INCOME_STATEMETNS_TABLE.TITLE' })}
                </th>
                <></> {renderHeaders(flowOrstock ? analysisResult?.financial_statements : analysisResult?.financial_statements_stock, true)}
              </tr>
            </thead>
            <tbody>
              {financial_statements[0]?.values
                .slice(firmBackground.industry === 'Finance & Insurance' ? 14 : 12)
                .map((row, rowIndex) => (
                  <tr key={rowIndex} className={'bg-gray-200'}>
                    <td
                      className={`${TableStyle.TdLabelStyle} text-gray-800 font-normal !border-l border-b border-[#d2d5d9]`}
                      title={intl.formatMessage({ id: `tooltip_${row.key}` })}
                      data-tip={intl.formatMessage({ id: `tooltip_${row.key}` })}
                    >
                      <ReactTooltip />
                      {intl.formatMessage({ id: row.key })}
                    </td>
                   
                    {analysisResult?.financial_statements &&
                      analysisResult?.financial_statements.map((yearData, columnIndex) => (
                        <td key={columnIndex} className={`${TableStyle.TdStyle} text-end w-[150px] sm:w-[100px] `}>
                          {isLoading ? (
                            <MyLoader />

                          ) : (
                            <input
                              className=" border-none text-end text-xs focus:outline-none focus:ring-2 !bg-gray-200 m-0 py-1 text-gray-900 px-2"
                              type="text"
                              value={yearData?.values[rowIndex + (firmBackground.industry === 'Finance & Insurance' ? 14 : 12)]
                                .value.toLocaleString('en-US')}
                              disabled
                            />
                          )}
                        </td>
                      ))}
                  </tr>
                ))}
            </tbody>
          </table> :
          <table className=" !border border-[#d2d5d9] rounded-md table-auto">
            <thead className="sticky top-0 text-end  z-9">
              <tr >
                <th className={`${TableStyle.ThStyleHeaderText}  text-center text-bold bg-white `}>
                  <span className="flex">
                    {intl.formatMessage({ id: 'FINANCIAL_CHART.CURRENCY' })}{firmBackground.currency_sym}
                    <span className="flex ">
                      {intl.formatMessage({ id: 'FINANCIAL_CHART.DECIMAL' })}{firmBackground.decimal}
                    </span>
                  </span>
                </th>
                <th colSpan={2} className={`${TableStyle.ThStyleHeaderText} w-[150px] sm:w-[100px] text-dark text-center bg-white text-xs sm:text-sm`}>
                
                </th>

              </tr>
            </thead>
            <thead className="sticky top-[20px] text-end  z-9">
              <tr>
                <th className={TableStyle.ThStyleHeaderText}>
                  <CurrencyInfo showFirstButton={true} showSecondButton={true} />
                </th>
                <>
                  <th className={`${TableStyle.ThStyleHeaderText} text-dark text-center`} colSpan={1}>
                    <ProgressTableAndExcelExport showFirstButton={true} />
                  </th>
                  <th className={`${TableStyle.ThStyleHeader} text-black text-end`} colSpan={1}>
                    <ProgressTableAndExcelExport showSecondButton={true} />
                  </th>

                </>


              </tr>
            </thead>
            <thead className="sticky top-[44px] text-center  rounded-md bg-gray-100 z-5">
              <tr>
                <th className={`${TableStyle.TdLabelStyle} !bg-gray-300 !font-semibold`}>
                  {intl.formatMessage({ id: 'FINANCIAL_STATEMENT.BALANCE_SHEET_TABLE.TITLE' })}
                </th>
                {renderHeaders(financial_statements, true)}
                {renderHeaders(flowOrstock ? analysisResult?.financial_statements : analysisResult?.financial_statements_stock, true)}
              </tr>
            </thead>

            <tbody>
              {financial_statements[0]?.values
                .slice(0, firmBackground.industry === 'Finance & Insurance' ? 14 : 12)
                .map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    className={'bg-gray-200'}
                  >
                    <td
                      className={`${TableStyle.TdLabelStyle} text-gray-800 font-normal !border-l border-b border-[#d2d5d9] truncate  sm:max-w-none`}
                      title={intl.formatMessage({ id: `tooltip_${row.key}` })}

                      data-tip={
                        row.label === 'Cash and Banks'
                          ? intl.formatMessage({
                            id: 'tooltip_cash_and_banks',
                          })
                          : row.label === 'Cash And Banks'
                            ? intl.formatMessage({
                              id: 'tooltip_cash_and_banks',
                            })
                            : row.label === 'Debt Securities'
                              ? intl.formatMessage({
                                id: 'tooltip_equity_securities',
                              })
                              : row.label === 'Equity Securities'
                                ? intl.formatMessage({
                                  id: 'tooltip_equity_securities',
                                })
                                : row.label === 'Debt Securities'
                                  ? intl.formatMessage({
                                    id: 'tooltip_debt_securities',
                                  })
                                  : row.label === 'Gross Loans'
                                    ? intl.formatMessage({
                                      id: 'tooltip_gross_loans',
                                    })
                                    : row.label === 'Non-performing Loans'
                                      ? intl.formatMessage({
                                        id: 'tooltip_non_performing_loans',
                                      })
                                      : row.label === 'General Reserves'
                                        ? intl.formatMessage({
                                          id: 'tooltip_general_reserves',
                                        })
                                        : row.label === 'Specific Reserves'
                                          ? intl.formatMessage({
                                            id: 'tooltip_specific_reserves',
                                          })
                                          : row.label === 'Total Assets'
                                            ? intl.formatMessage({
                                              id: 'tooltip_total_assets',
                                            })
                                            : row.label === 'Deposits'
                                              ? intl.formatMessage({
                                                id: 'tooltip_deposits',
                                              })
                                              : row.label === 'Borrowings'
                                                ? intl.formatMessage({
                                                  id: 'tooltip_borrowings',
                                                })
                                                : row.label === 'Equity'
                                                  ? intl.formatMessage({
                                                    id: 'tooltip_equity',
                                                  })
                                                  : row.label === 'Total Liabilities And Equity'
                                                    ? intl.formatMessage({
                                                      id: 'tooltip_total_liabilities_and_equity',
                                                    })
                                                    : row.label === 'Guarantees'
                                                      ? intl.formatMessage({
                                                        id: 'tooltip_guarantees',
                                                      })
                                                      : row.label === 'Net FX Position'
                                                        ? intl.formatMessage({
                                                          id: 'tooltip_net_fx_position',
                                                        })
                                                        : row.label === 'Interest Incomes'
                                                          ? intl.formatMessage({
                                                            id: 'tooltip_interest_incomes',
                                                          })
                                                          : row.label === 'Interest Expenses'
                                                            ? intl.formatMessage({
                                                              id: 'tooltip_interest_expenses',
                                                            })
                                                            : row.label === 'Equity Gains and Losses'
                                                              ? intl.formatMessage({
                                                                id: 'tooltip_equity_gains_losses',
                                                              })
                                                              : row.label === 'Provisions'
                                                                ? intl.formatMessage({
                                                                  id: 'tooltip_provisions',
                                                                })
                                                                : row.label === 'Income Tax'
                                                                  ? intl.formatMessage({
                                                                    id: 'tooltip_income_tax',
                                                                  })
                                                                  : row.label === 'Net Income'
                                                                    ? intl.formatMessage({
                                                                      id: 'tooltip_net_income',
                                                                    })
                                                                    : row.label === 'Regulatory Capital'
                                                                      ? intl.formatMessage({
                                                                        id: 'tooltip_capital',
                                                                      })
                                                                      : row.label === 'Risk Weighted Assets'
                                                                        ? intl.formatMessage({
                                                                          id: 'tooltip_rwas',
                                                                        })
                                                                        : row.label === 'Gross Loans'
                                                                          ? intl.formatMessage({
                                                                            id: 'tooltip_loans',
                                                                          })
                                                                          : row.label === 'Inventories'
                                                                            ? intl.formatMessage({
                                                                              id: 'tooltip_inventories',
                                                                            })
                                                                            : row.label === 'Account Receivables'
                                                                              ? intl.formatMessage({
                                                                                id: 'tooltip_account_receivables',
                                                                              })
                                                                              : row.label === 'Tangible And Intangible Assets'
                                                                                ? intl.formatMessage({
                                                                                  id: 'tooltip_tangible_and_intangible_assets',
                                                                                })
                                                                                : row.label === 'Account Payables'
                                                                                  ? intl.formatMessage({
                                                                                    id: 'tooltip_account_payables',
                                                                                  })
                                                                                  : row.label === 'Debt'
                                                                                    ? intl.formatMessage({
                                                                                      id: 'Debt',
                                                                                    })
                                                                                    : row.label === 'Balancing'
                                                                                      ? intl.formatMessage({
                                                                                        id: 'tooltip_balancing',
                                                                                      })
                                                                                      : ''
                      }
                    >
                      <ReactTooltip />

                      {intl.formatMessage({ id: row.key })}
                    </td>
                    {financial_statements.map((yearData, columnIndex) => (
                      <td key={columnIndex} className={`${TableStyle.TdStyle}  `}>
                        <input
                          type="text"
                          onFocus={(e) => {
                            if (e.target.value === '0') {
                              e.target.value = '';
                            }
                          }}
                          onBlur={(e) => {
                            if (e.target.value === '') {
                              handleInputChange(0, row.key, columnIndex);
                            }
                          }}
                          onChange={(e) => {
                            const newValue = e.target.value.replace(/,/g, '');
                            if (!isNaN(newValue)) {
                              handleInputChange(newValue, row.key, columnIndex);
                            }
                          }}
                          value={yearData.values[rowIndex].value
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          placeholder="0"
                          className={`w-full border-none text-end text-xs focus:outline-none focus:ring-2  m-0 py-1 bg-white text-gray-900 px-2 `}
                        />
                      </td>
                    ))}





                  </tr>
                ))}
            </tbody>

            <thead className="text-center  bg-primary rounded-md">
              <tr>
                <th className={`${TableStyle.TdLabelStyle} !bg-gray-300 !font-semibold `}>
                  {intl.formatMessage({ id: 'FINANCIAL_STATEMENT.INCOME_STATEMETNS_TABLE.TITLE' })}
                </th>
                {renderHeaders(financial_statements, false)}
                <></> {renderHeaders(flowOrstock ? analysisResult?.financial_statements : analysisResult?.financial_statements_stock, true)}
              </tr>
            </thead>
            <tbody>
              {financial_statements[0]?.values
                .slice(firmBackground.industry === 'Finance & Insurance' ? 14 : 12)
                .map((row, rowIndex) => (
                  <tr key={rowIndex} className={'bg-gray-200'}>
                    <td
                      className={`${TableStyle.TdLabelStyle} text-gray-800 font-normal !min-w-[250px] sm:w-[120px]  !border-l border-b border-[#d2d5d9]`}
                      title={intl.formatMessage({ id: `tooltip_${row.key}` })}
                      data-tip={intl.formatMessage({ id: `tooltip_${row.key}` })}
                    >
                      <ReactTooltip />
                      {intl.formatMessage({ id: row.key })}
                    </td>
                    {financial_statements.map((yearData, columnIndex) => (
                      <td key={columnIndex} className={`${TableStyle.TdStyle} !p-0 !m-0 bg-red-400`}>
                        <input
                          type="text"
                          onFocus={(e) => {
                            if (e.target.value === '0') e.target.value = '';
                          }}
                          onBlur={(e) => {
                            if (e.target.value === '') handleInputChange(0, row.key, columnIndex);
                          }}
                          onChange={(e) => {
                            const newValue = e.target.value.replace(/,/g, '');
                            if (!isNaN(newValue)) {
                              handleInputChange(newValue, row.key, columnIndex);
                            }
                          }}
                          value={yearData.values[rowIndex + (firmBackground.industry === 'Finance & Insurance' ? 14 : 12)]
                            .value.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          placeholder="0"
                          className={`w-full border-none text-end text-xs focus:outline-none focus:ring-2  m-0 py-1 bg-white text-gray-900 px-2 `}
                        />
                      </td>
                    ))}
                    

                    {analysisResult?.financial_statements &&
                      analysisResult?.financial_statements.map((yearData, columnIndex) => (
                        <td key={columnIndex} className={`${TableStyle.TdStyle} text-end w-[150px] sm:w-[100px] `}>
                         <MyLoader />
                        </td>
                      ))}
                  </tr>
                  
                ))}
           
            </tbody>

          </table>
      }

    </div>
  );

  const customTable = custom_financial_statements[0]?.values && (
    <div className="overflow-x-auto  p-2 bg-white rounded-lg shadow-lg">

  {
        rating_valuation ?

          <table className="!border border-[#d2d5d9]  table-auto  ">
               <thead className="sticky top-0 text-end  z-9">
              <tr >
                <th className={`${TableStyle.ThStyleHeaderText}  text-center text-bold bg-white `}>
                  <span className="flex">
                    {intl.formatMessage({ id: 'FINANCIAL_CHART.CURRENCY' })}{firmBackground.currency_sym}
                    <span className="flex ">
                      {intl.formatMessage({ id: 'FINANCIAL_CHART.DECIMAL' })}{firmBackground.decimal}
                    </span>
                  </span>
                </th>
                <th colSpan={2} className={`${TableStyle.ThStyleHeaderText} text-dark text-center bg-white text-xs sm:text-sm`}>
               
                </th>

                {isLoading ? (
                  <>

                    <th colSpan={analysisResult?.financial_statements?.length || 1} className={`${TableStyle.ThStyleHeader}  text-black text-center !bg-gray-200 text-xs sm:text-sm`}>
                      Projection
                    </th>
                  </>
                ) : (
                  <th colSpan={analysisResult?.financial_statements?.length || 1} className={`${TableStyle.ThStyleHeader}  text-black text-center !bg-gray-200 text-xs sm:text-sm`}>
                    Projection
                  </th>
                )}
              </tr>
            </thead>
            <thead className="sticky top-[44px] text-center  rounded-md bg-gray-100 z-5">
              <tr>
                <th className={`${TableStyle.TdLabelStyle} !bg-gray-300 !font-semibold`}>
                  {intl.formatMessage({ id: 'FINANCIAL_STATEMENT.BALANCE_SHEET_TABLE.TITLE' })}
                </th>
                {renderHeaders(flowOrstock ? analysisResult?.financial_statements : analysisResult?.financial_statements_stock, true)}
              </tr>
            </thead>
          {/* <thead className="sticky top-[20px] text-end  z-9">
          <tr >
                           <th className={`${TableStyle.ThStyleHeaderText}  text-center text-bold bg-white `}>

              {intl.formatMessage({
                id: 'FINANCIAL_STATEMENT.BALANCE_SHEET_TABLE.TITLE',
              })}
            </th>
            {custom_financial_statements?.map((yearData, columnIndex) => {
              yearData.year.split('-')
              const date = new Date(
                Number(yearData.year.split('-')[0]),
                Number(yearData.year.split('-')[1]),
                0
              )
              return (
                <th scope="col" key={columnIndex} className="
                pl-1 pr-1 text-end text-gray-700 font-medium border-b border-gray-200
                min-w-[60px] max-w-[140px]
                xs:min-w-[60px] xs:max-w-[80px]
                sm:min-w-[80px] sm:max-w-[100px]
                md:min-w-[100px] md:max-w-[120px]
                lg:min-w-[100px] lg:max-w-[100px]
                xl:min-w-[120px] xl:max-w-[120px]
                2xl:min-w-[100px] 2xl:max-w-[100px]
                xs:text-[10px] sm:text-xs md:text-sm lg:text-base
              ">
                  {date.toLocaleString('en-US', { month: 'short' }) +
                    ' ' +
                    date.toLocaleString('en-US', { year: 'numeric' })}
                </th>
              )
            })}
            <th className="font-medium border-b border-gray-200 lg:w-[60%]"></th>

          </tr>
        </thead> */}
        <tbody>
          {custom_financial_statements[0]?.values
            .slice(0, 11)
            .map((row, rowIndex) => (
              <tr key={rowIndex}                     className={'bg-gray-200'}
>
                <td
                                       className={`${TableStyle.TdLabelStyle} text-gray-800 font-normal !border-l border-b border-[#d2d5d9] truncate sm:max-w-none`}

                  title={intl.formatMessage({ id: `tooltip_${row.key}` })}

                >
                  <span className="block truncate">
                    {intl.formatMessage({ id: row.key })}
                  </span>
                </td>
                {analysisResult?.custom_financial_statements?.map(
                  (yearData, columnIndex) => (
                    <td key={columnIndex} cclassName={`${TableStyle.TdStyle} text-end`}>
                      <input
                        type="text"
                        defaultValue={0}
                        value={yearData.values[rowIndex].value}
                        disabled
                        className={` border-none text-end text-xs focus:outline-none focus:ring-2  !bg-gray-200 m-0 py-1  text-gray-900 px-2 `}

                      />
                    </td>
                  )
                )}
              </tr>
            ))}
        </tbody>
      </table>
      :
      <table className="table-auto w-full border border-gray-200">
      <thead className="sticky top-[20px] text-end  z-9">
      <tr>
            <th className="
              pl-1 text-left text-blue-900 font-bold border-b border-gray-200
              min-w-[60px] max-w-[140px]
              xs:min-w-[60px] xs:max-w-[80px]
              sm:min-w-[80px] sm:max-w-[100px]
              md:min-w-[100px] md:max-w-[120px]
              lg:min-w-[100px] lg:max-w-[100px]
              xl:min-w-[120px] xl:max-w-[120px]
              2xl:min-w-[220px] 2xl:max-w-[220px]
              xs:text-[10px] sm:text-xs md:text-sm lg:text-base
                   sticky left-0 bg-gray-300 z-30

            ">
              <span style={{ color: '#012169' }}>
                {intl.formatMessage({
                  id: 'FINANCIAL_STATEMENT.INCOME_STATEMETNS_TABLE.TITLE',
                })}
              </span>
            </th>
            {custom_financial_statements?.map((yearData, columnIndex) => {
              yearData.year.split('-')
              const date = new Date(
                Number(yearData.year.split('-')[0]),
                Number(yearData.year.split('-')[1]),
                0
              )
              return (
                <th scope="col" key={columnIndex} className="
                pl-1 pr-1 text-end text-gray-700 font-medium border-b border-gray-200
                min-w-[60px] max-w-[140px]
                xs:min-w-[60px] xs:max-w-[80px]
                sm:min-w-[80px] sm:max-w-[100px]
                md:min-w-[100px] md:max-w-[120px]
                lg:min-w-[100px] lg:max-w-[100px]
                xl:min-w-[120px] xl:max-w-[120px]
                2xl:min-w-[100px] 2xl:max-w-[100px]
                xs:text-[10px] sm:text-xs md:text-sm lg:text-base
              ">
                  {date.toLocaleString('en-US', { month: 'short' }) +
                    ' ' +
                    date.toLocaleString('en-US', { year: 'numeric' })}
                </th>
              )
            })}
            <th className="font-medium border-b border-gray-200 lg:w-[60%]"></th>

          </tr>
        </thead>
        <tbody>
          {custom_financial_statements[0]?.values
            .slice(12, custom_financial_statements[0].values.length)
            .map((row, rowIndex) => (
              <tr key={rowIndex} className="hover:bg-gray-50">
                <td                       className={`${TableStyle.TdLabelStyle} text-gray-800 font-normal !border-l border-b border-[#d2d5d9]`}
 title={intl.formatMessage({ id: `tooltip_${row.key}` })}
                >
                  <span className="block truncate">
                    {intl.formatMessage({ id: row.key })}
                  </span>
                </td>
                {analysisResult?.custom_financial_statements?.map(
                  (yearData, columnIndex) => (
                    <>
                      <td key={columnIndex} className={`${TableStyle.TdStyle} text-end w-[150px] sm:w-[100px] `}>
                        <input
                          type="text"
                          defaultValue={0}
                          value={yearData?.values[rowIndex + 12].value}
                          disabled
                          className=" border-none text-end text-xs focus:outline-none focus:ring-2 !bg-gray-200 m-0 py-1 text-gray-900 px-2"

                        />
                      </td>
                    </>
                  )
                )}
              </tr>
            ))}
        </tbody>
      </table>
}
      </div>
  )


  return (
    <div>
      <div className={styles.filters}>
        <div className={styles.filters_charts}>
          <button onClick={handleSelectAll}>Tümünü Seç
          </button>
          <button onClick={handleDeselectAll}>Tümünün Seçimini Kaldır</button>
        </div>

        <div className={styles.checkbox_group}>
          {availableMetrics.map((metric) => (
            <div key={metric}>
              <input
                type="checkbox"
                id={metric}
                checked={selectedMetrics.includes(metric)}
                onChange={() => handleCheckboxChange(metric)}
              />
              <label htmlFor={metric}>{metric}</label>
            </div>
          ))}
        </div>
      </div>

      <div className="row justify-content-center mt-5">
        {selectedMetrics.includes("Growth Rate Of Revenues") &&
          chartIndicatorsDatas["Growth Rate Of Revenues"] && (
            <div className="col-12 col-md-6 mb-4">
              <Bar
                options={createChartOptions("Growth Rate Of Revenues")}
                data={chartIndicatorsDatas["Growth Rate Of Revenues"]}
              />
            </div>
          )}
        {selectedMetrics.includes("Growth Rate Of Revenues") && (
          <hr style={{ borderTop: "2px solid black", width: "100%" }} />
        )}

        {selectedMetrics.includes("Gross Margin") &&
          chartIndicatorsDatas["Gross Margin"] && (
            <div className="col-12 col-md-6 mb-4">
              <Bar
                options={createChartOptions("Gross Margin")}
                data={chartIndicatorsDatas["Gross Margin"]}
              />
            </div>
          )}
        {selectedMetrics.includes("Gross Margin") && (
          <hr style={{ borderTop: "2px solid black", width: "100%" }} />
        )}

        {selectedMetrics.includes("EBITDA Margin") &&
          chartIndicatorsDatas["EBITDA Margin"] && (
            <div className="col-12 col-md-6 mb-4">
              <Bar
                options={createChartOptions("EBITDA Margin")}
                data={chartIndicatorsDatas["EBITDA Margin"]}
              />
            </div>
          )}
        {selectedMetrics.includes("EBITDA Margin") && (
          <hr style={{ borderTop: "2px solid black", width: "100%" }} />
        )}

        {selectedMetrics.includes("Profit Margin") &&
          chartIndicatorsDatas["Profit Margin"] && (
            <div className="col-12 col-md-6 mb-4">
              <Bar
                options={createChartOptions("Profit Margin")}
                data={chartIndicatorsDatas["Profit Margin"]}
              />
            </div>
          )}
        {selectedMetrics.includes("Profit Margin") && (
          <hr style={{ borderTop: "2px solid black", width: "100%" }} />
        )}

        {selectedMetrics.includes("ICR") &&
          chartIndicatorsDatas["ICR"] && (
            <div className="col-12 col-md-6 mb-4">
              <Bar options={createChartOptions("ICR")} data={chartIndicatorsDatas["ICR"]} />
            </div>
          )}
        {selectedMetrics.includes("ICR") && (
          <hr style={{ borderTop: "2px solid black", width: "100%" }} />
        )}

        {selectedMetrics.includes("DSCR") &&
          chartIndicatorsDatas["DSCR"] && (
            <div className="col-12 col-md-6 mb-4">
              <Bar options={createChartOptions("DSCR")} data={chartIndicatorsDatas["DSCR"]} />
            </div>
          )}
        {selectedMetrics.includes("DSCR") && (
          <hr style={{ borderTop: "2px solid black", width: "100%" }} />
        )}

        {selectedMetrics.includes("ROA") &&
          chartIndicatorsDatas["ROA"] && (
            <div className="col-12 col-md-6 mb-4">
              <Bar options={createChartOptions("ROA")} data={chartIndicatorsDatas["ROA"]} />
            </div>
          )}
        {selectedMetrics.includes("ROA") && (
          <hr style={{ borderTop: "2px solid black", width: "100%" }} />
        )}

        {selectedMetrics.includes("ROE") &&
          chartIndicatorsDatas["ROE"] && (
            <div className="col-12 col-md-6 mb-4">
              <Bar options={createChartOptions("ROE")} data={chartIndicatorsDatas["ROE"]} />
            </div>
          )}
        {selectedMetrics.includes("ROE") && (
          <hr style={{ borderTop: "2px solid black", width: "100%" }} />
        )}

        {selectedMetrics.includes("Leverage Ratio") &&
          chartIndicatorsDatas["Leverage Ratio"] && (
            <div className="col-12 col-md-6 mb-4">
              <Bar
                options={createChartOptions("Leverage Ratio")}
                data={chartIndicatorsDatas["Leverage Ratio"]}
              />
            </div>
          )}
        {selectedMetrics.includes("Leverage Ratio") && (
          <hr style={{ borderTop: "2px solid black", width: "80%" }} />
        )}
      </div>


      <div className="analysis-table-header-container-sub-text">
        <div
          className={`${styles.report_container_main_header_common
            } ${'col-12 text-left mt-5 mb-3'}`}
          style={{ width: '98%' }}
        >
          III.{' '}
          {intl.formatMessage({
            id: 'FINANCIAL_CHART.TITLE',
          })}
        </div>
      </div>
      {table}
      <br />
      <div className="analysis-table-header-container-sub">
        <div
          className="analysis-table-header-container-sub-text"
          style={{ width: '100%' }}
        >
          {custom_financial_statements[0]?.values && (
            <div
              className={`${styles.report_container_main_header_common
                } ${'col-12 text-left mt-5 mb-3'}`}
              style={{ width: '98%' }}
            >
              IV.{' '}
              {intl.formatMessage({
                id: 'FINANCIAL_CHART.CUSTOM_TABLE_TITLE',
              })}
            </div>
          )}
        </div>
      </div>
      {customTable}
    </div>
  );
};

export default RealAnalysisReport;