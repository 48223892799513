import { Outlet, useLocation } from 'react-router-dom';
import { Header } from 'components/LayoutMain';

const AppLayout = () => {
  const location = useLocation();

  const shouldShowHeader = location.pathname === '/' || location.pathname === '/homepage' || location.pathname === '/register' || location.pathname === '/login'
  || location.pathname === '/myfirm' || location.pathname === '/equity'|| location.pathname === '/get-equity'|| location.pathname === '/loan'|| location.pathname === '/get-loan'

  return (
    <>
      {shouldShowHeader && <Header />} 
      <main>
        <Outlet />
      </main>
    </>
  );
};

export default AppLayout;
