import { useEffect, useState } from 'react'
import {  useSelector } from 'react-redux'
import * as analysisDataActions from 'redux/actions/analysisDataActions'
import useCalculateAnalysis from 'hooks/useCalculateAnalysis'
import useHandleInputChange from 'hooks/useHandleInputChange'
import Table from 'components/Table/Table'

const MaturityShortTerm = ({ isLoading, setIsLoading }) => {
  const { calculateAnalysis } = useCalculateAnalysis(setIsLoading)
  const [lastKeyPress, setLastKeyPress] = useState(null)

  const {
    firmBackground,
    maturity_short_term,
  } = useSelector((state) => state.analysisDataReducer)

  const { handleInputChange } = useHandleInputChange(analysisDataActions.maturityShortTerm, maturity_short_term, calculateAnalysis)
  
  useEffect(() => {
    if (lastKeyPress) {
      const timeoutId = setTimeout(() => calculateAnalysis(), 1500)
      return () => clearTimeout(timeoutId)
    }
  }, [maturity_short_term, lastKeyPress])

  useEffect(() => {
    setIsLoading(false)
  }, [])

  
  return (
    <Table
    data={maturity_short_term}
    isLoading={isLoading}
    handleInputChange={handleInputChange}
    columnTitle="Maturity Short Term"
    currencySym={firmBackground.currency_sym}
    decimal={firmBackground.decimal}
  />
  )
}

export default MaturityShortTerm
