import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import {
  analysisCreate,
  createFirm,
  customScenarioCreate,
  customScenarioDataCreate,
  loanRequestCreate,
  equityRequestCreate,
  login,
  realAnalysisCreate,
  realBusinessCreate,
  realFxCreate,
  realMaturityCreate,
  registration,
  financialBusinessCreate,
  financialMaturityCreate,
  financialAnalysisCreate,
} from 'redux/ApiCalls'
import storeConstants from 'redux/constants/actionTypes'
import { toast } from 'react-toastify'
import { createTheme, ThemeProvider } from '@mui/material/styles'

const theme = createTheme()

const Register = (props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    firmBackground,
    financial_statements,
    fx_position,
    maturity_short_term,
    business_plan,
    analysisResult,
    saved_analysis,
  } = useSelector((state) => state.analysisDataReducer)

  const { addLoan, saveLoan } = useSelector((state) => state.loanRequest)

  const { addEquity, saveEquity } = useSelector((state) => state.equityRequest)

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    company_name: Yup.string().required('Company Name is required'),
    company_web_site: Yup.string().required('Company Web Site is required'),
    city: Yup.string().required('City is required'),
    your_role_at_the_company: Yup.string().required(
      'Your Role At The Company is required'
    ),
    business_description: Yup.string().required(
      'Business Description is required'
    ),
    email: Yup.string().required('Email is required').email('Email is invalid'),
    password: Yup.string()
      .required('Password is required')
      .min(6, 'Password must be at least 6 characters')
      .max(40, 'Password must not exceed 40 characters'),
    password2: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),
    acceptTerms: Yup.bool().oneOf([true], 'Accept Terms is required'),
  })


  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  let custom = analysisResult?.scenarios

  const onSubmit = (data) => {
    registration({ ...data })
      .then(() => {
        login({ email: data.email, password: data.password })
          .then((res) => {
            if (res.data.access) {
              dispatch({
                type: storeConstants.LOGIN_SUCCESS,
                payload: res.data.access,
              })

              toast.success(
                intl.formatMessage({ id: 'TOAST_MESSAGES.REGISTER.SUCCESS' })
              )
              navigate('/myfirm')
              let token = res.data.access
              console.log(saveLoan)
              if (saveLoan) {
                loanRequestCreate(token, addLoan)
                  .then((res) => {
                    toast.success('Loan request created successfully.')
                  })
                  .catch((err) => {
                    toast.error('Loan request creation failed.')
                  })
              }
              if (saveEquity) {
                equityRequestCreate(token, addEquity)
                  .then((res) => {
                    toast.success('Equity request created successfully.')
                  })
                  .catch((err) => {
                    toast.error('Equity request creation failed.')
                  })
              }
              if (saved_analysis) {
                createFirm(res.data.access, {
                  ...firmBackground,
                  foreCastStartDate: firmBackground.foreCastStartDate / 1000,
                  decemberOfLastYear: firmBackground.decemberOfLastYear / 1000,
                  foreCastEndDate: firmBackground.foreCastEndDate / 1000,
                  fiscalYear: firmBackground.fiscalYear / 1000,
                  start_of_operations_year:
                    firmBackground.start_of_operations_year / 1000,
                })
                  .then(({ data }) => {
                    toast.success('Firm Created')
                    let firmSlug = data.slug
                    let token = res.data.access

                    analysisCreate(token, firmSlug, {
                      name: 'SaveAnalysis',
                      is_default: 1,
                    })
                      .then(({ data }) => {
                        toast.success('Analysis Created')
                        let analysisId = data.id
                        customScenarioCreate(token, custom, analysisId).then(
                          ({ data }) => {
                            let customId = data.id
                            let analyzeId = data.analyze
                            custom.map((yearData) => {
                              let newCustom = {}
                              yearData.values.map((data, index) => {
                                newCustom[data.key] = data.value
                              })
                              newCustom.year_for_data = new Date(
                                yearData.year
                              ).toISOString()
                              newCustom.projection_frequency =
                                firmBackground.projection_frequency
                              customScenarioDataCreate(
                                token,
                                newCustom,
                                analyzeId,
                                customId
                              ).catch((err) => {
                                console.log(err)
                              })
                            })
                          }
                        )
                        if (firmBackground.industry === 'Finance & Insurance') {
                          financialAnalysisCreate(
                            token,
                            financial_statements,
                            analysisId
                          )
                          let newFx = [...fx_position]
                          newFx.map((yearData) => {
                            yearData.values.map((data, index) => {
                              if (typeof data.value === 'string') {
                                yearData.values[index].value = Number(data.value.replace(/,/g, ''))
                              }
                            })
                          })
                    
                          financialFxCreate(token, newFx, analysisId)
                          financialMaturityCreate(
                            token,
                            maturity_short_term,
                            analysisId
                          )
                          financialBusinessCreate(
                            token,
                            business_plan,
                            analysisId
                          )
                        } else {
                          realAnalysisCreate(
                            token,
                            financial_statements,
                            analysisId
                          )
                          realFxCreate(token, fx_position, analysisId)
                          realMaturityCreate(
                            token,
                            maturity_short_term,
                            analysisId
                          )
                          realBusinessCreate(token, business_plan, analysisId)
                        }
                      })
                      .catch((err) => {
                        console.log(err)
                      })
                  })
                  .catch((err) => {
                    console.log(err)
                  })
              } else {
                navigate('/myfirm')
              }
            } else {
              console.log(err)
            }
          })
          .catch((err) => {
            toast.error(
              intl.formatMessage({
                id: 'TOAST_MESSAGES.REGISTER.ERROR',
              })
            )
          })
      })
      .catch((err) => {
        toast.error(
          intl.formatMessage({
            id: 'TOAST_MESSAGES.REGISTER.ERROR',
          })
        )
      })
  }

  const onClick = () => {
    navigate('/login')
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 py-6 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-md lg:max-w-3xl space-y-8 bg-white p-8 rounded-lg shadow-lg">
        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col space-y-4">
            <div className="flex flex-col sm:flex-row sm:space-x-4">
              <div className="sm:w-1/2">
                <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
                  {intl.formatMessage({ id: 'REGISTER.FIST_NAME' })}
                </label>
                <input
                  id="first_name"
                  name="first_name"
                  type="text"
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                  {...register('first_name')}
                />
              </div>
              <div className="sm:w-1/2">
                <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
                  {intl.formatMessage({ id: 'REGISTER.LAST_NAME' })}
                </label>
                <input
                  id="last_name"
                  name="last_name"
                  type="text"
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                  {...register('last_name')}
                />
              </div>
            </div>

            <div className="flex flex-col sm:flex-row sm:space-x-4">
              <div className="sm:w-1/2">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  {intl.formatMessage({ id: 'REGISTER.EMAIL' })}
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                  {...register('email')}
                />
              </div>

              <div className="sm:w-1/2">
                <label htmlFor="company_web_site" className="block text-sm font-medium text-gray-700">
                  {intl.formatMessage({ id: 'REGISTER.COMPANY_WEB_SITE' })}
                </label>
                <input
                  id="company_web_site"
                  name="company_web_site"
                  type="text"
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                  {...register('company_web_site')}
                />
              </div>
            </div>

            <div className="flex flex-col sm:flex-row sm:space-x-4">
              <div className="sm:w-1/2">
                <label htmlFor="company_name" className="block text-sm font-medium text-gray-700">
                  {intl.formatMessage({ id: 'REGISTER.COMPANY_NAME' })}
                </label>
                <input
                  id="company_name"
                  name="company_name"
                  type="text"
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                  {...register('company_name')}
                />
              </div>

              <div className="sm:w-1/2">
                <label htmlFor="your_role_at_the_company" className="block text-sm font-medium text-gray-700">
                  {intl.formatMessage({ id: 'REGISTER.ROLE' })}
                </label>
                <input
                  id="your_role_at_the_company"
                  name="your_role_at_the_company"
                  type="text"
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                  {...register('your_role_at_the_company')}
                />
              </div>
            </div>

            <div className="flex flex-col sm:flex-row sm:space-x-4">
              <div className="sm:w-1/2">
                <label htmlFor="business_description" className="block text-sm font-medium text-gray-700">
                  {intl.formatMessage({ id: 'REGISTER.BUSINESS_DESCRIPTION' })}
                </label>
                <input
                  id="business_description"
                  name="business_description"
                  type="text"
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                  {...register('business_description')}
                />
              </div>

              <div className="sm:w-1/2">
                <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                  {intl.formatMessage({ id: 'REGISTER.CITY' })}
                </label>
                <input
                  id="city"
                  name="city"
                  type="text"
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                  {...register('city')}
                />
              </div>
            </div>

            <div className="flex flex-col sm:flex-row sm:space-x-4">
              <div className="sm:w-1/2">
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  {intl.formatMessage({ id: 'REGISTER.PASSWORD' })}
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                  {...register('password')}
                />
              </div>

              <div className="sm:w-1/2">
                <label htmlFor="password2" className="block text-sm font-medium text-gray-700">
                  {intl.formatMessage({ id: 'REGISTER.CONFIRM_PASSWORD' })}
                </label>
                <input
                  id="password2"
                  name="password2"
                  type="password"
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                  {...register('password2')}
                />
              </div>
            </div>

            <div className="flex items-center">
              <input
                id="remember"
                name="remember"
                type="checkbox"
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              />
              <label htmlFor="remember" className="ml-2 block text-sm text-gray-900">
                Remember me
              </label>
            </div>

            <div className="flex flex-col space-y-4">
              <div className="flex flex-col sm:flex-row sm:space-x-4">
                <div className="sm:w-full">
                  <div
                    id="terms_of_use"
                    className="mt-1 block w-full h-40 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm overflow-y-auto"
                  >
                    <p className="text-sm text-gray-700">
                      Website Terms of UseLast updated January 2025ACCEPTANCE OF TERMSThese are the Website Terms of Use (the “Website Terms”) governing the use of www.financialpredictionai.com website (the “Website”). Your access to and use of the Website is conditioned on your acceptance of and compliance with these Website Terms. By accessing or using the Website you agree to be bound by these Terms of Use. If you disagree with any part of these Website Terms, then you shall cease your use of the Website immediately. Your access to and use of the Website is also conditioned on your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes our policies and procedures on the collection, use and disclosure of your personal information. Please read our Privacy Policy carefully before using the Website.If you are registered to our data automation, financial planning and analysis solution service and have an active account, please refer to our Terms of Service.USER LIMITATIONS AND ACKNOWLEDGEMENTExcept to the extent expressly permitted in this Website Terms or required by law on a non-excludable basis, the use of the Website shall be subject to the following prohibitions:   i.           You must not copy, or make any alteration to, or access the software code of the Website or damage, interfere with, or disrupt the integrity, performance or use of the Website;  ii.           You must not use the Website in any way that is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity;
                      iii.           You must not create any derivative works of Company’s Property (as such term defined below) or build a similar or competitive product, service or website.OWNERSHIP OF COMPANY PROPERTYAll rights, of any kind whatsoever, including, but not limited to, intellectual property rights, copyrights, trademarks, brands, patents, trade secrets, samples, know-how and/or any other material included and/or associated with the Company’s Website, whether said rights are registered or unregistered, are exclusively owned by the Company (collectively, the “Company’s Property”). You hereby acknowledge that you shall have no rights of any kind in the Company’s Website. It is hereby clarified that these Website Terms does not transfer any rights in the Company’s Property to you.LINKS TO OTHER WEBSITESOur Website may contain links to third-party websites that are not owned or controlled by the Company.The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party websites. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or Websites available on or through any such websites.We strongly advise you to read the terms of use and privacy policies of any third-party website you visit.TERMINATIONWe may terminate or suspend your access immediately, without prior notice or liability, for any reason whatsoever, including without limitation
                      if you breach any of these Website Terms. Upon termination, your right to use the Website will cease immediately.LIMITATION OF LIABILITYTo the maximum extent permitted by applicable law, in no event shall the Company, its officers, directors, employees, agents or suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Website, third-party software and/or third-party hardware used with the Website, or otherwise in connection with any provision of this Website Terms), even if the Company, its officers, directors, employees, agents or suppliers has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.“AS IS” AND “AS AVAILABLE” DISCLAIMNERThe Website is provided to you “AS IS” and “AS AVAILABLE” and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Website, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Website will meet Your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or websites, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.
                      Without limiting the foregoing, the Company makes no representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Website, or the information, content, and materials or products included thereon; (ii) that the Website will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Website; or (iv) that the Website, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.GOVERNING LAWYou agree that the laws of the Turkiye, excluding its conflicts-of-law rules, shall govern these Website Terms. You expressly agree that the exclusive jurisdiction for any claim or dispute with the Company or relating in any way to your use of our Website resides solely in the competent courts located in the Turkiye.GENERALIf any provision of these Website Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.We reserve the right, at our sole discretion, to modify or replace these Website Terms at any time.              </p>
                  </div>
                </div>
              </div>

              <div className="flex items-center mt-6 sm:mt-4">
                <input
                  id="terms"
                  name="terms"
                  type="checkbox"
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  required
                />
                <label htmlFor="terms" className="ml-2 block text-sm text-gray-900">
                  I read and understood
                </label>
              </div>
            </div>
          </div>

          <button
            type="submit"
            className="w-full flex-1 bg-blue-500 text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 flex items-center justify-center disabled:opacity-50"
            >
            {intl.formatMessage({ id: 'REGISTER.BUTTON' })}
          </button>

          <div className="flex justify-between items-center mt-4">
            <Link
              to={saved_analysis ? '/login?saveAnalysis=true' : '/login'}
              className="text-sm text-blue-600 hover:text-blue-800"
              onClick={onClick}
            >
              {intl.formatMessage({ id: 'REGISTER.ALREADY_HAVE_ACCOUNT' })}
            </Link>
          </div>
        </form>
      </div>
    </div>

  )
}
export default Register
