import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getFirmList, getLoanRequest, loanDetail, loanRequestCreate, updateLoanRequest } from 'redux/ApiCalls';
import styles from 'assets/styles/pages/loan.module.scss'
import { Checkbox } from '@mui/material';
import { currencySym } from 'services';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify'
import storeConstants from 'redux/constants/actionTypes';
import ReactTooltip from 'react-tooltip';


const LoanRequest = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { firms } = useSelector((state) => state.getFirmList)
  const [selectFirm, setSelectFirm] = useState('')

  const params = useParams();
  const token = useSelector(state => state.auth.token)
  const [form, setForm] = useState({ loan_amount: "", loan_type: "", special_notes: "", is_private: false })
  const [currencySelect, setCurrencySelect] = useState("ALL (Lek)")
  const [getDetailLoanId, setGetDetailLoanId] = useState([])
  const loanId = params?.loanId

  const [loanRequest, setLoanRequest] = useState([])
  const [loanObjectivesSelected, setLoanObjectivesSelected] = useState([]);
  const [typeOfLoanSelected, setTypeOfLoanSelected] = useState([]);
  const [loanMaturitySelected, setLoanMaturitySelected] = useState([]);
  const [gracePeriodSelected, setGracePeriodSelected] = useState([]);
  const [interestReteSelected, setInterestReteSelected] = useState([]);
  const [interestPeriodSelected, setInterestPeriodSelected] = useState([]);
  const [collateralSelected, setCollateralSelected] = useState([]);
  const [isSubscribed, setIsSubscribed] = useState(false);


  const loan_objectives = loanRequest.loan_objectives
  const type_of_loan = loanRequest.types_of_loans
  const loan_maturity = loanRequest.loan_maturity
  const grace_period = loanRequest.grace_period
  const interest_rate_term = loanRequest.interest_rate_term
  const interest_period = loanRequest.interest_period
  const collaterals_to_be_provided = loanRequest.collaterals_to_be_provided


  useEffect(() => {
    if (token && loanId) {
      loanDetail(token, loanId)
        .then(res => {
          setGetDetailLoanId(res.data)
        })
        .catch(err => console.log(err))
    }

  }, [])

  useEffect(() => {
    if (loanId && getDetailLoanId.loan_objectives?.length > 0) {
      setLoanObjectivesSelected(getDetailLoanId.loan_objectives)
      setTypeOfLoanSelected(getDetailLoanId.type_of_loan)
      setLoanMaturitySelected(getDetailLoanId.loan_maturity)
      setGracePeriodSelected(getDetailLoanId.grace_period)
      setInterestReteSelected(getDetailLoanId.interest_rate_term)
      setInterestPeriodSelected(getDetailLoanId.interest_period)
      setCollateralSelected(getDetailLoanId.collateral_type)
      setForm({ loan_amount: getDetailLoanId.loan_amount, loan_type: getDetailLoanId.loan_type, special_notes: getDetailLoanId.special_notes, is_private: getDetailLoanId.is_private })
      setCurrencySelect(getDetailLoanId.currency_unit)
      setSelectFirm(getDetailLoanId.firm)
    }
  }, [loanId, getDetailLoanId.loan_objectives])


  useEffect(() => {
    getLoanRequest()
      .then((res) => {
        setLoanRequest(res.data)
      })
      .catch(err => {
        console.log(err)
      })
  }, []);


  const isAllLoanObjectivesSelected = loan_objectives?.length > 0 && loanObjectivesSelected.length === loan_objectives?.length;
  const isAllTypeOfLoanSelected = type_of_loan?.length > 0 && typeOfLoanSelected.length === type_of_loan?.length;
  const isAllLoanMaturitySelected = loan_maturity?.length > 0 && loanMaturitySelected.length === loan_maturity?.length;
  const isAllGracePeriodSelected = grace_period?.length > 0 && gracePeriodSelected.length === grace_period?.length;
  const isAllInterestReteSelected = interest_rate_term?.length > 0 && interestReteSelected.length === interest_rate_term?.length;
  const isAllInterestPeriodSelected = interest_period?.length > 0 && interestPeriodSelected.length === interest_period?.length;
  const isAllCollateralSelected = collaterals_to_be_provided?.length > 0 && collateralSelected.length === collaterals_to_be_provided?.length;

  const handleChangeLoanObjectives = (event) => {
    const value = event.target.value;
    if (value === "all") {
      setLoanObjectivesSelected(loanObjectivesSelected.length === loan_objectives?.length ? [] : loan_objectives);
      return;
    }
    const list = [...loanObjectivesSelected];
    const index = list.indexOf(value);
    index === -1 ? list.push(value) : list.splice(index, 1);
    setLoanObjectivesSelected(list);
  };

  const handleChangeTypeOfLoan = (event) => {
    const value = event.target.value;
    if (value === "all") {
      setTypeOfLoanSelected(typeOfLoanSelected.length === type_of_loan?.length ? [] : type_of_loan);
      return;
    }
    const list = [...typeOfLoanSelected];
    const index = list.indexOf(value);
    index === -1 ? list.push(value) : list.splice(index, 1);
    setTypeOfLoanSelected(list);
  };

  const handleChangeLoanMaturity = (event) => {
    const value = event.target.value;
    if (value === "all") {
      setLoanMaturitySelected(loanMaturitySelected.length === loan_maturity?.length ? [] : loan_maturity);
      return;
    }
    const list = [...loanMaturitySelected];
    const index = list.indexOf(value);
    index === -1 ? list.push(value) : list.splice(index, 1);
    setLoanMaturitySelected(list);
  };

  const handleChangeGracePeriod = (event) => {
    const value = event.target.value;
    if (value === "all") {
      setGracePeriodSelected(gracePeriodSelected.length === grace_period?.length ? [] : grace_period);
      return;
    }
    const list = [...gracePeriodSelected];
    const index = list.indexOf(value);
    index === -1 ? list.push(value) : list.splice(index, 1);
    setGracePeriodSelected(list);
  };

  const handleChangeInterestRete = (event) => {
    const value = event.target.value;
    if (value === "all") {
      setInterestReteSelected(interestReteSelected.length === interest_rate_term?.length ? [] : interest_rate_term);
      return;
    }
    const list = [...interestReteSelected];
    const index = list.indexOf(value);
    index === -1 ? list.push(value) : list.splice(index, 1);
    setInterestReteSelected(list);
  };

  const handleChangeInterestPeriod = (event) => {
    const value = event.target.value;
    if (value === "all") {
      setInterestPeriodSelected(interestPeriodSelected.length === interest_period?.length ? [] : interest_period);
      return;
    }
    const list = [...interestPeriodSelected];
    const index = list.indexOf(value);
    index === -1 ? list.push(value) : list.splice(index, 1);
    setInterestPeriodSelected(list);
  };

  const handleChangeCollateral = (event) => {
    const value = event.target.value;
    if (value === "all") {
      setCollateralSelected(collateralSelected.length === collaterals_to_be_provided?.length ? [] : collaterals_to_be_provided);
      return;
    }
    const list = [...collateralSelected];
    const index = list.indexOf(value);
    index === -1 ? list.push(value) : list.splice(index, 1);
    setCollateralSelected(list);
  };

  const onChangeInput = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const listLoanObjectives = loan_objectives?.map((option) => {
    return (
      <div key={option}>
        <Checkbox
          value={option}
          onChange={handleChangeLoanObjectives}
          checked={loanObjectivesSelected.includes(option)}
          className={styles.check_input}
        />
        <span> {intl.formatMessage({ id: option })}</span>
      </div>
    );
  });

  const listTypeOfLoan = type_of_loan?.map((option) => {
    return (
      <div key={option}>
        <Checkbox
          value={option}
          onChange={handleChangeTypeOfLoan}
          checked={typeOfLoanSelected.includes(option)}
          className={styles.check_input}
        />
        <span> {intl.formatMessage({ id: option })}</span>
      </div>
    )
  });

  const listLoanMaturity = loan_maturity?.map((option) => {
    return (
      <div key={option}>
        <Checkbox
          value={option}
          onChange={handleChangeLoanMaturity}
          checked={loanMaturitySelected.includes(option)}
          className={styles.check_input}
        />
        <span> {intl.formatMessage({ id: option })}</span>
      </div>
    )
  });

  const listGracePeriod = grace_period?.map((option) => {
    return (
      <div key={option}>
        <Checkbox
          value={option}
          onChange={handleChangeGracePeriod}
          checked={gracePeriodSelected.includes(option)}
          className={styles.check_input}
        />
        <span> {intl.formatMessage({ id: option })}</span>
      </div>
    )
  });

  const listInterestRete = interest_rate_term?.map((option) => {
    return (
      <div key={option}>
        <Checkbox
          value={option}
          onChange={handleChangeInterestRete}
          checked={interestReteSelected.includes(option)}
          className={styles.check_input}
        />
        <span> {intl.formatMessage({ id: option })}</span>
      </div>
    )
  });

  const listInterestPeriod = interest_period?.map((option) => {
    return (
      <div key={option}>
        <Checkbox
          value={option}
          onChange={handleChangeInterestPeriod}
          checked={interestPeriodSelected.includes(option)}
          className={styles.check_input}
        />
        <span> {intl.formatMessage({ id: option })}</span>
      </div>

    )
  });

  const listCollateral = collaterals_to_be_provided?.map((option) => {
    return (
      <div key={option}>
        <Checkbox
          value={option}
          onChange={handleChangeCollateral}
          checked={collateralSelected.includes(option)}
          className={styles.check_input}
        />
        <span> {intl.formatMessage({ id: option })}</span>
      </div>
    )
  });

  const findSelectFirm = firms?.find(firm => firm.id) == selectFirm
  const createAll = {
    loan_objectives: loanObjectivesSelected,
    type_of_loan: typeOfLoanSelected,
    loan_amount: form.loan_amount,
    loan_maturity: loanMaturitySelected,
    grace_period: gracePeriodSelected,
    interest_rate_term: interestReteSelected,
    interest_period: interestPeriodSelected,
    collateral_type: collateralSelected,
    currency_unit: currencySelect,
    special_notes: form.special_notes,
    is_private: form.is_private,
    firm: selectFirm ? selectFirm : "other",
  }

  const onSubmit = (e) => {
    e.preventDefault();
    token ? ((createAll.loan_objectives?.length === 0 || createAll.type_of_loan?.length === 0 || createAll.loan_amount?.length === 0 || createAll.loan_maturity?.length === 0 || createAll.grace_period?.length === 0 || createAll.interest_rate_term?.length === 0 || createAll.interest_period?.length === 0 || createAll.collateral_type?.length === 0 || createAll.currency_unit?.length === 0 || createAll.firm?.length === 0) ?
      toast.error("Fields with * are required.")
      : (
        (loanId) ? ((updateLoanRequest(token, loanId, createAll)).then(
          res => {
            navigate("/loan")
            toast.success("Loan updated successfully.")
          }
        ).catch(
          err => { toast.error("Fields with * are required.") }
        )) : (loanRequestCreate(token, createAll).then(res => {
          navigate("/loan")
          toast.success("Loan request created successfully.")
        }).catch(err => {
          toast.error("Error creating loan request.")
        }))
      ))
      : (
        (createAll.loan_objectives?.length === 0 || createAll.type_of_loan?.length === 0 || createAll.loan_amount?.length === 0 || createAll.loan_maturity?.length === 0 || createAll.grace_period?.length === 0 || createAll.interest_rate_term?.length === 0 || createAll.interest_period?.length === 0 || createAll.collateral_type?.length === 0 || createAll.currency_unit?.length === 0 || createAll.firm?.length === 0) ?
          toast.error("Fields with * are required.")
          : (
            dispatch({
              type: storeConstants.ADD_LOAN,
              payload: createAll,
            }),
            dispatch({
              type: storeConstants.SAVE_LOAN,
              payload: true
            }),
            navigate("/register")
          )
      )
  }

  const handleChange = event => {
    if (event.target.checked) {
      setForm({ ...form, is_private: true });
    } else {
      setForm({ ...form, is_private: false });
    }
    setIsSubscribed(current => !current);
  };

  useEffect(() => {
    if (getDetailLoanId && token)
      getFirmList(token)
        .then((res) => {
          dispatch({
            type: storeConstants.GET_FIRM_LIST,
            payload: res.data.results,
          })
        })
        .catch((e) => console.log(e))
  }, [])


  return (
    <div className="container mt-3 ">
      <div className="bg-gray-50 shadow-md rounded p-6">
        <form className="space-y-6 " style={{ color: "red !important" }}>
          <div>
            <label className="block  font-semibold mb-2">
              {intl.formatMessage({ id: 'LOAN_HEADER_FOR_FIRM' })} *
            </label>
            <select
              className="w-full border border-gray-300 rounded px-4 py-2 focus:ring focus:ring-blue-300"
              onChange={(e) => setSelectFirm(e.target.value)}
            >
              <option value="">
                {intl.formatMessage({ id: "LOAN_REQUEST_SELECT" })}
              </option>
              {getDetailLoanId.id && !getDetailLoanId.firm ? (
                <option value="other">{intl.formatMessage({ id: "Other" })}</option>
              ) : (
                <option value="other">{intl.formatMessage({ id: "Other" })}</option>
              )}
              {firms?.map((option, index) => (
                <option key={index} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>

          <hr />

          <div>
            <label className="block  font-semibold mb-2">
              {intl.formatMessage({ id: 'LOAN_HEADER_LOAN_OBJ' })} *
            </label>
            <div className="flex items-center space-x-4">
              <Checkbox
                required
                value="all"
                className="form-checkbox h-5 w-5 text-blue-600"
                onChange={handleChangeLoanObjectives}
                checked={isAllLoanObjectivesSelected}
              />
              <span className="text-black">{intl.formatMessage({ id: "LOAN_REQUEST_SELECT_ALL" })}</span>
            </div>
            <div className="mt-2 text-black">{listLoanObjectives}</div>
          </div>

          <hr />
          <div>
            <label className="block  font-semibold mb-2">
              {intl.formatMessage({ id: 'LOAN_HEADER_LOAN_AMOUNT' })} *
            </label>
            <input
              name="loan_amount"
              value={form.loan_amount}
              onChange={onChangeInput}
              type="number"
              className="w-full border border-gray-300 rounded px-4 py-2 focus:ring focus:ring-blue-300"
            />
          </div>

          <hr />
          <div>
            <label className="block  font-semibold mb-2">
              {intl.formatMessage({ id: 'LOAN_HEADER_TYPE_OF_LOANS' })} *
            </label>
            <div className="flex items-center space-x-4">
              <Checkbox
                required
                value="all"
                className="form-checkbox h-5 w-5 text-blue-600"
                onChange={handleChangeTypeOfLoan}
                checked={isAllTypeOfLoanSelected}
              />
              <span className="text-black">{intl.formatMessage({ id: "LOAN_REQUEST_SELECT_ALL" })}</span>
            </div>
            <div className="mt-2 text-black">{listTypeOfLoan}</div>
          </div>

          <hr />

          <div>
            <label className="block  font-semibold mb-2">
              {intl.formatMessage({ id: 'LOAN_HEADER_LOAN_MATURITY' })} *
            </label>
            <div className="flex items-center space-x-4">
              <Checkbox
                required
                value="all"
                className="form-checkbox h-5 w-5 text-blue-600"
                onChange={handleChangeLoanMaturity}
                checked={isAllLoanMaturitySelected}
              />
              <span className="text-black">{intl.formatMessage({ id: "LOAN_REQUEST_SELECT_ALL" })}</span>
            </div>
            <div className="mt-2 text-black">{listLoanMaturity}</div>
          </div>

          <hr />

          <div>
            <label className="block  font-semibold mb-2">
              {intl.formatMessage({ id: 'LOAN_HEADER_CURRENCY_UNIT' })} *
            </label>
            <select
              className="w-full border border-gray-300 rounded px-4 py-2 focus:ring focus:ring-blue-300"
              value={currencySelect}
              onChange={(e) => setCurrencySelect(e.currentTarget.value)}
            >
              {currencySym.map((item, i) => (
                <option key={i} value={item.label}>
                  {item.label}
                </option>
              ))}
            </select>
          </div>

          <hr />

          <div>
            <label className="block  font-semibold mb-2">
              {intl.formatMessage({ id: 'LOAN__HEADER_SPECIAL_NOTES' })}
            </label>
            <textarea
              rows="4"
              cols="50"
              className="w-full border border-gray-300 rounded px-4 py-2 focus:ring focus:ring-blue-300"
              value={form.special_notes}
              onChange={onChangeInput}
              name="special_notes"
              required
            />
          </div>

          <hr />

          <div className="text-center">
            <button
              type="submit"
              onClick={onSubmit}
              className="bg-blue-600 text-white font-semibold px-6 py-3 rounded hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-300"
            >
              {intl.formatMessage({ id: 'LOAN_REQUEST_SUBMIT' })}
            </button>
          </div>
        </form>
      </div>
    </div>


  )
}

export default LoanRequest