import React from 'react';
import ContentLoader from 'react-content-loader';

const MyLoader = (props) => (
  <ContentLoader
    speed={2}
    width="100%" 
    height="30px"
    viewBox="0 0 140 30"
    style={{
      maxHeight: '24px', 
      overflow: 'hidden', 
      background: 'linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%)',
      backgroundSize: '500px 100px',
      animation: 'moving-gradient 1s linear infinite forwards',
    }}
    {...props}
  >
    <rect x="0" y="0" rx="3" ry="3" width="100%" height="100%" />
  </ContentLoader>
);

export default MyLoader;
