import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { equityDetail, equityRequestCreate, getEquityRequest, getFirmList, updateEquityRequest } from 'redux/ApiCalls'
import styles from 'assets/styles/pages/loan.module.scss'
import { Checkbox } from '@mui/material';
import { toast } from 'react-toastify'
import storeConstants from 'redux/constants/actionTypes'
import ReactTooltip from 'react-tooltip'

const EquityRequest = () => {
    const intl = useIntl()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { firms } = useSelector((state) => state.getFirmList)
    const [selectFirm, setSelectFirm] = useState('')
    const params = useParams();
    const token = useSelector(state => state.auth.token)
    const [form, setForm] = useState({ amount_of_capital_to_raise: "", valuation: "", special_notes: "", is_private: false })
    const [getDetailEquityId, setGetDetailEquityId] = useState([])
    const equityId = params?.equityId

    const [equityRequest, setEquityRequest] = useState([])

    const [typeOfCapitalSelect, setTypeOfCapitalSelect] = useState([])
    const [isSubscribed, setIsSubscribed] = useState(false);

    const type_of_capital = equityRequest.type_of_capital

    useEffect(() => {
        if (equityId && token) {
            equityDetail(token, equityId)
                .then(res => {
                    setGetDetailEquityId(res.data)
                })
                .catch(err => console.log(err))
        }
    }, [])


    useEffect(() => {
        if (equityId && getDetailEquityId.type_of_capital?.length > 0) {
            setTypeOfCapitalSelect(getDetailEquityId.type_of_capital)
            setForm({ amount_of_capital_to_raise: getDetailEquityId.amount_of_capital_to_raise, valuation: getDetailEquityId.valuation, special_notes: getDetailEquityId.special_notes, is_private: getDetailEquityId.is_private })
            setSelectFirm(getDetailEquityId.firm)

        }
    }, [equityId, getDetailEquityId.type_of_capital])

    useEffect(() => {
        getEquityRequest()
            .then((res) => {
                setEquityRequest(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, []);

    const isAllEquityTypeOfCapitalSelected = type_of_capital?.length > 0 && typeOfCapitalSelect.length === type_of_capital?.length;

    const handleChangeEquityTypeOfCapital = (event) => {
        const value = event.target.value;
        if (value === "all") {
            setTypeOfCapitalSelect(typeOfCapitalSelect.length === type_of_capital?.length ? [] : type_of_capital);
            return;
        }
        const list = [...typeOfCapitalSelect];
        const index = list.indexOf(value);
        index === -1 ? list.push(value) : list.splice(index, 1);
        setTypeOfCapitalSelect(list);
    };

    const onChangeInput = (event) => {
        setForm({ ...form, [event.target.name]: event.target.value });
    };

    const listTypeOfCapital = type_of_capital?.map((option) => {
        return (
            <div key={option}>
                <Checkbox
                    value={option}
                    onChange={handleChangeEquityTypeOfCapital}
                    checked={typeOfCapitalSelect.includes(option)}
                    className={styles.check_input}
                />
                <span> {intl.formatMessage({ id: option })}</span>
            </div>
        );
    });

    const findSelectFirm = firms?.find(firm => selectFirm === firm.name)?.id

    const createAll = {
        type_of_capital: typeOfCapitalSelect,
        amount_of_capital_to_raise: form.amount_of_capital_to_raise,
        valuation: form.valuation,
        special_notes: form.special_notes,
        is_private: form.is_private,
        firm: selectFirm ? selectFirm : "other",
    }


    const onSubmit = (e) => {
        e.preventDefault();
        token ? (createAll.type_of_capital?.length === 0 || createAll.amount_of_capital_to_raise?.length === 0 || createAll.valuation?.length === 0 || createAll.firm?.length === 0) ?
            toast.error("Fields with * are required.")
            : (
                (equityId) ? ((updateEquityRequest(token, equityId, createAll)).then(
                    res => {
                        navigate("/equity")
                        toast.success("Loan updated successfully.")
                    }
                ).catch(
                    err => { toast.error("Fields with * are required.") }
                )) : (equityRequestCreate(token, createAll).then(res => {
                    navigate("/equity")
                    toast.success("Loan request created successfully.")
                }).catch(err => {
                    toast.error("Error creating loan request.")
                }))
            ) : (createAll.type_of_capital?.length === 0 || createAll.amount_of_capital_to_raise?.length === 0 || createAll.valuation?.length === 0 || createAll.firm?.length === 0) ?
            toast.error("Fields with * are required.")
            : (
                dispatch({
                    type: storeConstants.ADD_EQUITY,
                    payload: createAll,
                }),
                dispatch({
                    type: storeConstants.SAVE_EQUITY,
                    payload: true
                }),
                navigate("/register")
            )
    }

    useEffect(() => {
        if (getDetailEquityId && token) {
            getFirmList(token)
                .then((res) => {
                    dispatch({
                        type: storeConstants.GET_FIRM_LIST,
                        payload: res.data.results,
                    })
                })
                .catch((e) => console.log(e))
        }

    }, [])

    const handleChange = event => {
        if (event.target.checked) {
            setForm({ ...form, is_private: true });
        } else {
            console.log("no checked");
            setForm({ ...form, is_private: false });
        }
        setIsSubscribed(current => !current);
    };

    return (
        <div className='container mt-3'>
            <div id={styles.get_loan}>
                <form className='form-group text-black'>
                    <div className={styles.page}>
                        <div className={styles.page_label} data-tip={
                            intl.formatMessage({ id: 'LOAN_HEADER_FOR_FIRM' })}
                        >
                            {
                                intl.formatMessage({
                                    id: 'LOAN_HEADER_FOR_FIRM',
                                })
                            }:</div>
                        <select className={styles.page_form_select}
                            onChange={(e) => { setSelectFirm(e.target.value) }}>
                            <option value="">
                                {intl.formatMessage({ id: "LOAN_REQUEST_SELECT" })}
                            </option>
                            {
                                getDetailEquityId.id && !getDetailEquityId.firm ? (
                                    <option selected value="other">{intl.formatMessage({ id: "Other" })}</option>
                                ) :
                                    <option value="other">{intl.formatMessage({ id: "Other" })}</option>
                            }
                            {firms?.map((option, index) => {
                                return (
                                    selectFirm === option.name ? (
                                        <option selected value={option.id}>{option.name}</option>
                                    ) : (
                                        <option value={option.id}>{option.name}</option>
                                    )
                                )
                            })}
                        </select>
                        <ReactTooltip />
                    </div>
                    <hr />
                    <div className={styles.page}>
                        <div className={styles.page_label} data-tip={
                            intl.formatMessage({ id: 'EQUITY_HEADER_TYPE_OF_CAPITAL' })
                        }>{intl.formatMessage({ id: "EQUITY_HEADER_TYPE_OF_CAPITAL" })}*:</div>
                        <div className={styles.page_table}>
                            <Checkbox required value="all" className={styles.check_input} onChange={handleChangeEquityTypeOfCapital} checked={isAllEquityTypeOfCapitalSelected} />
                            {
                                intl.formatMessage({ id: "LOAN_REQUEST_SELECT_ALL" })
                            }
                            {listTypeOfCapital}
                        </div>
                        <ReactTooltip />
                    </div>
                    <hr />
                    <div className={styles.page}>
                        <div className={styles.page_label} data-tip={
                            intl.formatMessage({ id: 'LOAN_HEADER_FOR_FIRM' })
                        }>{intl.formatMessage({ id: "EQUITY_HEADER_AMOUNT" })}*:</div> <ReactTooltip />
                        <div className={styles.page_table}>
                            <input
                                name="amount_of_capital_to_raise"
                                value={form.amount_of_capital_to_raise}
                                onChange={onChangeInput}
                                type="number"
                            />
                        </div>
                    </div>
                    <hr />
                    <div className={styles.page}>
                        <div className={styles.page_label} data-tip={
                            intl.formatMessage({ id: 'EQUITY_HEADER_VALUATION' })
                        }>{intl.formatMessage({ id: "EQUITY_HEADER_VALUATION" })}*:</div>
                        <div className={styles.page_table}>
                            <input
                                name="valuation"
                                value={form.valuation}
                                onChange={onChangeInput}
                                type="number"
                            />
                        </div>
                        <ReactTooltip />
                    </div>
                    <hr />
                    <div className={styles.page}>
                        <div className={styles.page_label} data-tip={
                            intl.formatMessage({ id: 'LOAN__HEADER_SPECIAL_NOTES' })
                        }>                            {
                                intl.formatMessage({
                                    id: 'LOAN__HEADER_SPECIAL_NOTES',
                                })
                            }:</div>
                        <div className={styles.page_table}>
                            <textarea rows='4' cols='50'
                                value={form.special_notes}
                                onChange={onChangeInput}
                                name="special_notes"
                                required={true}
                            />
                        </div>
                        <ReactTooltip />
                    </div>
                    <hr />
                    <div className={styles.page}>
                        <div className={styles.page_label} data-tip={
                            intl.formatMessage({ id: 'LOAN_HEADER_PRIVATE' })
                        }>
                            {
                                intl.formatMessage({
                                    id: 'LOAN_HEADER_PRIVATE',
                                })
                            }
                            :</div>
                        <div className={styles.page_table}>
                            <Checkbox onChange={handleChange} checked={form.is_private} className={styles.check_input} />
                            {
                                intl.formatMessage({
                                    id: 'LOAN_REQUEST_PRIVATE',
                                })
                            }
                        </div>
                        <ReactTooltip />
                    </div>
                    <hr />
                    <div style={{ textAlign: "center" }}>
                        {
                            intl.formatMessage({
                                id: 'LOAN_REQUEST_FIELDS_REQUIRED',
                            })
                        }
                    </div>
                    <div className={styles.page}>
                        <button
                            type="submit"
                            onClick={onSubmit}
                            style={{ margin: "0 auto", width: "20%" }}
                            className="btn btn-primary analysis_button mt-3"
                        >
                            {
                                intl.formatMessage({
                                    id: 'LOAN_REQUEST_SUBMIT',
                                })
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>

    )
}

export default EquityRequest