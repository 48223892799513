import React, { useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import { useIntl } from 'react-intl';
import MyLoader from "components/MyLoader";

const HeaderAnalysisChart = ({ isLoading }) => {
    const intl = useIntl();
    const { rating_valuation = [], custom_valuation = [] } = useSelector(
        (state) => state.analysisDataReducer.analysisResult || {}
    );


    console.log(isLoading, "isLoading")


    const categories = custom_valuation.length > 0 && custom_valuation[0]?.value !== "N/A"
        ? [intl.formatMessage({ id: "baseline" }), intl.formatMessage({ id: "custom" })]
        : [intl.formatMessage({ id: "baseline" })];

    const ratingData = rating_valuation.length > 0
        ? custom_valuation.length > 0 && custom_valuation[0]?.value !== "N/A"
            ? [
                (parseFloat(rating_valuation[0]?.value) || 0).toFixed(3),
                (parseFloat(custom_valuation[0]?.value) || 0).toFixed(3),
            ]
            : [(parseFloat(rating_valuation[0]?.value) || 0).toFixed(3)]
        : [];

    const valuationData = rating_valuation.length > 1
        ? custom_valuation.length > 1 && custom_valuation[1]?.value !== "N/A"
            ? [
                (parseFloat(rating_valuation[1]?.value) || 0).toFixed(3),
                (parseFloat(custom_valuation[1]?.value) || 0).toFixed(3),
            ]
            : [(parseFloat(rating_valuation[1]?.value) || 0).toFixed(3)]
        : [];

    const commonChartOptions = {
        chart: {
            type: "bar",
            height: 230,
        },
        plotOptions: {
            bar: {
                columnWidth: "10%",
            },
        },

        dataLabels: {
            enabled: true, position: "top",
            offsetY: 70,
            style: {
                fontSize: "12px",
                fontWeight: "bold",
                colors: ["#000"],
            },
        },
        grid: {
            row: { colors: ["#ffffff", "#f8f9fa"] },
        },
        xaxis: {
            categories: categories,
            labels: { rotate: -45 },

        },
        yaxis: {
            title: { text: intl.formatMessage({ id: "values" }) },
        },
        fill: {
            type: "gradient",
            gradient: {
                shade: "light",
                type: "horizontal",
                shadeIntensity: 0.5,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 50, 100],
                colorStops: [
                    { offset: 0, color: "#00c6ff", opacity: 1 },
                    { offset: 50, color: "#0072ff", opacity: 1 },
                    { offset: 100, color: "#0052d4", opacity: 1 },
                ],
            },
        },
    };

    const ratingChartData = useMemo(
        () => ({
            series: [{ name: intl.formatMessage({ id: "rating" }), data: ratingData }],
            options: commonChartOptions,
        }),
        [ratingData, categories, intl]
    );

    const valuationChartData = useMemo(
        () => ({
            series: [{ name: intl.formatMessage({ id: "valuation" }), data: valuationData }],
            options: commonChartOptions,
        }),
        [valuationData, categories, intl]
    );

    return (
        <div className="max-w-[1200px] m-auto flex flex-col md:flex-row justify-between items-center pt-2 pb-2 space-y-4 md:space-y-0 md:space-x-6 text-black">
            {(isLoading && rating_valuation) ? (
                <>
                    <div className="w-full md:w-[48%] lg:max-w-[600px] rounded-lg bg-white p-2 sm:p-1  h-[180px] flex items-center justify-center">
                        <MyLoader />
                    </div>
                    <div className="w-full md:w-[48%] lg:max-w-[600px] rounded-lg bg-white p-2 sm:p-1 h-[180px] flex items-center justify-center">
                        <MyLoader />
                    </div>
                </>

            ) : (!isLoading) ? (
                <>
                    <div className="w-full md:w-[50%] lg:max-w-[600px] max-h-[180px] rounded-lg bg-white p-1 sm:p-1 ">
                    <ReactApexChart options={ratingChartData.options} series={ratingChartData.series} type="bar" height={180} />
                    </div>
                    <div className="w-full md:w-[50%] lg:max-w-[600px] max-h-[180px] rounded-lg bg-white p-1 sm:p-1 ">
                        <ReactApexChart options={valuationChartData.options} series={valuationChartData.series} type="bar" height={180} />
                    </div>
                </>
            ) : ""}

        </div>

    );
};

export default HeaderAnalysisChart;
