import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  firmBackgroundCreate,
  realAnalysisCreate,
  realBusinessCreate,
  realFxCreate,
  realMaturityCreate,
  financialAnalysisCreate,
  financialBusinessCreate,
  financialFxCreate,
  financialMaturityCreate,
  createFirm,
  analysisCreate,
  loanRequestCreate,
  equityRequestCreate,
} from '../redux/ApiCalls'

import { useIntl } from 'react-intl'
import { login } from '../redux/ApiCalls'
import storeConstants from '../redux/constants/actionTypes'
import { toast } from 'react-toastify'

import { createTheme, ThemeProvider } from '@mui/material/styles'
import Link from '@mui/material/Link'
const theme = createTheme()

const Login = (props) => {
  const intl = useIntl()

  const { register, handleSubmit } = useForm()

  const navigate = useNavigate()

  const dispatch = useDispatch()

  const {
    firmBackground,
    financial_statements,
    fx_position,
    maturity_short_term,
    business_plan,
    analysisResult,
    saved_analysis,
  } = useSelector((state) => state.analysisDataReducer)

  let custom = analysisResult?.scenarios

  const { addLoan, saveLoan } = useSelector((state) => state.loanRequest)

  const { addEquity, saveEquity } = useSelector((state) => state.equityRequest)

  const onSubmit = (data) => {
    login({
      email: data.email,
      password: data.password,
    })
      .then((res) => {
        if (res.data.access) {
          dispatch({
            type: storeConstants.LOGIN_SUCCESS,
            payload: res.data.access,
          })
          toast.success(
            intl.formatMessage({
              id: 'TOAST_MESSAGES.LOGIN.SUCCESS',
            })
          )
          let token = res.data.access
          if (saveLoan) {
            loanRequestCreate(equityRequestCreate, token, addLoan)
              .then((res) => {
                toast.success('Loan request created successfully.')
              })
              .catch((err) => {
                toast.error('Loan request creation failed.')
              })
          }
          if (saveEquity) {
            equityRequestCreate(token, addEquity)
              .then((res) => {
                toast.success('Equity request created successfully.')
              })
              .catch((err) => {
                toast.error('Equity request creation failed.')
              })
          }
          if (saved_analysis) {
            createFirm(res.data.access, {
              ...firmBackground,
              foreCastStartDate: firmBackground.foreCastStartDate / 1000,
              decemberOfLastYear: firmBackground.decemberOfLastYear / 1000,
              foreCastEndDate: firmBackground.foreCastEndDate / 1000,
              fiscalYear: firmBackground.fiscalYear / 1000,
            })
              .then(({ data }) => {
                toast.success('Firm Created')
                let firmSlug = data.slug
                let token = res.data.access
                analysisCreate(token, firmSlug)
                  .then(({ data }) => {
                    toast.success('Analysis Created')
                    let analysisId = data.id

                    if (firmBackground.industry === 'Finance & Insurance') {
                      financialAnalysisCreate(
                        token,
                        financial_statements,
                        analysisId
                      )
                      financialFxCreate(token, fx_position, analysisId)
                      financialMaturityCreate(
                        token,
                        maturity_short_term,
                        analysisId
                      )
                      financialBusinessCreate(token, business_plan, analysisId)
                    } else {
                      realAnalysisCreate(
                        token,
                        financial_statements,
                        analysisId
                      )
                      realFxCreate(token, fx_position, analysisId)
                      realMaturityCreate(token, maturity_short_term, analysisId)
                      realBusinessCreate(token, business_plan, analysisId)
                    }
                    // firmBackgroundCreate(token, firmBackground)
                    customScenarioCreate(token, custom, analysisId).then(
                      ({ data }) => {
                        let customId = data.id
                        let analyzeId = data.analyze
                        custom.map((yearData) => {
                          let newCustom = {}
                          yearData.values.map((data, index) => {
                            newCustom[data.key] = data.value
                          })
                          newCustom.year_for_data = new Date(
                            yearData.year
                          ).toISOString()
                          newCustom.projection_frequency =
                            firmBackground.projection_frequency
                          customScenarioDataCreate(
                            token,
                            newCustom,
                            analyzeId,
                            customId
                          ).catch((err) => {
                            console.log(err)
                          })
                        })
                      }
                    )
                  })
                  .catch((err) => {
                    console.log(err)
                  })
              })
              .catch((err) => {
                console.log(err)
                toast.error(err?.response?.detail)
              })
          }
          navigate('/myfirm')
        }
      })
      .catch((err) => {
        toast.error(
          intl.formatMessage({
            id: 'TOAST_MESSAGES.LOGIN.ERROR',
          })
        )
      })
  }
  const onClick = () => {
    navigate('/register')
  }
  const onClickForgotPassword = () => {
    navigate('/reset')
  }
  return (
  <div className="min-h-screen flex items-center justify-center bg-gray-100 ">
      <div className="w-full max-w-md bg-white p-8 rounded-lg shadow-lg">

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          {/* Email Field */}
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              {intl.formatMessage({ id: 'REGISTER.EMAIL' })}
            </label>
            <input
              id="email"
              name="email"
              type="email"
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              {...register('email')}
            />
          </div>

          {/* Password Field */}
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              {intl.formatMessage({ id: 'REGISTER.PASSWORD' })}
            </label>
            <input
              id="password"
              name="password"
              type="password"
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              {...register('password')}
            />
          </div>

          {/* Remember me Checkbox */}
          <div className="flex items-center">
            <input
              id="remember"
              name="remember"
              type="checkbox"
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            />
            <label htmlFor="remember" className="ml-2 text-sm text-gray-900">
         Remember me
            </label>
          </div>

          {/* Submit Button */}
          <div>
            <button
              type="submit"
              className="w-full flex-1 bg-blue-500 text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 flex items-center justify-center disabled:opacity-50"
              >
              {intl.formatMessage({ id: 'LOGIN.TITLE' })}
            </button>
          </div>
        </form>

        {/* Forgot Password and Sign Up Links */}
        <div className="flex justify-between items-center mt-4">
          <button className="text-sm text-blue-600 hover:text-blue-800">
            <Link onClick={onClickForgotPassword} variant="body2">
            {intl.formatMessage({
                          id: 'LOGIN.TEXT_PASSWORD_FORGOT',
                        })}
            </Link>
          </button>
          <button className="text-sm text-blue-600 hover:text-blue-800">
            <Link onClick={onClick} variant="body2">
              {intl.formatMessage({ id: 'LOGIN.TEXT' })}
            </Link>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Login
