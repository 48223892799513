import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as analysisDataActions from 'redux/actions/analysisDataActions'
import { useIntl } from 'react-intl'
import {
  analysis,
} from 'redux/ApiCalls'
import storeConstants from 'redux/constants/actionTypes'
import ReactTooltip from 'react-tooltip'
import CurrencyInfo from 'components/CurrencyInfo'
import ProgressTableAndExcelExport from 'components/ProgressTableAndExcelExport'
import { TableStyle } from '../components/TableStyles/TableStyles';
import MyLoader from 'components/MyLoader'


const CustomScenario = ({ isLoading, setIsLoading }) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const [lastKeyPress, setLastKeyPress] = useState('')
  const { token } = useSelector((state) => state.auth)

  const [isLastCustomScenario, setIsLastCustomScenario] = useState(false)

  const {
    firmBackground,
    financial_statements,
    fx_position,
    maturity_short_term,
    business_plan,
    analysisResult,
    firmSlug,
    initial_custom_scenario,
  } = useSelector((state) => state.analysisDataReducer)
  const { scenarios, enable_custom_scenario } = analysisResult
  const tourState = useSelector((state) => state.joyRide)
  let { flowOrstock } = firmBackground

  const trueOrFalse = (() => {
    if (firmBackground.industry === 'Finance & Insurance') {
      return false
    }
    return true
  })()

  const calculateAnalysis = (isCustomScenario) => {
    setIsLoading(true)
    if (analysisResult.enable_custom_scenario !== isCustomScenario) {
      isCustomScenario
        ? dispatch(analysisDataActions.enableCustomScenario())
        : dispatch(analysisDataActions.disableCustomScenario())
    }
    const postData = {
      analysis_data: {
        financial: {},
        fx: {},
        maturity: {},
        business_plan: {},
        scenario: {},
      },
      firm_background: firmBackground,
      is_analysis_real: trueOrFalse,
      enable_custom_scenario: isCustomScenario,
    }

    financial_statements.map((item, index) => {
      if (index === 0) {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.financial[value.key] = [newValue]
        })
      } else {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.financial[value.key].push(newValue)
        })
      }
    })

    fx_position.map((item, index) => {
      if (index === 0) {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.fx[value.key] = [newValue]
        })
      } else {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.fx[value.key].push(newValue)
        })
      }
    })

    maturity_short_term.map((item, index) => {
      if (index === 0) {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.maturity[value.key] = [newValue]
        })
      } else {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.maturity[value.key].push(newValue)
        })
      }
    })

    business_plan.map((item, index) => {
      if (index === 0) {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.business_plan[value.key] = [newValue]
        })
      } else {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.business_plan[value.key].push(newValue)
        })
      }
    })

    if (analysisResult?.scenarios) {
      analysisResult.scenarios.map((item, index) => {
        if (index === 0) {
          item.values.map((value) => {
            let newValue =
              typeof value.value === 'string'
                ? Number(value.value.replace(/,/g, ''))
                : value.value
            postData.analysis_data.scenario[value.key] = [newValue]
          })
        } else {
          item.values.map((value) => {
            let newValue =
              typeof value.value === 'string'
                ? Number(value.value.replace(/,/g, ''))
                : value.value
            postData.analysis_data.scenario[value.key].push(newValue)
          })
        }
      })
    } else {
      initial_custom_scenario.map((item, index) => {
        if (index === 0) {
          item.values.map((value) => {
            let newValue =
              typeof value.value === 'string'
                ? Number(value.value.replace(/,/g, ''))
                : value.value
            postData.analysis_data.scenario[value.key] = [newValue]
          })
        } else {
          item.values.map((value) => {
            let newValue =
              typeof value.value === 'string'
                ? Number(value.value.replace(/,/g, ''))
                : value.value
            postData.analysis_data.scenario[value.key].push(newValue)
          })
        }
      })
    }

    analysis(token, postData)
      .then(({ data }) => {
        dispatch(analysisDataActions.fetchRealAnalysis(data))
        setIsLoading(false)
      })
      .catch((err) => err)
  }


  const handleInputChange = (value, key, index) => {
    if (isLoading) {
      return
    }

    const newCustomScenarioData = [...scenarios]
    const findValue = newCustomScenarioData[index].values.find(
      (item) => item.key === key
    )

    findValue.value = value
    dispatch(analysisDataActions.customScenario(newCustomScenarioData))
    setLastKeyPress(newCustomScenarioData)

    const numberRegex = /^-?\d*\.?\d+$/
    let newValue = value.replace(/\./g, '')

    if (value === '' || newValue === '') {
      newValue = '0'
    }

    if (newValue.match(numberRegex)?.length === newValue.length) {
      const newCustomScenarioData = [...scenarios]
      const findValue = newCustomScenarioData[index].values.find(
        (item) => item.key === key
      )

      findValue.value = Number(newValue).toLocaleString('en-US')
      dispatch(analysisDataActions.customScenario(newCustomScenarioData))
      setLastKeyPress(newCustomScenarioData)
      setIsLastCustomScenario(true)

      dispatch({
        type: storeConstants.REMOVE_OR_GET_LAST,
        payload: true,
      })
    }
  }

  useEffect(() => {
    if (isLastCustomScenario) {
      const timeoutId = setTimeout(() => {
        dispatch(analysisDataActions.enableCustomScenario())
        calculateAnalysis(true)
      }, 2000)
      return () => clearTimeout(timeoutId)
    }
  }, [isLastCustomScenario])

  useEffect(() => {
    if (lastKeyPress) {
      const timeoutId = setTimeout(() => {
        calculateAnalysis(true)
        setIsLoading(true)
      }, 2000)
      return () => clearTimeout(timeoutId)
    }
  }, [lastKeyPress])

  useEffect(() => {
    console.log(enable_custom_scenario, 'enable_custom_scenario')
    if (enable_custom_scenario) {
      const timeoutId = setTimeout(() => {
        calculateAnalysis(true)
        setIsLoading(true)
      }, 2000)
      return () => clearTimeout(timeoutId)
    }
  }, [enable_custom_scenario])




  const table = (
    <div className="overflow-x-auto  p-2 bg-white rounded-lg shadow-lg">

      <table className=" !border border-[#d2d5d9] rounded-md table-auto">      <thead className="sticky top-0 text-end  z-9">
        <tr >
          <th className={`${TableStyle.ThStyleHeaderText} text-center text-bold bg-white `}>
            <span className="flex">
              {intl.formatMessage({ id: 'FINANCIAL_CHART.CURRENCY' })}{firmBackground.currency_sym}
              <span className="flex ">
                {intl.formatMessage({ id: 'FINANCIAL_CHART.DECIMAL' })}{firmBackground.decimal}
              </span>
            </span>
          </th>

        </tr>
      </thead>
        <thead className="sticky text-end  z-9">
          <tr>
            <th className={TableStyle.ThStyleHeaderText}>
              <CurrencyInfo showFirstButton={true} showSecondButton={true} />
            </th>
            <th className={`${TableStyle.ThStyleHeaderText} text-dark text-center`} colSpan={1}>
              <ProgressTableAndExcelExport showFirstButton={true} />
            </th>
            <th className={`${TableStyle.ThStyleHeader} text-black text-end`} colSpan={1}>
              <ProgressTableAndExcelExport showSecondButton={true} />
            </th>

          </tr>
        </thead>



        <thead className="text-center w-full  bg-primary rounded-md">
          <tr>
            <th className={`${TableStyle.TdLabelStyle} !bg-gray-300 !font-semibold`}>
              {intl.formatMessage({
                id: 'CUSTOM_SCENARIO.TABLE_TITLE',
              })}
            </th>


            {scenarios?.map((item, index) => (
              <th key={index} scope="col" className={TableStyle.ThStyle}>

                {new Date(item.year).toLocaleString('en-US', { month: 'short' }) +
                  ' ' +
                  new Date(item.year).toLocaleString('en-US', { year: 'numeric' })}
              </th>
            ))}

          </tr>
        </thead>
        <tbody>
          {scenarios
            ? scenarios[0]?.values?.map((row, rowIndex) => (
              <tr key={rowIndex} className={'bg-gray-200'}>
                <td
                  className={`${TableStyle.TdLabelStyle} text-gray-800 font-normal !border-l  border-b border-[#d2d5d9] `}
                  data-tip={
                    row.label === 'Export Market GDP'
                      ? intl.formatMessage({
                        id: 'tooltip_WGDP',
                      })
                      : row.label === 'Domestic Market GDP'
                        ? intl.formatMessage({
                          id: 'tooltip_GDP',
                        })
                        : row.label === 'FX Rate (LCY/USD)'
                          ? intl.formatMessage({
                            id: 'tooltip_FX',
                          })
                          : row.label === 'Lending Rate on Local Curency Debts'
                            ? intl.formatMessage({
                              id: 'tooltip_LR',
                            })
                            : row.label === 'Lending Rate on Foreign Curency Debts'
                              ? intl.formatMessage({
                                id: 'tooltip_US_LR',
                              })
                              : row.label === 'Inflation'
                                ? intl.formatMessage({
                                  id: 'tooltip_INF',
                                })
                                : row.label === 'Export Market Inflation'
                                  ? intl.formatMessage({
                                    id: 'tooltip_US_INF',
                                  })
                                  : row.label ===
                                    'Rate of Return on Domestic Equity Investments'
                                    ? intl.formatMessage({
                                      id: 'tooltip_ER',
                                    })
                                    : row.label ===
                                      'Rate of Return on Foreign Equity Investments'
                                      ? intl.formatMessage({
                                        id: 'tooltip_OIL',
                                      })
                                      : row.label ===
                                        'Rate of Return on Foreign Equity Investments'
                                        ? intl.formatMessage({
                                          id: 'tooltip_OIL',
                                        })
                                        : ''
                  }
                >
                  <ReactTooltip />
                  <span className="block truncate">
                    {intl.formatMessage({ id: row.key })}
                  </span>
                </td>
                {scenarios.map((yearData, columnIndex) => (
  <td key={columnIndex} className={`${TableStyle.TdStyle} bg-white text-end`}>
    {isLoading ? (
      <MyLoader />
    ) : (
      <input
        type="text"
        defaultValue="0"
        pattern="^-?\d*\.?\d+"
        onFocus={(e) => {
          if (e.target.value === '0') e.target.value = ''; 
        }}
        onBlur={(e) => {
          if (e.target.value === '') handleInputChange(0, row.key, columnIndex); 
        }}
        onChange={(e) => {
          let newValue = e.target.value.replace(/,/g, ''); 
          if (!isNaN(newValue)) {
            handleInputChange(newValue, row.key, columnIndex); 
          }
        }}
        className="w-full border-none text-end text-xxs focus:outline-none focus:ring-2 focus:ring-blue-500 m-0 py-0"
        value={yearData.values[rowIndex]?.value
          ?.toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 
      />
    )}
  </td>
))}

              </tr>
            ))
            : ''}
        </tbody>
      </table>
    </div>
  )

  useEffect(() => {
    tourState.indexTab3 === false && window.scrollTo(0, 0)
  }, [tourState.indexTab3])

  return (
    <>
      <div>
        {table}
      </div>
    </>
  )
}

export default CustomScenario
