import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { FaHandshake, FaSignInAlt, FaUserPlus } from "react-icons/fa";
import { FaMagnifyingGlassChart } from "react-icons/fa6";
import { PiEqualizer } from "react-icons/pi";
import { getFirmList } from "redux/ApiCalls";
import storeConstants from "redux/constants/actionTypes";
import CountryFlag from "components/CountryFlag";
import { useIntl } from "react-intl";
import { FaBuilding } from "react-icons/fa";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl()

  useEffect(() => {
    getFirmList(auth.token)
      .then((res) => {
        dispatch({
          type: storeConstants.GET_FIRM_LIST,
          payload: res.data.results,
        });
      })
      .catch((e) => console.log(e));
  }, [auth.token, dispatch]);


  return (
    <header className="flex bg-gradient-to-t from-[#107eff] via-[#0062d4] to-[#01277c] py-3 px-4 sm:px-10 font-sans min-h-[70px] tracking-wide relative z-50 mx-auto">
      <div className="flex !items-center justify-between gap-4 w-full max-w-screen-xl">
        <div className="flex items-center justify-start">
          <h1 className="flex items-center text-1xl font-bold dark:text-white">
            FinancialPrediction
            <span className="bg-blue-100 text-blue-800 text-xl font-semibold px-1 rounded-sm dark:bg-blue-200 dark:text-blue-800 ml-1">
              AI
            </span>
          </h1>
        </div>
        <div
          className={`${menuOpen ? "flex bg-gradient-to-t from-[#107eff] via-[#0062d4] to-[#01277c]" : "hidden"
            } lg:flex items-start lg:static fixed inset-0 lg:bg-transparent lg:shadow-none shadow-md lg:w-auto w-2/3 lg:h-auto h-full lg:space-y-0 space-y-4 z-50 px-6 py-8 lg:p-0`}
        >
          <button
            onClick={() => setMenuOpen(false)}
            className="lg:hidden absolute top-4 right-4 w-9 h-9 rounded-full flex items-center justify-center bg-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-4 h-4 fill-black"
              viewBox="0 0 320.591 320.591"
            >
              <path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"></path>
              <path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"></path>
            </svg>
          </button>

          <ul
            className={`flex flex-col lg:flex-row lg:gap-x-6 space-y-3 lg:space-y-0 ${menuOpen ? "items-start" : "items-center"
              }`}
          >
            <li>
              <a href="/analysis" className="text-gray-200 hover:text-white text-base flex items-center">
                <FaMagnifyingGlassChart className="mr-1" />
                {intl.formatMessage({
                  id: 'NAV.BUTTON.ANALYSIS',
                })}
              </a>
            </li>
            <li>
              {auth.token ? (
                <div>
                  <Link
                    to="/get-loan"
                    className="text-gray-200 hover:text-white text-base flex items-center"
                  >
                    <FaHandshake className="mr-1" />
                    {intl.formatMessage({ id: 'SIDEBAR_ROUTES.LOANS_REQUESTS' })}</Link>
                </div>
              ) : (
                <div>
                  <Link
                    to="/loan"
                    className="text-gray-200 hover:text-white text-base flex items-center"
                  >
                    <FaHandshake className="mr-1" />
                    {intl.formatMessage({ id: 'SIDEBAR_ROUTES.LOANS_REQUESTS' })}  </Link>
                </div>
              )}
            </li>

            {auth.token ? (

              <li>
                <Link

                  to="/get-equity"
                  className="text-gray-200 hover:text-white text-base flex items-center"
                >
                  <PiEqualizer className="mr-1" />
                  {intl.formatMessage({ id: 'SIDEBAR_ROUTES.EQUITY_REQUESTS' })}                </Link>
              </li>
            ) : (
              <li>
                <Link

                  to="/get-equity"
                  className="text-gray-200 hover:text-white text-base flex items-center"
                >
                  <PiEqualizer className="mr-1" />
                  {intl.formatMessage({ id: 'SIDEBAR_ROUTES.EQUITY_REQUESTS' })}
                </Link>
              </li>
            )}

            {auth.token ? (
              <>
                <li className="lg:hidden">
                  <Link
                    onClick={() => {
                      navigate("/myfirm");
                    }}
                    className="text-gray-200 hover:text-white text-base flex items-center"
                  >
                    <FaSignInAlt className="mr-1" />
                    {intl.formatMessage({
                      id: 'NAV.BUTTON.MY_FIRM',
                    })}
                  </Link>
                </li>
                {/* <li className="lg:hidden">
                  <Link
                    onClick={() => {
                      navigate("/myfirm-update ");
                    }}
                    className="text-gray-200 hover:text-white text-base flex items-center"
                  >
                    <FaUserPlus className="mr-1" />
                    {intl.formatMessage({ id: 'SIDEBAR_ROUTES.ACCOUNT' })}
                  </Link>
                </li> */}
                <li className="lg:hidden">
                  <Link
                    onClick={() =>
                      dispatch(
                        logOut(),
                        navigate('/homepage'),
                        toast.info('successfully logged out')
                      )
                    }
                    className="text-gray-200 hover:text-white text-base flex items-center"
                  >
                    <FaUserPlus className="mr-1" />
                    {intl.formatMessage({
                      id: 'NAV.BUTTON.LOGOUT',
                    })}
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li className="lg:hidden">
                  <Link
                    onClick={() => {
                      navigate("/login");
                    }}
                    className="text-gray-200 hover:text-white text-base flex items-center"
                  >
                    <FaSignInAlt className="mr-1" />
                    {intl.formatMessage({
                      id: 'NAV.BUTTON.LOGIN',
                    })}
                  </Link>
                </li>
                <li className="lg:hidden">
                  <Link
                    onClick={() => {
                      navigate("/register");
                    }}
                    className="text-gray-200 hover:text-white text-base flex items-center"
                  >
                    <FaUserPlus className="mr-1" />
                    {intl.formatMessage({
                      id: 'NAV.BUTTON.SIGN_UP',
                    })}
                  </Link>
                </li>
              </>
            )}


          </ul>
        </div>



        {auth.token ? (
          <div className="hidden lg:flex items-center gap-4 lg:ml-auto">

            <Link sc="/myfirm"                 className="text-gray-200 hover:text-white text-base flex items-center"
            >
              <FaBuilding className="mr-1"/>
              {intl.formatMessage({
                id: 'NAV.BUTTON.MY_FIRM',
              })}
            </Link>
            <Link to="/myfirm-update"                 className="text-gray-200 hover:text-white text-base flex items-center"
            >
              {intl.formatMessage({ id: 'SIDEBAR_ROUTES.ACCOUNT' })}
            </Link>
            <Link
              onClick={() =>
                dispatch(
                  logOut(),

                  toast.info('successfully logged out')
                )
              }
              to="homepage"
              className="text-gray-200 hover:text-white text-base flex items-center"

            >
              {intl.formatMessage({
                id: 'NAV.BUTTON.LOGOUT',
              })}
            </Link>
          </div>
        ) : (
          <>
            <div className="hidden lg:flex items-center gap-4 lg:ml-auto">
              <Link
                to="login"
                className="text-gray-200 hover:text-white text-base flex items-center"
              >
                <FaSignInAlt className="mr-1" />
                {intl.formatMessage({
                    id: 'NAV.BUTTON.LOGIN',
                  })}

              </Link>
              <Link
                to="register"
                className="text-gray-200 hover:text-white text-base flex items-center"
              >
                <FaUserPlus className="mr-1" />
                {intl.formatMessage({
                    id: 'NAV.BUTTON.SIGN_UP',
                  })}

              </Link>
              <CountryFlag />

            </div>

          </>
        )}
        <button
          id="toggleOpen"
          onClick={() => setMenuOpen(true)}
          className="lg:hidden ml-4"
        >
          <svg
            className="w-7 h-7 fill-gray-300"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
    </header>


  )
}

export default Header
