import React from 'react'
import { useIntl } from 'react-intl'
import ReactTooltip from 'react-tooltip'
import { TableStyle } from '../TableStyles/TableStyles'
import CurrencyInfo from 'components/CurrencyInfo'
import ProgressTableAndExcelExport from 'components/ProgressTableAndExcelExport'
import MyLoader from 'components/MyLoader'

const Table = ({
  data,
  isLoading,
  handleInputChange,
  columnTitle,
  currencySym,
  decimal,
}) => {
  const intl = useIntl()

  return (
    <div className="overflow-x-auto p-2 bg-white rounded-lg shadow-lg ">
      <table className="!border border-[#d2d5d9] rounded-md table-auto ">
        <thead className="sticky top-0 text-end !min-h-[400px] z-9">
          <tr>
            <th className={`${TableStyle.ThStyleHeaderText} text-center text-bold bg-white`}>
              <span className="flex">
                {intl.formatMessage({ id: 'FINANCIAL_CHART.CURRENCY' })}{currencySym}
                <span className="flex ">
                  {intl.formatMessage({ id: 'FINANCIAL_CHART.DECIMAL' })}{decimal}
                </span>
              </span>
            </th>
          </tr>
        </thead>
        <thead className="sticky  text-end z-9">
          <tr>
            <th className={TableStyle.ThStyleHeaderText}>
              <CurrencyInfo showFirstButton={true} showSecondButton={true} />
            </th>
            <th className={`${TableStyle.ThStyleHeaderText} text-dark text-center`} colSpan={1}>
              <ProgressTableAndExcelExport showFirstButton={true} />
            </th>
            <th className={`${TableStyle.ThStyleHeader} text-black text-end`} colSpan={1}>
              <ProgressTableAndExcelExport showSecondButton={true} />
            </th>
          </tr>
        </thead>
        <thead className="text-center w-full bg-primary rounded-md">
          <tr>
            <th className={`${TableStyle.TdLabelStyle} !bg-gray-300 !font-semibold`}>
              {columnTitle}
            </th>
            {data.map((item, index) => (
              <th key={index} className={TableStyle.ThStyle}>
                {new Date(item.year).toLocaleString('en-US', { month: 'short' }) +
                  ' ' +
                  new Date(item.year).toLocaleString('en-US', { year: 'numeric' })}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data[0]?.values.map((row, rowIndex) => (
            <tr key={rowIndex} className={'bg-gray-200'}>
              <td className={`${TableStyle.TdLabelStyle} text-gray-800 font-normal !border-l !border-r  border-b border-[#d2d5d9]`}>
                <ReactTooltip />
                <span className="block truncate">{intl.formatMessage({ id: row.key })}</span>
              </td>
              {data.map((yearData, columnIndex) => (
                <td key={columnIndex} className={`${TableStyle.TdStyle} bg-white text-end !border-l  border-[#d2d5d9]`}>
                  {isLoading ? (

                    <MyLoader />

                  ) : (
                    <input
                      onFocus={(e) => {
                        if (e.target.value === '0') e.target.value = ''
                      }}
                      onBlur={(e) => {
                        if (e.target.value === '') handleInputChange(0, row.key, columnIndex)
                      }}
                      onChange={(e) => {
                        let newValue = e.target.value.replace(/,/g, '')
                        if (!isNaN(newValue)) {
                          handleInputChange(newValue, row.key, columnIndex)
                        }
                      }}
                      type="text"
                      value={yearData.values[rowIndex]?.value
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      className="w-full border-none text-end text-xs focus:outline-none focus:ring-2 m-0 py-1 bg-white text-gray-900 px-2"
                    />
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default Table
