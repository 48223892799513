import { useEffect,  useState } from 'react'
import { useSelector } from 'react-redux'
import * as analysisDataActions from '../redux/actions/analysisDataActions'
import { useIntl } from 'react-intl'
import CurrencyInfo from 'components/CurrencyInfo'
import ProgressTableAndExcelExport from 'components/ProgressTableAndExcelExport'
import { TableStyle } from '../components/TableStyles/TableStyles';
import ReactTooltip from 'react-tooltip'
import MyLoader from 'components/MyLoader'
import useCalculateAnalysis from 'hooks/useCalculateAnalysis'
import useHandleInputChange from 'hooks/useHandleInputChange'


const FinancialStatements = ({ isLoading, setIsLoading, setExcelRead,runInTransition,setRunInTransition }) => {
  const intl = useIntl()
  const { rating_valuation } = useSelector(
    (state) => state.analysisDataReducer.analysisResult
  )
  const [lastKeyPress, setLastKeyPress] = useState(null)
  const { calculateAnalysis } = useCalculateAnalysis(setIsLoading)

  const {
    analysisResult,
    firmBackground,
    financial_statements,
  } = useSelector((state) => state.analysisDataReducer)

  const { handleInputChange } = useHandleInputChange(analysisDataActions.financialStatements, financial_statements, calculateAnalysis)

  let { flowOrstock } = firmBackground


  useEffect(() => {
    if(lastKeyPress || runInTransition){
      const timeoutId = setTimeout(() => calculateAnalysis(), 1500)
      return () => clearTimeout(timeoutId)
    }

  }, [financial_statements, lastKeyPress])


  const formatDate = (year, month) => {
    const date = new Date(Number(year), Number(month) - 1, 1);
    return `${date.toLocaleString('en-US', { month: 'short' })} ${date.toLocaleString('en-US', { year: 'numeric' })}`;
  };

  const renderHeaders = (data, isProjection) => {
    setRunInTransition(false)
    return data?.map((yearData, columnIndex) => {
      const [year, month] = String(yearData?.year).includes('-')
      ? String(yearData?.year).split('-')
      : [String(yearData?.year), ''];
    
    console.log(yearData?.year, "console.log(yearData?.year);");
    
      return (
        <th
          key={columnIndex}
          className={`${TableStyle.ThStyle} ${isProjection ? 'bg-primary !text-white' : 'bg-primary !text-white'
            } px-2`}
        >
          {isProjection ? formatDate(year, month) : yearData.year}
        </th>
      );
    });
  };

  const table = (
    <div className="overflow-x-auto  p-2 bg-white rounded-lg shadow-lg">
      {
        rating_valuation ?
          <table className="!border border-[#d2d5d9]  table-auto  ">
            <thead className="sticky top-0 text-end  z-9">
              <tr >
                <th className={`${TableStyle.ThStyleHeaderText}  text-center text-bold bg-white `}>
                  <span className="flex">
                    {intl.formatMessage({ id: 'FINANCIAL_CHART.CURRENCY' })}{firmBackground.currency_sym}
                    <span className="flex ">
                      {intl.formatMessage({ id: 'FINANCIAL_CHART.DECIMAL' })}{firmBackground.decimal}
                    </span>
                  </span>
                </th>
                <th colSpan={2} className={`${TableStyle.ThStyleHeaderText} text-dark text-center bg-white text-xs sm:text-sm`}>
                  Historical
                </th>
                {isLoading ? (
                  <>
                    <th colSpan={analysisResult?.financial_statements?.length || 1} className={`${TableStyle.ThStyleHeader}  text-black text-center !bg-gray-200 text-xs sm:text-sm`}>
                      Projection
                    </th>
                  </>
                ) : (
                  <th colSpan={analysisResult?.financial_statements?.length || 1} className={`${TableStyle.ThStyleHeader}  text-black text-center !bg-gray-200 text-xs sm:text-sm`}>
                    Projection
                  </th>
                )}
              </tr>
            </thead>
            <thead className="sticky  text-end  z-9">
              <tr>
                <th className={TableStyle.ThStyleHeaderText}>
                  <CurrencyInfo showFirstButton={true} showSecondButton={true} />
                </th>
                <th className={`${TableStyle.ThStyleHeaderText} text-dark text-center`} colSpan={1}>
                  <ProgressTableAndExcelExport showFirstButton={true} setExcelRead={setExcelRead} />
                </th>
                <th className={`${TableStyle.ThStyleHeader} text-black text-end`} colSpan={1}>
                  <ProgressTableAndExcelExport showSecondButton={true} setExcelRead={setExcelRead} />
                </th>
                <th colSpan={analysisResult?.financial_statements?.length || 1} className={`${TableStyle.ThStyleHeader} text-black !bg-gray-200  text-center text-xs sm:text-sm`}>
                </th>
              </tr>
            </thead>
            <thead className="sticky top-[44px] text-center  rounded-md bg-gray-100 z-5">
              <tr>
                <th className={`${TableStyle.TdLabelStyle} !bg-gray-300 !font-semibold`}>
                  {intl.formatMessage({ id: 'FINANCIAL_STATEMENT.BALANCE_SHEET_TABLE.TITLE' })}
                </th>
                {renderHeaders(financial_statements, true)}
                {renderHeaders(flowOrstock ? analysisResult?.financial_statements : analysisResult?.financial_statements_stock, true)}
              </tr>
            </thead>

            <tbody>
              {financial_statements[0]?.values
                .slice(0, firmBackground.industry === 'Finance & Insurance' ? 14 : 12)
                .map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    className={'bg-gray-200'}
                  >
                    <td
                      className={`${TableStyle.TdLabelStyle} text-gray-800 font-normal !border-l border-b border-[#d2d5d9] truncate sm:max-w-none`}
                      title={intl.formatMessage({ id: `tooltip_${row.key}` })}

                      data-tip={
                        row.label === 'Cash and Banks'
                          ? intl.formatMessage({
                            id: 'tooltip_cash_and_banks',
                          })
                          : row.label === 'Cash And Banks'
                            ? intl.formatMessage({
                              id: 'tooltip_cash_and_banks',
                            })
                            : row.label === 'Debt Securities'
                              ? intl.formatMessage({
                                id: 'tooltip_equity_securities',
                              })
                              : row.label === 'Equity Securities'
                                ? intl.formatMessage({
                                  id: 'tooltip_equity_securities',
                                })
                                : row.label === 'Debt Securities'
                                  ? intl.formatMessage({
                                    id: 'tooltip_debt_securities',
                                  })
                                  : row.label === 'Gross Loans'
                                    ? intl.formatMessage({
                                      id: 'tooltip_gross_loans',
                                    })
                                    : row.label === 'Non-performing Loans'
                                      ? intl.formatMessage({
                                        id: 'tooltip_non_performing_loans',
                                      })
                                      : row.label === 'General Reserves'
                                        ? intl.formatMessage({
                                          id: 'tooltip_general_reserves',
                                        })
                                        : row.label === 'Specific Reserves'
                                          ? intl.formatMessage({
                                            id: 'tooltip_specific_reserves',
                                          })
                                          : row.label === 'Total Assets'
                                            ? intl.formatMessage({
                                              id: 'tooltip_total_assets',
                                            })
                                            : row.label === 'Deposits'
                                              ? intl.formatMessage({
                                                id: 'tooltip_deposits',
                                              })
                                              : row.label === 'Borrowings'
                                                ? intl.formatMessage({
                                                  id: 'tooltip_borrowings',
                                                })
                                                : row.label === 'Equity'
                                                  ? intl.formatMessage({
                                                    id: 'tooltip_equity',
                                                  })
                                                  : row.label === 'Total Liabilities And Equity'
                                                    ? intl.formatMessage({
                                                      id: 'tooltip_total_liabilities_and_equity',
                                                    })
                                                    : row.label === 'Guarantees'
                                                      ? intl.formatMessage({
                                                        id: 'tooltip_guarantees',
                                                      })
                                                      : row.label === 'Net FX Position'
                                                        ? intl.formatMessage({
                                                          id: 'tooltip_net_fx_position',
                                                        })
                                                        : row.label === 'Interest Incomes'
                                                          ? intl.formatMessage({
                                                            id: 'tooltip_interest_incomes',
                                                          })
                                                          : row.label === 'Interest Expenses'
                                                            ? intl.formatMessage({
                                                              id: 'tooltip_interest_expenses',
                                                            })
                                                            : row.label === 'Equity Gains and Losses'
                                                              ? intl.formatMessage({
                                                                id: 'tooltip_equity_gains_losses',
                                                              })
                                                              : row.label === 'Provisions'
                                                                ? intl.formatMessage({
                                                                  id: 'tooltip_provisions',
                                                                })
                                                                : row.label === 'Income Tax'
                                                                  ? intl.formatMessage({
                                                                    id: 'tooltip_income_tax',
                                                                  })
                                                                  : row.label === 'Net Income'
                                                                    ? intl.formatMessage({
                                                                      id: 'tooltip_net_income',
                                                                    })
                                                                    : row.label === 'Regulatory Capital'
                                                                      ? intl.formatMessage({
                                                                        id: 'tooltip_capital',
                                                                      })
                                                                      : row.label === 'Risk Weighted Assets'
                                                                        ? intl.formatMessage({
                                                                          id: 'tooltip_rwas',
                                                                        })
                                                                        : row.label === 'Gross Loans'
                                                                          ? intl.formatMessage({
                                                                            id: 'tooltip_loans',
                                                                          })
                                                                          : row.label === 'Inventories'
                                                                            ? intl.formatMessage({
                                                                              id: 'tooltip_inventories',
                                                                            })
                                                                            : row.label === 'Account Receivables'
                                                                              ? intl.formatMessage({
                                                                                id: 'tooltip_account_receivables',
                                                                              })
                                                                              : row.label === 'Tangible And Intangible Assets'
                                                                                ? intl.formatMessage({
                                                                                  id: 'tooltip_tangible_and_intangible_assets',
                                                                                })
                                                                                : row.label === 'Account Payables'
                                                                                  ? intl.formatMessage({
                                                                                    id: 'tooltip_account_payables',
                                                                                  })
                                                                                  : row.label === 'Debt'
                                                                                    ? intl.formatMessage({
                                                                                      id: 'Debt',
                                                                                    })
                                                                                    : row.label === 'Balancing'
                                                                                      ? intl.formatMessage({
                                                                                        id: 'tooltip_balancing',
                                                                                      })
                                                                                      : ''
                      }
                    >
                      <ReactTooltip />

                      {intl.formatMessage({ id: row.key })}
                    </td>
                    {financial_statements.map((yearData, columnIndex) => (
                      <td key={columnIndex} className={`${TableStyle.TdStyle} w-[150px] sm:w-[100px]`}>
                        <input
                          type="text"
                          onFocus={(e) => {
                            if (e.target.value === '0') {
                              e.target.value = '';
                            }
                          }}
                          onBlur={(e) => {
                            if (e.target.value === '') {
                              handleInputChange(0, row.key, columnIndex);
                            }
                          }}
                          onChange={(e) => {
                            const newValue = e.target.value.replace(/,/g, '');
                            if (!isNaN(newValue)) {
                              handleInputChange(newValue, row.key, columnIndex);
                            }
                          }}
                          value={yearData.values[rowIndex].value
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          placeholder="0"
                          className={`w-full border-none text-end text-xs focus:outline-none focus:ring-2  m-0 py-1 bg-white text-gray-900 px-2 `}
                        />
                      </td>
                    ))}
                    {analysisResult?.financial_statements &&
                      analysisResult?.financial_statements.map((yearData, columnIndex) => (
                        <td key={columnIndex} className={`${TableStyle.TdStyle} text-end`}>
                          {isLoading ? (
                            <MyLoader />
                          ) : (
                            <input
                              className={` border-none text-end text-xs focus:outline-none focus:ring-2  !bg-gray-200 m-0 py-1  text-gray-900 px-2 `}
                              type="text"
                              value={yearData.values[rowIndex]?.value.toLocaleString('en-US')}
                              disabled
                            />
                          )}
                        </td>
                      ))}


                  </tr>
                ))}
            </tbody>
            <thead className="text-center  bg-primary rounded-md">
              <tr>
                <th className={`${TableStyle.TdLabelStyle} !bg-gray-300 !font-semibold `}>
                  {intl.formatMessage({ id: 'FINANCIAL_STATEMENT.INCOME_STATEMETNS_TABLE.TITLE' })}
                </th>
                {renderHeaders(financial_statements, true)}
                {renderHeaders(flowOrstock ? analysisResult?.financial_statements : analysisResult?.financial_statements_stock, true)}
              </tr>
            </thead>
            <tbody>
              {financial_statements[0]?.values
                .slice(firmBackground.industry === 'Finance & Insurance' ? 14 : 12)
                .map((row, rowIndex) => (
                  <tr key={rowIndex} className={'bg-gray-200'}>
                    <td
                      className={`${TableStyle.TdLabelStyle} text-gray-800 font-normal !border-l border-b border-[#d2d5d9]`}
                      title={intl.formatMessage({ id: `tooltip_${row.key}` })}
                      data-tip={intl.formatMessage({ id: `tooltip_${row.key}` })}
                    >
                      <ReactTooltip />
                      {intl.formatMessage({ id: row.key })}
                    </td>
                    {financial_statements.map((yearData, columnIndex) => (
                      <td key={columnIndex} className={`${TableStyle.TdStyle}`}>
                        <input
                          type="text"
                          onFocus={(e) => {
                            if (e.target.value === '0') e.target.value = '';
                          }}
                          onBlur={(e) => {
                            if (e.target.value === '') handleInputChange(0, row.key, columnIndex);
                          }}
                          onChange={(e) => {
                            const newValue = e.target.value.replace(/,/g, '');
                            if (!isNaN(newValue)) {
                              handleInputChange(newValue, row.key, columnIndex);
                            }
                          }}
                          value={yearData.values[rowIndex + (firmBackground.industry === 'Finance & Insurance' ? 14 : 12)]
                            .value.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          placeholder="0"
                          className="w-full  border-none text-end text-xs focus:outline-none focus:ring-2 m-0 py-1 bg-white text-gray-900 px-2"
                        />
                      </td>
                    ))}

                    {analysisResult?.financial_statements &&
                      analysisResult?.financial_statements.map((yearData, columnIndex) => (
                        <td key={columnIndex} className={`${TableStyle.TdStyle} text-end w-[150px] sm:w-[100px] `}>
                          {isLoading ? (
                            <MyLoader />

                          ) : (
                            <input
                              className=" border-none text-end text-xs focus:outline-none focus:ring-2 !bg-gray-200 m-0 py-1 text-gray-900 px-2"
                              type="text"
                              value={(yearData.values[rowIndex + (firmBackground.industry === 'Finance & Insurance' ? 14 : 12)]?.value || 0)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              disabled
                            />
                          )}
                        </td>
                      ))}
                  </tr>
                ))}
            </tbody>
          </table> :
          <table className=" !border border-[#d2d5d9] rounded-md table-auto">
            <thead className="sticky top-0 text-end  z-9">
              <tr >
                <th className={`${TableStyle.ThStyleHeaderText}  text-center text-bold bg-white `}>
                  <span className="flex">
                    {intl.formatMessage({ id: 'FINANCIAL_CHART.CURRENCY' })}{firmBackground.currency_sym}
                    <span className="flex ">
                      {intl.formatMessage({ id: 'FINANCIAL_CHART.DECIMAL' })}{firmBackground.decimal}
                    </span>
                  </span>
                </th>
                <th colSpan={2} className={`${TableStyle.ThStyleHeaderText} w-[150px] sm:w-[100px] text-dark text-center bg-white text-xs sm:text-sm`}>
                  Historical
                </th>

              </tr>
            </thead>
            <thead className="sticky  text-end  z-9">
              <tr>
                <th className={TableStyle.ThStyleHeaderText}>
                  <CurrencyInfo showFirstButton={true} showSecondButton={true} />
                </th>
                <>
                  <th className={`${TableStyle.ThStyleHeaderText} text-dark text-center`} colSpan={1}>
                    <ProgressTableAndExcelExport showFirstButton={true} />
                  </th>
                  <th className={`${TableStyle.ThStyleHeader} text-black text-end`} colSpan={1}>
                    <ProgressTableAndExcelExport showSecondButton={true} />
                  </th>
                </>
              </tr>
            </thead>
            <thead className="sticky top-[44px] text-center  rounded-md bg-gray-100 z-5">
              <tr>
                <th className={`${TableStyle.TdLabelStyle} !bg-gray-300 !font-semibold`}>
                  {intl.formatMessage({ id: 'FINANCIAL_STATEMENT.BALANCE_SHEET_TABLE.TITLE' })}
                </th>
                {renderHeaders(financial_statements, true)}
                {renderHeaders(flowOrstock ? analysisResult?.financial_statements : analysisResult?.financial_statements_stock, true)}
              </tr>
            </thead>
            <tbody>
              {financial_statements[0]?.values
                .slice(0, firmBackground.industry === 'Finance & Insurance' ? 14 : 12)
                .map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    className={'bg-gray-200'}
                  >
                    <td
                      className={`${TableStyle.TdLabelStyle} text-gray-800 font-normal !border-l border-b border-[#d2d5d9] truncate  sm:max-w-none`}
                      title={intl.formatMessage({ id: `tooltip_${row.key}` })}

                      data-tip={
                        row.label === 'Cash and Banks'
                          ? intl.formatMessage({
                            id: 'tooltip_cash_and_banks',
                          })
                          : row.label === 'Cash And Banks'
                            ? intl.formatMessage({
                              id: 'tooltip_cash_and_banks',
                            })
                            : row.label === 'Debt Securities'
                              ? intl.formatMessage({
                                id: 'tooltip_equity_securities',
                              })
                              : row.label === 'Equity Securities'
                                ? intl.formatMessage({
                                  id: 'tooltip_equity_securities',
                                })
                                : row.label === 'Debt Securities'
                                  ? intl.formatMessage({
                                    id: 'tooltip_debt_securities',
                                  })
                                  : row.label === 'Gross Loans'
                                    ? intl.formatMessage({
                                      id: 'tooltip_gross_loans',
                                    })
                                    : row.label === 'Non-performing Loans'
                                      ? intl.formatMessage({
                                        id: 'tooltip_non_performing_loans',
                                      })
                                      : row.label === 'General Reserves'
                                        ? intl.formatMessage({
                                          id: 'tooltip_general_reserves',
                                        })
                                        : row.label === 'Specific Reserves'
                                          ? intl.formatMessage({
                                            id: 'tooltip_specific_reserves',
                                          })
                                          : row.label === 'Total Assets'
                                            ? intl.formatMessage({
                                              id: 'tooltip_total_assets',
                                            })
                                            : row.label === 'Deposits'
                                              ? intl.formatMessage({
                                                id: 'tooltip_deposits',
                                              })
                                              : row.label === 'Borrowings'
                                                ? intl.formatMessage({
                                                  id: 'tooltip_borrowings',
                                                })
                                                : row.label === 'Equity'
                                                  ? intl.formatMessage({
                                                    id: 'tooltip_equity',
                                                  })
                                                  : row.label === 'Total Liabilities And Equity'
                                                    ? intl.formatMessage({
                                                      id: 'tooltip_total_liabilities_and_equity',
                                                    })
                                                    : row.label === 'Guarantees'
                                                      ? intl.formatMessage({
                                                        id: 'tooltip_guarantees',
                                                      })
                                                      : row.label === 'Net FX Position'
                                                        ? intl.formatMessage({
                                                          id: 'tooltip_net_fx_position',
                                                        })
                                                        : row.label === 'Interest Incomes'
                                                          ? intl.formatMessage({
                                                            id: 'tooltip_interest_incomes',
                                                          })
                                                          : row.label === 'Interest Expenses'
                                                            ? intl.formatMessage({
                                                              id: 'tooltip_interest_expenses',
                                                            })
                                                            : row.label === 'Equity Gains and Losses'
                                                              ? intl.formatMessage({
                                                                id: 'tooltip_equity_gains_losses',
                                                              })
                                                              : row.label === 'Provisions'
                                                                ? intl.formatMessage({
                                                                  id: 'tooltip_provisions',
                                                                })
                                                                : row.label === 'Income Tax'
                                                                  ? intl.formatMessage({
                                                                    id: 'tooltip_income_tax',
                                                                  })
                                                                  : row.label === 'Net Income'
                                                                    ? intl.formatMessage({
                                                                      id: 'tooltip_net_income',
                                                                    })
                                                                    : row.label === 'Regulatory Capital'
                                                                      ? intl.formatMessage({
                                                                        id: 'tooltip_capital',
                                                                      })
                                                                      : row.label === 'Risk Weighted Assets'
                                                                        ? intl.formatMessage({
                                                                          id: 'tooltip_rwas',
                                                                        })
                                                                        : row.label === 'Gross Loans'
                                                                          ? intl.formatMessage({
                                                                            id: 'tooltip_loans',
                                                                          })
                                                                          : row.label === 'Inventories'
                                                                            ? intl.formatMessage({
                                                                              id: 'tooltip_inventories',
                                                                            })
                                                                            : row.label === 'Account Receivables'
                                                                              ? intl.formatMessage({
                                                                                id: 'tooltip_account_receivables',
                                                                              })
                                                                              : row.label === 'Tangible And Intangible Assets'
                                                                                ? intl.formatMessage({
                                                                                  id: 'tooltip_tangible_and_intangible_assets',
                                                                                })
                                                                                : row.label === 'Account Payables'
                                                                                  ? intl.formatMessage({
                                                                                    id: 'tooltip_account_payables',
                                                                                  })
                                                                                  : row.label === 'Debt'
                                                                                    ? intl.formatMessage({
                                                                                      id: 'Debt',
                                                                                    })
                                                                                    : row.label === 'Balancing'
                                                                                      ? intl.formatMessage({
                                                                                        id: 'tooltip_balancing',
                                                                                      })
                                                                                      : row.label === 'rwas'
                                                                                        ? intl.formatMessage({
                                                                                          id: 'tooltip_balancing',
                                                                                        })

                                                                                        : ''
                      }
                    >
                      <ReactTooltip />

                      {intl.formatMessage({ id: row.key })}
                    </td>
                    {financial_statements.map((yearData, columnIndex) => (
                      <td key={columnIndex} className={`${TableStyle.TdStyle}  `}>
                        <input
                          type="text"
                          onFocus={(e) => {
                            if (e.target.value === '0') {
                              e.target.value = '';
                            }
                          }}
                          onBlur={(e) => {
                            if (e.target.value === '') {
                              handleInputChange(0, row.key, columnIndex);
                            }
                          }}
                          onChange={(e) => {
                            const newValue = e.target.value.replace(/,/g, '');
                            if (!isNaN(newValue)) {
                              handleInputChange(newValue, row.key, columnIndex);
                            }
                          }}
                          value={yearData.values[rowIndex].value
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          placeholder="0"
                          className={`w-full border-none text-end text-xs focus:outline-none focus:ring-2  m-0 py-1 bg-white text-gray-900 px-2 `}
                        />
                      </td>
                    ))}
                    {analysisResult?.financial_statements &&
                      analysisResult?.financial_statements.map((yearData, columnIndex) => (
                        <td key={columnIndex} className={`${TableStyle.TdStyle} text-end`}>
                          {isLoading ? (
                            <MyLoader />

                          ) : (
                            <input
                              className={` border-none text-end text-xs focus:outline-none focus:ring-2  !bg-gray-200 m-0 py-1  text-gray-900 px-2 `}
                              type="text"
                              value={yearData.values[rowIndex]?.value.toLocaleString('en-US')}
                              disabled
                            />
                          )}
                        </td>
                      ))}
                  </tr>
                ))}
            </tbody>
            <thead className="text-center  bg-primary rounded-md">
              <tr>
                <th className={`${TableStyle.TdLabelStyle} !bg-gray-300 !font-semibold `}>
                  {intl.formatMessage({ id: 'FINANCIAL_STATEMENT.INCOME_STATEMETNS_TABLE.TITLE' })}
                </th>
                {renderHeaders(financial_statements, true)}
                {renderHeaders(flowOrstock ? analysisResult?.financial_statements : analysisResult?.financial_statements_stock, true)}
              </tr>
            </thead>
            <tbody>
              {financial_statements[0]?.values
                .slice(firmBackground.industry === 'Finance & Insurance' ? 14 : 12)
                .map((row, rowIndex) => (
                  <tr key={rowIndex} className={'bg-gray-200'}>
                    <td
                      className={`${TableStyle.TdLabelStyle} text-gray-800 font-normal !min-w-[250px] sm:w-[120px]  !border-l border-b border-[#d2d5d9]`}
                      title={intl.formatMessage({ id: `tooltip_${row.key}` })}
                      data-tip={intl.formatMessage({ id: `tooltip_${row.key}` })}
                    >
                      <ReactTooltip />
                      {intl.formatMessage({ id: row.key })}
                    </td>
                    {financial_statements.map((yearData, columnIndex) => (
                      <td key={columnIndex} className={`${TableStyle.TdStyle} !p-0 !m-0 bg-red-400`}>
                        <input
                          type="text"
                          onFocus={(e) => {
                            if (e.target.value === '0') e.target.value = '';
                          }}
                          onBlur={(e) => {
                            if (e.target.value === '') handleInputChange(0, row.key, columnIndex);
                          }}
                          onChange={(e) => {
                            const newValue = e.target.value.replace(/,/g, '');
                            if (!isNaN(newValue)) {
                              handleInputChange(newValue, row.key, columnIndex);
                            }
                          }}
                          value={yearData.values[rowIndex + (firmBackground.industry === 'Finance & Insurance' ? 14 : 12)]
                            .value.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          placeholder="0"
                          className={`w-full border-none text-end text-xs focus:outline-none focus:ring-2  m-0 py-1 bg-white text-gray-900 px-2 `}
                        />
                      </td>
                    ))}
                    {analysisResult?.financial_statements &&
                      analysisResult?.financial_statements.map((yearData, columnIndex) => (
                        <td key={columnIndex} className={`${TableStyle.TdStyle} text-end w-[150px] sm:w-[100px] `}>
                          <MyLoader />
                        </td>
                      ))}
                  </tr>
                ))}
            </tbody>
          </table>
      }

    </div>
  );





  return (
    <>
      {flowOrstock === true ? (
        table
      ) : null}

      {flowOrstock === false ? (
        table
      ) : null}
    </>

  );


}
export default FinancialStatements
