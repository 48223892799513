import { useSelector } from 'react-redux'
import FinancialAnalysisReport from 'components/FinancialAnalysisReport'
import RealAnalysisReport from 'components/RealAnalysisReport'

const Report = ({isLoading}) => {
  const { firmBackground } = useSelector((state) => state.analysisDataReducer)

  return (
    <>
      {firmBackground?.industry === 'Finance & Insurance' ? (
        <FinancialAnalysisReport isLoading={isLoading} />
      ) : (
        <RealAnalysisReport isLoading={isLoading}/>
      )}
    </>
  )
}

export default Report
